import styled from "styled-components";
import goTo from "../../controllers/goTo";
import CustomButton from "../helperComponents/CustomButton";
import { useContext } from "react";
import Context from "../../Context";

const Row = styled.div`
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  gap: 20px;
  border-radius: 10px;
  padding: 20px;
  background: var(--glassGradient);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);
`;
const Title = styled.div`
  white-space: nowrap;
  font-size: 24px;
  font-weight: 900;
  overflow: hidden;
`;
const NumCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  /* width: 200px; */
`;

const Stat = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;

export default function ReportedItem({ item, subjectType }) {
  const { setPopupFeedItem } = useContext(Context);
  let { subjectID, title } = item;

  return (
    <Row>
      <Title>{title}</Title>

      <Stat>
        <CustomButton variant="filled" onClick={openPost}>
          Open
        </CustomButton>
        <CustomButton
          variant="filled"
          onClick={goTo(
            `/reports/?subjectID=${item._id}&subjectType=${subjectType}`
          )}
        >
          Show Reports
        </CustomButton>
        <NumCol>New Reports: {item.newReportsCount}</NumCol>
        <NumCol>Old Reports: {item.oldReportsCount}</NumCol>
      </Stat>
    </Row>
  );

  function openPost() {
    setPopupFeedItem(item);
    goTo("/content/" + item._id)();
  }
}
