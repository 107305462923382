import { styled } from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";

import selectFile from "../../controllers/selectFile.js";
import compressAndUploadFile from "../../controllers/compressAndUploadFile.js";
import { serverLine } from "../../controllers/serverLine.js";

import Context from "../../Context.js";

import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai/index.js";

import ProfilePicture from "../helperComponents/ProfilePicture.js";
import ShowSelectedImage from "../helperComponents/ShowSelectedImage.js";

import MaterialInput from "../helperComponents/MaterialInput.js";
import CustomButton from "../helperComponents/CustomButton.js";

import LoadingSection from "../helperComponents/LoadingSection.js";
import SocialLinksEditor from "./SocialLinksEditor.js";
import DropDownLocationInput from "../helperComponents/DropDownLocationInput.js";
import CategoryAdder from "./CategoryAdder.js";
import getCategoryOptions from "../../controllers/utils/getCategoryOptions.js";
import MultiSelector from "./MultiSelector.js";
import BeautifulTitle from "../helperComponents/BeautifulTitle.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  width: 100%;
  margin-top: 100px;
  gap: 10px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 45vw;
  gap: 30px;
  justify-content: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Button = styled.div`
  width: 100%;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 20px 15px;
`;

const Textarea = styled.textarea`
  font-size: 18px;
  border: none;
  border-bottom: 1px solid var(--translucentHard);
  background: transparent;
  border-radius: 0;
  color: var(--color);
  /* text-align: center; */
  width: 100%;
  font-family: "Inter";
  resize: none;

  padding: 15px 0;

  &::placeholder {
    color: var(--translucentHard);
  }
`;

const ImageUploadSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 25px;
  /* width: 100%; */
  justify-content: center;
  align-items: center;
`;

const ImageUploadButton = styled(Button)`
  /* width: 300px; */
  position: absolute;
  right: 2px;
  bottom: 5px;
  width: 50px;
  background: var(--glassGradient);
  backdrop-filter: blur(20px);
  box-shadow: var(--hardShadow);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  height: 50px;

  transition: all 0.2s ease-in-out;
  border: 1px solid var(--glassBorder);

  &:hover {
    transform: scale(1.2);
    box-shadow: var(--lightShadow);
  }
`;

export default function InterestsEditor() {
  const { loggedInUser, setLoggedInUser, popupAlert } = useContext(Context);
  const [interests, setInterests] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.interests) setInterests(loggedInUser.interests);
    }
  }, [loggedInUser]);

  if (!loggedInUser || loading) return <LoadingSection />;

  let options = getCategoryOptions();

  return (
    <Container>
      <Inputs>
        <BeautifulTitle>Select your Interests</BeautifulTitle>
        <MultiSelector
          style={{ justifyContent: "center" }}
          value={interests}
          onChange={setInterests}
          maxItems={2}
          options={options}
        />

        <CustomButton
          style={{ width: "200px", borderRadius: "10px" }}
          icon={<AiOutlineCheck />}
          onClick={save}
          variant="filled"
        >
          Save
        </CustomButton>
      </Inputs>
      <br /> <br />
    </Container>
  );

  async function save() {
    if (!interests.length) return popupAlert("Choose atleast one interest");

    let toSend = {
      changes: {},
    };

    toSend.changes.onboarding = "interestsAdded";
    toSend.changes.interests = interests;

    setLoading(true);

    try {
      await serverLine.patch("/profile", toSend);

      let newLoggedInUser = { ...loggedInUser, interests };

      if (newLoggedInUser.onboarding) {
        newLoggedInUser.onboarding.interestsAdded = true;
      } else {
        newLoggedInUser.onboarding = { interestsAdded: true };
      }

      setLoggedInUser(newLoggedInUser);

      setLoading(false);

      popupAlert("Saved!");
    } catch (e) {
      setLoading(false);
      popupAlert(e.message);
    }
  }
}
