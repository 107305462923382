import styled from "styled-components";
import { GrGithub, GrYoutube } from "react-icons/gr/index.js";
import { useContext, useEffect, useState } from "react";
import Context from "../../Context.js";

import {
  BsInstagram,
  BsLinkedin,
  BsTiktok,
  BsTwitterX,
  BsYoutube,
} from "react-icons/bs/index.js";

import { serverLine } from "../../controllers/serverLine.js";
import ProfilePicture from "../helperComponents/ProfilePicture.js";

import { RiLinkedinLine, RiTwitterLine, RiUnsplashFill } from "react-icons/ri";

import SocialLink from "./SocialLink.js";

import getLocationString from "../../controllers/getLocationString.js";

import getUrlParams from "../../controllers/getUrlParams.js";

import LoadingSection from "../helperComponents/LoadingSection.js";
import { AiOutlineYoutube } from "react-icons/ai";
import { LiaArtstation } from "react-icons/lia";
import { BiLogoInstagramAlt, BiLogoUpwork } from "react-icons/bi";
import PageNotFound from "../helperComponents/PageNotFound.js";
import WithHeader from "../core/WithHeader.js";
import BookCard from "../cardForProfile/BookCard.js";
import limitStringLength from "../../controllers/utils/limitStringLength.js";
import getImageURL from "../../controllers/getImageURL.js";
import FollowButton from "../helperComponents/FollowButton.js";
import { FaGlobe, FaYoutube } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { GoGlobe } from "react-icons/go";
import CustomButton from "../helperComponents/CustomButton.js";
import { HiMenu } from "react-icons/hi";
import adminAccess from "../../data/adminAccess.js";
import UserBanUnbanButton from "./UserBanUnbanButton.js";
import ContentFeed from "../feed/ContentFeed.js";

const MainContent = styled.div`
  padding: 0px 0;
  /* padding-top: 150px; */
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 100px;

  @media (max-width: 900px) {
    padding: 0;
  }
`;

const HeroSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  background: var(--glassGradientHeader);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);

  padding: 20px;
  border-radius: 10px;
  /* justify-content: space-between; */
  margin-bottom: 0;
  gap: 30px;
  width: 62vw;

  @media (max-width: 900px) {
    /* padding: 20px; */
    flex-direction: column;
    margin-top: 0;
    gap: 30px;
    width: 100%;
    /* gap: 20px; */
  }
`;

const HeroLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: 100%;
  padding: 0 0;
`;

const JustText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: 100%;
  padding: 0 0;

  @media (max-width: 900px) {
    gap: 7px;
    margin-bottom: 10px;
  }
`;

const Name = styled.div`
  font-size: 30px;
  font-weight: 900;
  /* margin-bottom: 10px; */

  width: 100%;

  @media (max-width: 900px) {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
  }
`;

const HeroTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  /* margin-bottom: 10px; */

  width: 100%;

  @media (max-width: 900px) {
    font-size: 13px;
    text-align: center;
  }
`;

const HeroDescription = styled.div`
  font-size: 19px;

  /* margin-top: -15px; */
  /* width: 600px; */
  opacity: 0.7;
  line-height: 30px;
  width: 100%;

  @media (max-width: 900px) {
    font-size: 13px;
    text-align: center;
  }
`;

const HeroRight = styled.div``;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const SectionTitle = styled.div`
  font-size: 22px;
  opacity: 0.5;
`;

const SectionList = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const ProfileContainer = styled.div`
  position: relative;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 25px;
  gap: 15px;
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid var(--translucentHard);
  align-items: center;
`;

const HeroButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 900px) {
    justify-content: center;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
`;
const ProfileImage = styled.img`
  height: 300px;
  width: 300px;
  object-fit: cover;
  border-radius: 15px;
`;

const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 900px) {
    width: 100%;
    justify-content: center;
  }
`;

const DataItem = styled.div`
  font-size: 18px;
  font-weight: 500;

  @media (max-width: 900px) {
    font-size: 13px;
    text-align: center;
  }
`;

export default function ProfilePage({ username }) {
  let { loggedInUser, popupAlert, loggedInUserID, nonPopupRoute } =
    useContext(Context);
  const [userData, setUserdata] = useState(null);

  const [error, setError] = useState(null);

  useEffect(() => {
    setUserdata(null);
    serverLine
      .get("/profile/?username=" + username)
      .then(setUserdata)
      .catch((err) => {
        setError(err.message);
      });
  }, [username]);

  if (error == "User not found")
    return (
      <WithHeader>
        <PageNotFound message="Page not found" />{" "}
      </WithHeader>
    );

  if (error) return JSON.stringify(error);

  if (!userData)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  if (!userData.user.tagline)
    userData.user.tagline = "Hi I am new to Shortbooks";

  if (!userData.user.bio) userData.user.bio = "I am new to Shortbooks";

  let isAdmin = false;

  if (loggedInUser) {
    isAdmin = adminAccess.includes(loggedInUser.email);
  }

  let heroSectionInfo = (
    <HeroLeft>
      <JustText>
        <Name>{userData.user.name} </Name>

        <HeroTitle>{userData.user.tagline} </HeroTitle>

        <HeroDescription>
          {limitStringLength(userData.user.bio, 200)}{" "}
        </HeroDescription>

        <DataRow>
          <DataItem>{userData.user.followerCount} Followers</DataItem>

          <DataItem>{userData.user.followingCount} Following</DataItem>

          <DataItem>{getLocationString(userData.user)}</DataItem>
        </DataRow>
      </JustText>

      <HeroButtons>
        {loggedInUserID !== userData.user._id ? (
          <FollowButton userID={userData.user._id} />
        ) : null}

        {isAdmin ? (
          <UserBanUnbanButton subjectType={"USER"} item={userData.user} />
        ) : null}

        <SocialLink
          baseLink=""
          icon={<FaGlobe />}
          field="websiteLink"
          // size="29px"
          size="23px"
          data={userData.user.socialLinks}
        />

        <SocialLink
          baseLink="https://instagram.com"
          icon={<BiLogoInstagramAlt />}
          field="instagram"
          size="28px"
          data={userData.user.socialLinks}
        />
        <SocialLink
          baseLink="https://github.com"
          data={userData.user.socialLinks}
          icon={<GrGithub />}
          field="github"
        />
        <SocialLink
          baseLink="https://twitter.com"
          data={userData.user.socialLinks}
          icon={<FaSquareXTwitter />}
          // size="27px"
          field="twitter"
        />
        <SocialLink
          baseLink="https://youtube.com"
          data={userData.user.socialLinks}
          icon={<GrYoutube />}
          size="27px"
          field="youtube"
        />

        <SocialLink
          baseLink="https://www.linkedin.com/in"
          data={userData.user.socialLinks}
          icon={<BsLinkedin />}
          size="21px"
          field="linkedIn"
        />

        <SocialLink
          baseLink="https://www.artstation.com"
          data={userData.user.socialLinks}
          icon={<LiaArtstation />}
          field="artstation"
        />

        <SocialLink
          baseLink="https://www.upwork.com/freelancers"
          data={userData.user.socialLinks}
          icon={<BiLogoUpwork />}
          size="31px"
          field="upwork"
        />

        <SocialLink
          baseLink="https://unsplash.com"
          data={userData.user.socialLinks}
          icon={<RiUnsplashFill />}
          field="unsplash"
        />

        <SocialLink
          baseLink="https://www.tiktok.com/"
          data={userData.user.socialLinks}
          icon={<BsTiktok />}
          field="tiktok"
        />

        {loggedInUserID == userData.user._id ? (
          <CustomButton
            style={{ fontSize: "24px", padding: "0px 10px" }}
            variant="minimal"
            href="/more-menu"
            icon={<HiMenu />}
          />
        ) : null}
      </HeroButtons>
    </HeroLeft>
  );

  let heroSection = (
    <HeroSection>
      <ProfileContainer>
        <ProfileImage src={getImageURL(userData.user.profileImage, true)} />
      </ProfileContainer>

      {heroSectionInfo}
    </HeroSection>
  );

  return (
    <WithHeader>
      <MainContent id="heroSection">
        {heroSection}

        <Content>
          <ContentFeed
            type={"BOOK"}
            filterType={"PROFILE_CONTENTS"}
            title={"Shortbooks"}
            feedType={"SLIDER"}
            username={username}
          />
          <ContentFeed
            type={"ARTICLE"}
            filterType={"PROFILE_CONTENTS"}
            title={"Articles"}
            feedType={"SLIDER"}
            username={username}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Content>
      </MainContent>
    </WithHeader>
  );
}
