import { styled } from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";
import { serverLine } from "../../controllers/serverLine.js";

import Context from "../../Context.js";

import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai/index.js";

import MaterialInput from "../helperComponents/MaterialInput.js";
import CustomButton from "../helperComponents/CustomButton.js";

import LoadingSection from "../helperComponents/LoadingSection.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  width: 100%;

  gap: 30px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  gap: 30px;
  justify-content: center;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Button = styled.div`
  width: 100%;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 20px 15px;
`;

const Textarea = styled.textarea`
  font-size: 18px;
  border: none;
  border-bottom: 1px solid var(--translucentHard);
  background: transparent;
  border-radius: 0;
  color: var(--color);
  /* text-align: center; */
  width: 100%;
  font-family: "Inter";
  resize: none;

  padding: 15px 0;

  &::placeholder {
    color: var(--translucentHard);
  }
`;

const ImageUploadSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 25px;
  /* width: 100%; */
  justify-content: center;
  align-items: center;
`;

const ImageUploadButton = styled(Button)`
  /* width: 300px; */
  position: absolute;
  right: -25px;
  bottom: 35%;
  width: 50px;
  background: var(--bgColor);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  height: 50px;
`;

const Title = styled.div`
  font: 18px;
  opacity: 0.7;
`;

export default function SocialLinksEditor({ value, onChange }) {
  const [showMore, setShowMore] = useState(false);

  let socialLinks = value;
  let setSocialLinks = onChange;

  return (
    <Container>
      <Title>Social Links</Title>
      <Inputs>
        <MaterialInput
          value={socialLinks.instagram}
          onChange={makeChange("instagram")}
          label={"Instagram Username"}
        />
        <MaterialInput
          value={socialLinks.github}
          onChange={makeChange("github")}
          label={"Github Username"}
        />
        <MaterialInput
          value={socialLinks.twitter}
          onChange={makeChange("twitter")}
          label={"Twitter Username"}
        />

        {showMore ? (
          <>
            {" "}
            <MaterialInput
              value={socialLinks.youtube}
              onChange={makeChange("youtube")}
              label={"Youtube ChannelID"}
            />
            <MaterialInput
              value={socialLinks.linkedIn}
              onChange={makeChange("linkedIn")}
              label={"linkedIn Username"}
            />
            <MaterialInput
              value={socialLinks.artstation}
              onChange={makeChange("artstation")}
              label={"Artstation Username"}
            />
            <MaterialInput
              value={socialLinks.upwork}
              onChange={makeChange("upwork")}
              label={"Upwork Username"}
            />
            <MaterialInput
              value={socialLinks.websiteLink}
              onChange={makeChange("websiteLink")}
              label={"Personal Website Link"}
            />
            <MaterialInput
              value={socialLinks.tiktok}
              onChange={makeChange("tiktok")}
              label={"Tiktok Username"}
            />
          </>
        ) : null}

        {/* <MaterialInput
          value={socialLinks.unsplash}
          onChange={makeChange("unsplash")}
          label={"Unsplash Username"}
        /> */}

        {!showMore ? (
          <CustomButton
            onClick={() => {
              setShowMore(true);
            }}
          >
            Show More
          </CustomButton>
        ) : null}
      </Inputs>
      <br /> <br />
    </Container>
  );

  function makeChange(fieldName) {
    return (e) => {
      let newItem = { ...socialLinks };
      newItem[fieldName] = e.target.value;
      setSocialLinks(newItem);
    };
  }
}
