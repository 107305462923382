import { styled } from "styled-components";
import ContentActionButtons from "../ContentActionButtons";
import BookButtons from "../BookButtons";
import RichTextEditor from "../../richTextEditor/RichTextEditor";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--chapterWidth);
  gap: 100px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default function RenderArticle({ value, setValue }) {
  // return <ContentActionButtons content={value} />;
  // return "hh";

  return (
    <Container>
      <RichTextEditor value={value} readOnly={true} />
      <BookButtons content={value} />
      <ContentActionButtons content={value} setContent={setValue} />
    </Container>
  );
}
