import { useEffect, useState } from "react";
import hozStatDataProcessor from "../../controllers/stat/hozStatDataProcessor";
import HorizontalBarGraph from "./libs/HorizontalBarGraph";
import { serverLine } from "../../controllers/serverLine";
import LoadingSection from "../helperComponents/LoadingSection";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export default function UserStatSection() {
  const [data, setData] = useState(null);

  useEffect(() => {
    serverLine.get("/user-stat").then(setData);
  }, []);

  if (!data) return <LoadingSection />;

  let fields = [
    "internalContentViewCount",
    // "internalContentReadCount",
    // "externalContentViewCount",
    // "externalContentReadCount",
  ];

  return (
    <Container>
      <HorizontalBarGraph
        fields={["internalContentViewCount"]}
        title="Internal Views"
        dataProcessor={hozStatDataProcessor}
        rawData={data}
      />
      <HorizontalBarGraph
        fields={["internalContentReadCount"]}
        title="Internal Reads"
        dataProcessor={hozStatDataProcessor}
        rawData={data}
      />
      <HorizontalBarGraph
        fields={["externalContentViewCount"]}
        title="External Views"
        dataProcessor={hozStatDataProcessor}
        rawData={data}
      />
      <HorizontalBarGraph
        fields={["externalContentReadCount"]}
        title="External Reads"
        dataProcessor={hozStatDataProcessor}
        rawData={data}
      />
    </Container>
  );
}
