import { useEffect, useReducer, useState } from "react";
import reducer from "../../../reducer.js";
import Context from "../../../Context.js";
import DynamicForm from "../../helperComponents/dynamicForm/DynamicForm.js";
import getUserID from "../../../controllers/getUserID.js";
import { RiGoogleFill } from "react-icons/ri/index.js";
import goToAuthScreen from "../../../controllers/goToAuthScreen.js";
import { useNavigate } from "react-router-dom";
import { serverLine } from "../../../controllers/serverLine.js";
import logout from "../../../controllers/logout.js";
import isLoggedIn from "../../../controllers/isLoggedIn.js";
import AskBasicInfo from "../../onboarding/AskBasicInfo.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import goTo from "../../../controllers/goTo.js";
import updateRouteState from "../../../controllers/updateRouteState.js";
import WithBackground from "./WithBackground.js";
import YouAreBanned from "../../report/YouAreBanned.js";
import LoadingSection from "../../helperComponents/LoadingSection.js";
import getHostName from "../../../controllers/getHostName.js";
import AskInterests from "../../onboarding/AskInterests.js";

window.nonReactLikeStatus = {};

function Boilerplate({ children }) {
  const [loggedInUserID, setLoggedInUserID] = useState(null);
  const [formData, setFormData] = useState(false);

  const [notificationData, setNotificationData] = useState({
    general: 0,
    work: 0,
  });
  const [popupFeedItem, setPopupFeedItem] = useState(null);
  const navigate = useNavigate();
  const alert = (message) => {
    toast(message);
  };

  const [colorMode, setColorMode] = useState("DARK");
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [state, dispatch] = useReducer(reducer, {});
  const [scrollData, setScrollData] = useState(null);

  let currentPath = window.location.pathname + window.location.search;

  const [currentRoute, setCurrentRoute] = useState(currentPath);

  let initialNonPopupRoute = currentPath;

  const [nonPopupRoute, setNonPopupRoute] = useState(initialNonPopupRoute);

  let isOnStaticPage =
    window.location.href.indexOf("static.shortbooks.app") !== -1;

  useEffect(() => {
    if (isOnStaticPage) {
      window.location = `https://www.shortbooks.app`;
    }
  }, []);

  window.setNonPopupRoute = setNonPopupRoute;
  window.setCurrentRoute = setCurrentRoute;
  window.setForm = setForm;
  window.navigate = navigate;
  window.doAlert = alert;
  window.loggedInUserID = loggedInUserID;
  window.loggedInUser = loggedInUser;
  window.homeLink = window.location.protocol + "//" + getHostName();
  window.updateLoggedInUser = updateLoggedInUser;

  function updateNotificationData() {
    serverLine.get("/notification-count").then(setNotificationData);
  }

  useState(() => {
    let theFunc = (theEvent) => {
      let newURl = window.location.pathname + window.location.search;

      updateRouteState(newURl);
    };

    window.addEventListener("popstate", theFunc);

    return () => {
      window.removeEventListener("popstate", theFunc);
    };
  }, []);

  useEffect(() => {
    if (loggedInUserID) {
      updateNotificationData();

      // if (window.notifInterval) {
      //   window.clearInterval(window.notifInterval);
      // }
      // window.notifInterval = window.setInterval(() => {
      //   updateNotificationData();
      // }, 30 * 1000);
    }
  }, [loggedInUserID]);

  useEffect(() => {
    let userID = getUserID();

    if (userID) {
      setLoggedInUserID(userID);
      updateLoggedInUser();
    } else {
      setLoggedInUserID(false);
    }
  }, []);

  useEffect(() => {
    removeFormQuery();
    backFeatures();

    let data = {};
    dispatch({ type: "NEW_STATE", value: data });

    let newStatus = localStorage.getItem("shortbooks-color-mode");
    if (!newStatus) newStatus = "DARK";
    updateDarkMode(newStatus);
  }, []);

  // console.log(loggedInUser);

  if (isLoggedIn()) {
    if (!loggedInUser) {
      children = <LoadingSection />;
    } else {
      if (!loggedInUser.onboarding) {
        children = <AskBasicInfo />;
      } else {
        if (!loggedInUser.onboarding.interestsAdded)
          children = <AskInterests />;
        if (!loggedInUser.onboarding.basicInfoAdded)
          children = <AskBasicInfo />;
      }

      if (loggedInUser.banned) children = <YouAreBanned />;
    }
  }

  window.popupAlert = alert;

  if (isOnStaticPage) {
    children = "Please! wait, redirecting to the main website.";
  }

  return (
    <Context.Provider
      value={{
        scrollData,
        setScrollData,
        nonPopupRoute,
        setNonPopupRoute,
        currentRoute,
        setLoggedInUser,
        promptLogin,
        loggedInUserID,
        popupFeedItem,
        setPopupFeedItem,
        notificationData,
        setNotificationData,
        updateNotificationData,
        state,
        popupAlert: alert,
        updateLoggedInUser: updateLoggedInUser,
        loggedInUser: loggedInUser,
        dispatch,
        updateDarkMode,
        colorMode,
        setForm: setForm,
      }}
    >
      <DynamicForm setForm={setForm} data={formData} />

      <WithBackground> {children}</WithBackground>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Context.Provider>
  );

  function updateDarkMode(newStatus) {
    setColorMode(newStatus);
    localStorage.setItem("shortbooks-color-mode", newStatus);
    setColors(newStatus);
  }

  function updateLoggedInUser() {
    try {
      serverLine.get("/logged-in-user").then(setLoggedInUser);
    } catch (e) {
      alert.show(e.message);
      console.log("First Fetch Error----------");
      logout();
    }
  }

  function setColors(newVal) {
    let colors = {
      bgColor: "#ffffff",
      bgColorDark: "#999999",
      bgColor2: "#ffffff",
      color: "#150c01",
      glassBorder: "#ffffff",
      primaryColor: "#ffffff",
      translucent: "rgba(0,0,0,0.1)",
      accentColor: "#9a5b22",
      // glassGradientHeader: "linear-gradient(122deg, #e1d1c5, #e0dacf, #efebe5)",//old
      glassGradientHeader: "linear-gradient(122deg, #ebdfd5, #f0ece6, #faf9f7)",
      translucentHard: "rgba(0,0,0,0.2)",
      glassGradientLight:
        "linear-gradient(122deg, rgb(255 255 255 / 23%), rgb(255 255 255 / 12%), rgb(255 255 255 / 31%))",
      glassGradient: "linear-gradient(122deg, #ffffff7a, #ffffff52, #ffffffa1)",
      glassGradientHard:
        "linear-gradient(122deg, #ffffffbf, #ffffff85, #ffffffd4)",
      veryLightShadow: "rgb(0 0 0 / 5%) 1px 4px 10px 1px",
      lightShadow: "rgb(0 0 0 / 13%) 1px 4px 10px 1px",
      hardShadow: "rgb(0 0 0 / 24%) 1px 4px 25px 1px",
    };

    // if (newVal) {
    //   if (newVal === "DARK")
    //     colors = {
    //       bgColor: "#111111",
    //       bgColorDark: "#000000",
    //       bgColorLight: "#222222",
    //       bgColor2: "#000000",
    //       color: "#ffffff",
    //       accentColor: "#8c9fbd",
    //       translucent: "rgb(212 226 227 / 6%)",
    //       translucentHard: "rgba(255,255,255,0.2)",
    //     };
    // }

    document.documentElement.style.setProperty(
      "--veryLightShadow",
      colors.veryLightShadow
    );

    document.documentElement.style.setProperty(
      "--glassGradientHeader",
      colors.glassGradientHeader
    );

    document.documentElement.style.setProperty(
      "--primaryColor",
      colors.primaryColor
    );

    document.documentElement.style.setProperty(
      "--glassBorder",
      colors.glassBorder
    );

    document.documentElement.style.setProperty(
      "--glassGradient",
      colors.glassGradient
    );

    document.documentElement.style.setProperty(
      "--glassGradientLight",
      colors.glassGradientLight
    );

    document.documentElement.style.setProperty(
      "--glassGradientHard",
      colors.glassGradientHard
    );

    document.documentElement.style.setProperty(
      "--lightShadow",
      colors.lightShadow
    );

    document.documentElement.style.setProperty(
      "--hardShadow",
      colors.hardShadow
    );

    document.documentElement.style.setProperty(
      "--accentColor",
      colors.accentColor
    );

    document.documentElement.style.setProperty(
      "--bgColorDark",
      colors.bgColorDark
    );

    document.documentElement.style.setProperty("--bgColor", colors.bgColor);
    document.documentElement.style.setProperty(
      "--bgColorLight",
      colors.bgColorLight
    );
    document.documentElement.style.setProperty("--bgColor2", colors.bgColor2);
    document.documentElement.style.setProperty("--color", colors.color);
    document.documentElement.style.setProperty(
      "--translucent",
      colors.translucent
    );
    document.documentElement.style.setProperty(
      "--translucentHard",
      colors.translucentHard
    );
  }

  function promptLogin() {
    setFormData({
      title: "Please Login",
      buttons: [
        {
          icon: <RiGoogleFill />,
          name: "Login / Signup",
          onClick: goToAuthScreen,
        },
      ],
    });
  }

  function backFeatures() {
    window.addEventListener("popstate", (event) => {
      removeFormOnBack();
    });
  }

  function removeFormOnBack() {
    const formPage = getUrlQuery("formPage");
    if (!formPage) {
      setFormData(null);
    }
  }

  function getUrlQuery(field) {
    if (typeof window == "undefined") return null;
    const queryString = window.location.search;
    const urlParamsForm = new URLSearchParams(queryString);
    return urlParamsForm.get(field);
  }

  function setForm(data, noPathChange) {
    if (noPathChange) {
      return setFormData({ data, noPathChange });
    }

    let path = window.location.pathname;
    let queryObj = getUrlQueryObject();

    if (getUrlQuery("formPage") && !data) {
      closeForm();
    } else if (!getUrlQuery("formPage")) {
      queryObj.formPage = "true";

      let thePath = removeLastSlash(path) + queryObjToUrl(queryObj);

      goTo(thePath)();

      setFormData({ data, noPathChange });
    } else {
      setFormData({ data, noPathChange });
    }
  }

  function removeLastSlash(urlString) {
    if (urlString[urlString.length - 1] == "/") {
      return urlString.slice(0, urlString.length - 1);
    } else {
      return urlString;
    }
  }

  function queryObjToUrl(queryInput) {
    let newQueryUrl = "/?";
    let i = 0;
    let queryLen = Object.keys(queryInput).length;
    for (let key in queryInput) {
      if (queryInput[key]) {
        if (i == queryLen - 1) {
          newQueryUrl += `${key}=${queryInput[key]}`;
        } else {
          newQueryUrl += `${key}=${queryInput[key]}&`;
          i++;
        }
      }
    }

    return newQueryUrl;
  }

  function getUrlQueryObject() {
    let data = {};
    let raw = window.location.search.replace(/\?/gi, "").replace(/\//gi, "");
    if (!raw) return data;
    raw = raw.split("&");
    for (let itm of raw) {
      if (!itm) continue;
      itm = itm.split("=");
      if (itm.length == 2) {
        if (itm[1].trim()) {
          data[itm[0]] = itm[1];
        }
      }
    }
    return data;
  }

  function removeFormQuery() {
    //this prevents bug
    //what bug?
    //if url already has form query then
    //on setFormData query won't be pushed

    if (getUrlQuery("formPage")) {
      let path = window.location.pathname;
      let queryObj = getUrlQueryObject();
      delete queryObj.formPage;
      let newPath = "/";

      let queryPath = queryObjToUrl(queryObj);

      if (queryPath == "/?") queryPath = "";

      if (path === "/") {
        newPath = queryPath;
      } else {
        if (path[path.length - 1] == "/" && queryPath[0] == "/") {
          queryPath = queryPath.slice(1, queryPath.length);
        }
        newPath = path + queryPath;
      }

      if (newPath[newPath.length - 1] == "/") {
        newPath = newPath.slice(0, newPath.length - 1);
      }

      console.log("newPath", newPath, queryPath);
      goTo(newPath, { isReplace: true })();
    }
  }

  function closeForm() {
    //sometimes it takes a few seconds to push the router
    //if user presses back before then user might go 2 pages back
    if (getUrlQuery("formPage")) {
      goTo(-1)();
    }
  }
}

export default Boilerplate;
