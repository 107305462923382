import { styled } from "styled-components";
import CustomButton from "../helperComponents/CustomButton";
import goTo from "../../controllers/goTo";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
`;

export default function RenderContentTags({ content }) {
  if (!content.tags) return null;

  return (
    <List>
      {content.tags.map((item) => (
        <CustomButton
          style={{ fontSize: "17px", padding: "10px 20px" }}
          variant="outlined"
          onClick={goTo("/tag-feed/" + item)}
        >
          #{capitalizeFirstLetter(item)}
        </CustomButton>
      ))}
    </List>
  );
}
