import CustomButton from "../../helperComponents/CustomButton";
import { styled } from "styled-components";
import goTo from "../../../controllers/goTo";
import { serverLine } from "../../../controllers/serverLine";
import { useContext, useState } from "react";
import WithHeader from "../WithHeader";
import {
  MdOutlineArticle,
  MdOutlineBeachAccess,
  MdWorkOutline,
} from "react-icons/md";

import Context from "../../../Context";

import { BiBook } from "react-icons/bi";

import LoadingSection from "../../helperComponents/LoadingSection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  /* height: 100%; */
  /* margin-top: 200px; */
`;
const Title = styled.h3``;

const Types = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 500px;
  gap: 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const SecondaryButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

export default function CreateContent() {
  const { loggedInUser } = useContext(Context);
  const [loading, setLoading] = useState(false);

  if (loading || !loggedInUser)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  let buttons = [
    <CustomButton
      variant={"filled"}
      onClick={create("ARTICLE")}
      icon={<MdOutlineArticle />}
    >
      Article
    </CustomButton>,

    <CustomButton variant={"filled"} onClick={create("BOOK")} icon={<BiBook />}>
      Book
    </CustomButton>,
  ];

  return (
    <WithHeader>
      <Container>
        <Box>
          <Title>Create Content</Title>
          <Types>{buttons}</Types>
        </Box>
        <br /> <br />
        <SecondaryButtons>
          <CustomButton href={"/manage-contents"} variant="filled">
            Manage Contents
          </CustomButton>
        </SecondaryButtons>
        <br />
        <br />
        <br />
        <br />
      </Container>
    </WithHeader>
  );

  function create(type) {
    return () => {
      setLoading(true);

      serverLine
        .post("/content", { values: { type: type } })
        .then((newData) => {
          setLoading(false);
          goTo("/edit-content/" + newData._id)();
        })
        .catch((e) => {
          setLoading(false);
          window.popupAlert(e.message);
        });
    };
  }
}
