import { styled } from "styled-components";
import goToProfile from "../../controllers/goToProfile";
import getImageURL from "../../controllers/getImageURL";
import FollowButton from "../helperComponents/FollowButton";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import SemanticButton from "../helperComponents/SemanticButton";
import getProfileLink from "../../controllers/getProfileLink";

const User = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  color: var(--color);
  background: var(--glassGradient);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);
  padding: 10px;
  border-radius: 10px;

  cursor: pointer;

  &:hover {
    background: var(--primaryColor);
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  /* margin: 50px 0; */
  justify-content: space-between;
  gap: 35px;
  font-size: 17px;
  align-items: center;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;
const Image = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 10px;
  object-fit: cover;
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  padding-right: 10px;
`;
const Name = styled.div`
  font-weight: 700;
`;
const Username = styled.div`
  opacity: 0.7;
  font-size: 15px;
`;

export default function RenderContentUser({ author }) {
  return (
    <Container>
      <SemanticButton href={getProfileLink(author.username)}>
        <User>
          <Image src={getImageURL(author.profileImage, true)} />
          <Text>
            <Name>{capitalizeFirstLetter(author.name)}</Name>
            <Username>@{author.username}</Username>
          </Text>
        </User>
      </SemanticButton>
      <FollowButton style={{ height: "50px" }} userID={author._id} />
    </Container>
  );
}
