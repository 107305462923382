import styled from "styled-components";

import getImageURL from "../../controllers/getImageURL";
import getDateString from "../../controllers/getDateString";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";

const Notification = styled.div`
  padding: 25px 25px;
  /* cursor: pointer; */
  width: 100%;
  border-radius: 15px;

  /* text-transform: capitalize; */
  background: var(--glassGradient);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);

  color: var(--color);
  display: flex;
  flex-direction: column;
  gap: 25px;

  ${({ seen }) => {
    if (!seen)
      return `
      background:var(--primaryColor);
      `;
  }}
`;

const NotificationText = styled.div`
  /* text-transform: capitalize; */
  font-size: 19px;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const NotificationTextSmall = styled.div`
  /* text-transform: capitalize; */
  font-size: 15px;
  margin-top: -10px;
  opacity: 0.5;
`;

const NotificationButtons = styled.div`
  display: flex;
  flex-direction: row;

  gap: 15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const Image = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 100px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default function NotificationBox({
  seen,
  image,
  text,
  buttons,
  createdAt,
}) {
  return (
    <Notification seen={seen}>
      <Row>
        {image ? <Image src={getImageURL(image)} /> : null}

        <Right>
          <NotificationText>{capitalizeFirstLetter(text)}</NotificationText>
          <NotificationTextSmall>
            {getDateString(createdAt)}
          </NotificationTextSmall>
          <NotificationButtons>{buttons}</NotificationButtons>
        </Right>
      </Row>
    </Notification>
  );
}
