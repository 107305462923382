import { useEffect } from "react";
import { serverLine } from "../../controllers/serverLine";
import { nanoid } from "nanoid";

export default function RegisterView({ subjectID, subjectType }) {
  useEffect(() => {
    if (window.catchViewTimeout) window.clearTimeout(window.catchViewTimeout);

    let externalUserID = getExternalClientID();

    markAsViewed(externalUserID);

    window.catchViewTimeout = window.setTimeout(
      () => {
        markAsRead(externalUserID);
      },
      1000 * 60 * 1
    );

    return () => {
      window.clearTimeout(window.catchViewTimeout);
    };
  }, []);

  function getExternalClientID() {
    let localStorageVariableName = "shortbooks-external-userID";
    let externalUserID = localStorage.getItem(localStorageVariableName);

    if (!externalUserID) {
      externalUserID = nanoid();
      localStorage.setItem(localStorageVariableName, externalUserID);
    }

    return externalUserID;
  }

  function markAsViewed(externalUserID) {
    serverLine.post("/view", {
      subjectID,
      subjectType,
      status: "VIEWED",
      externalUserID: externalUserID,
    });
  }

  function markAsRead(externalUserID) {
    serverLine.post("/view", {
      subjectID,
      subjectType,
      status: "READ",
      externalUserID: externalUserID,
    });
  }

  return null;
}
