import CustomButton from "../../helperComponents/CustomButton";
import { styled } from "styled-components";
import goTo from "../../../controllers/goTo";
import { serverLine } from "../../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";
import Context from "../../../Context";
import { AiOutlineCheck } from "react-icons/ai";
import getUrlParams from "../../../controllers/getUrlParams";
import MaterialInput from "../../helperComponents/MaterialInput";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import LoadingSection from "../../helperComponents/LoadingSection";
import WithHeader from "../WithHeader";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  align-items: center;
  padding-bottom: 100%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 38vw;
  padding-top: 150px;
  gap: 30px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;
const Title = styled.h1``;

const Subtitle = styled.div`
  opacity: 0.5;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--translucentHard);
  gap: 5px;
`;

const Info = styled.div`
  display: flex;
  font-size: 30px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  width: 100%;
`;

const Success = styled.div`
  margin-top: 100px;
  width: 38vw;
  padding: 50px 20px;
  border-radius: 10px;
  background-color: var(--translucent);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;
const SuccessIcon = styled.div`
  background-color: var(--translucent);
  height: 100px;
  width: 100px;
  display: flex;

  justify-content: center;
  align-items: center;
  font-size: 30px;
  border-radius: 100px;
`;
const SuccessText = styled.h2``;
const SuccessButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export default function ReportContent() {
  const { loggedInUserID, nonPopupRoute } = useContext(Context);

  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState({});
  const [status, setStatus] = useState(false);

  let params = getUrlParams(nonPopupRoute);

  const [postData, setPostData] = useState(null);

  let subjectID = params.get("subjectID");
  let subjectType = params.get("subjectType");

  useEffect(() => {
    setPostData(null);
    setStatus(false);
    setLoading(true);

    if (subjectType == "CONTENT") {
      if (subjectID) {
        setLoading(true);
        serverLine.get("/content/?contentID=" + subjectID).then((newData) => {
          setPostData(newData);
          setLoading(false);
        });
      }
    }
  }, [subjectID, subjectType]);

  if (subjectType !== "CONTENT") {
    return (
      <WithHeader>
        <Info>Content is not a post type {subjectType}</Info>
      </WithHeader>
    );
  }

  if (!loggedInUserID)
    return (
      <WithHeader>
        <Info>Login Required</Info>
      </WithHeader>
    );

  if (status)
    return (
      <WithHeader>
        <MainContainer>
          <Success>
            <SuccessIcon>
              <AiOutlineCheck />
            </SuccessIcon>
            <SuccessText>Report Sent</SuccessText>

            <SuccessButtons>
              <CustomButton
                variant="outlined"
                onClick={goTo("/content/" + subjectID)}
              >
                Back to the content
              </CustomButton>

              <CustomButton variant="outlined" onClick={goTo("/")}>
                Go Home
              </CustomButton>
            </SuccessButtons>
          </Success>
        </MainContainer>
      </WithHeader>
    );

  if (loading) return <LoadingSection />;

  if (!postData) return null;

  return (
    <WithHeader>
      <MainContainer>
        <Container>
          <Top>
            <Title>Report</Title>
            <Subtitle>{postData.title}</Subtitle>
          </Top>

          <Inputs>
            <br />
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Reason</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={config.reason}
                onChange={updateReason}
              >
                <FormControlLabel
                  value="SPAM"
                  control={<Radio />}
                  label="Spam"
                />

                <FormControlLabel
                  value="HATEFUL"
                  control={<Radio />}
                  label="Hateful"
                />

                <FormControlLabel
                  value="COPYRIGHT"
                  control={<Radio />}
                  label="Copyright"
                />

                <FormControlLabel
                  value="LEGAL_ISSUE"
                  control={<Radio />}
                  label="Legal Issue"
                />

                <FormControlLabel
                  value="SEXUAL_CONTENT"
                  control={<Radio />}
                  label="Sexual Content"
                />
              </RadioGroup>
            </FormControl>

            <MaterialInput
              multiline={true}
              // variant="standard"
              label="Description"
              onChange={updateField}
            />

            <br />

            <CustomButton
              width={"100px"}
              variant="outlined"
              onClick={postReport}
            >
              Report
            </CustomButton>
          </Inputs>
        </Container>
      </MainContainer>
    </WithHeader>
  );

  async function postReport() {
    if (!config.reason) return window.doAlert("Please select the reason");

    setLoading(true);

    try {
      await serverLine.post("/report", { subjectID, subjectType, ...config });
      setStatus(true);
    } catch (e) {
      window.doAlert(e.message);
    }

    setLoading(false);
  }

  function updateReason(e) {
    let newConfig = { ...config };
    newConfig.reason = e.target.value;
    setConfig(newConfig);
  }

  function updateField(event) {
    let newConfig = { ...config };
    newConfig.description = event.target.value;
    setConfig(newConfig);
  }
}
