import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";

import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

import { BiBook } from "react-icons/bi";
import Context from "../../Context";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import { serverLine } from "../../controllers/serverLine";
import goTo from "../../controllers/goTo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
`;

const Button = styled.div`
  background: var(--glassGradientHard);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);

  border: 1px solid var(--glassBorder);
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;

  &:hover {
    background: var(--glassBorder);
  }
`;

const ButtonLabel = styled.div`
  opacity: 0.5;
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 15px;
`;

const Icon = styled.div``;
const Text = styled.div``;

const ButtonContent = styled.div`
  font-size: 18px;
`;

export default function BookButtons({ content }) {
  const { setPopupFeedItem } = useContext(Context);
  const [bookData, setBookData] = useState(null);

  useEffect(() => {
    if (content.bookID) {
      serverLine.get("/content/?contentID=" + content.bookID).then(setBookData);
    }
  }, []);

  if (!content.bookID) return null;
  if (!bookData) return null;

  let [prevChapter, nextChapter] = getPrevAndNextChapter();

  let prevButton = null;

  let nextButton = null;

  if (prevChapter) {
    prevButton = (
      <Button onClick={goToChapter(prevChapter)}>
        <ButtonLabel>
          <Icon>
            <MdArrowBackIos />
          </Icon>

          <Text>Previous Chapter</Text>
        </ButtonLabel>
        <ButtonContent>
          {capitalizeFirstLetter(prevChapter.title)}
        </ButtonContent>
      </Button>
    );
  }

  if (nextChapter) {
    nextButton = (
      <Button onClick={goToChapter(nextChapter)}>
        <ButtonLabel>
          <Text>Next Chapter</Text>
          <Icon>
            <MdArrowForwardIos />
          </Icon>
        </ButtonLabel>
        <ButtonContent>
          {capitalizeFirstLetter(nextChapter.title)}
        </ButtonContent>
      </Button>
    );
  }

  return (
    <Container>
      <Button onClick={goToChapter(bookData)}>
        <ButtonLabel>
          <Icon>
            <BiBook />
          </Icon>
          <Text>Book</Text>
        </ButtonLabel>
        <ButtonContent>{capitalizeFirstLetter(bookData.title)}</ButtonContent>
      </Button>

      {prevButton}
      {nextButton}
    </Container>
  );

  function goToChapter(item) {
    return () => {
      setPopupFeedItem(item);
      goTo("/content/" + item._id)();
    };
  }

  function getPrevAndNextChapter() {
    let indexOfChapter = null;

    if (!bookData.chapters) return [];

    let i = 0;

    for (let item of bookData.chapters) {
      if (content._id == item._id) {
        indexOfChapter = i;
      }
      i++;
    }

    let prev = bookData.chapters[indexOfChapter - 1];
    let next = bookData.chapters[indexOfChapter + 1];

    return [prev, next];
  }
}
