import styled from "styled-components";
import CustomButton from "../../helperComponents/CustomButton";
import { MdRemove } from "react-icons/md";
import { FaHamburger } from "react-icons/fa";
import { PiHamburger } from "react-icons/pi";
import { HiOutlineMenu } from "react-icons/hi";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 25px;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;

const Title = styled.span`
  white-space: nowrap;
  font-size: 21px;
  overflow: hidden;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function SelectedChapter({ item, onRemove }) {
  return (
    <Container>
      <Left>
        <Icon>
          <HiOutlineMenu />
        </Icon>
        <Title>{item.title}</Title>
      </Left>

      <CustomButton
        variant="outlined"
        height={"30px"}
        onClick={() => {
          onRemove(item);
        }}
        icon={<MdRemove />}
      />
    </Container>
  );
}
