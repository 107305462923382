import { useContext } from "react";
import { GrGoogle } from "react-icons/gr/index.js";
import styled from "styled-components";
import Context from "../../Context.js";
import goToAuthScreen from "../../controllers/goToAuthScreen.js";
import CustomButton from "../helperComponents/CustomButton.js";
const ParentContainer = styled.div`
  width: 100%;
  background: var(--glassGradientHeader);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);
  padding: 30px;
  border-radius: 10px;
`;

const Tagline = styled.div`
  font-weight: 900;
  font-size: 25px;
  /* background: linear-gradient(to bottom, var(--color), var(--color), #000); */
  /* -webkit-text-fill-color: transparent; */
  /* -webkit-background-clip: text; */
  width: 50vw;
  @media (max-width: 900px) {
    width: 100%;
    font-size: 35px;
  }
`;
const MainButtons = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 60px;
  align-items: center;
  border: none;
  width: 100%;
  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    justify-content: space-between;
    /* justify-content: center; */
    /* flex-direction: column; */
  }
`;
const Button = styled.button`
  width: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid;
  gap: 25px;
  background-color: var(--color);
  cursor: pointer;
  transition: 0.25s ease-in-out;
  color: var(--bgColor);
  &:hover {
    transform: scale(0.9);
  }
  @media (min-width: 950px) {
    width: 300px;
  }
`;

const Medium = styled.div`
  font-size: 21px;
  opacity: 0.8;
  font-weight: 400;
  width: 50vw;
  opacity: 0.5;
  /* line-height: 30px; */

  @media (max-width: 900px) {
    width: 100%;
    font-size: 18px;
    line-height: 28px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
`;

export default function LoggedOutSection() {
  let { setForm } = useContext(Context);
  return (
    <ParentContainer>
      <TextContainer>
        <Tagline>Learn Faster With Shortbooks</Tagline>
        <Medium>
          Dive into easy-to-understand, structured concepts and master new
          topics in no time.
        </Medium>
        <MainButtons>
          <CustomButton
            variant="filled"
            height={"60px"}
            width={"300px"}
            icon={<GrGoogle />}
            onClick={goToAuthScreen}
          >
            Login With Google
          </CustomButton>
        </MainButtons>
      </TextContainer>
    </ParentContainer>
  );
}
