import { styled } from "styled-components";

import getImageURL from "../../controllers/getImageURL";
import goToProfile from "../../controllers/goToProfile";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  border-radius: 10px;

  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: var(--translucent);
  }
`;

const Lines = styled.div`
  display: flex;
  flex: 1;
  height: 90px;
  flex-direction: column;
  padding: 20px;
  border: 1px solid var(--translucentHard);
  gap: 10px;
  border-radius: 0 10px 10px 0;
`;
const Line1 = styled.div`
  font-size: 21px;
  text-transform: capitalize;

  font-weight: 800;
`;
const Line2 = styled.div``;
const Icon = styled.div``;

const Image = styled.img`
  height: auto;
  height: 90px;
  width: 90px;
  background-color: #fff;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
`;

export default function ProfileFeedItem({ item }) {
  return (
    <Container onClick={goToProfile(item.username)}>
      <Image src={getImageURL(item.profileImage, true)} />
      <Lines>
        <Line1>{item.username}</Line1>
        <Line2>{item.tagline}</Line2>
      </Lines>
    </Container>
  );
}
