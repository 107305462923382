import { styled } from "styled-components";
import { CustomLexicalEditor } from "./CustomLexicalEditor";
import getImageURL from "../../controllers/getImageURL";
import MaterialInput from "../helperComponents/MaterialInput";

const Container = styled.div`
  display: flex;
  /* background: var(--translucent); */
  padding: 0;
  border-radius: 15px;
  flex-direction: column;
  gap: 30px;
  width: 100%;

  flex: 1;
  font-size: 21px;
`;

const Image = styled.img`
  width: 100%;
  min-height: 200px;
  background-color: var(--translucent);
  height: auto;
  border-radius: 5px;
`;

const TitleText = styled.div`
  font-weight: 800;
  font-size: 40px;
`;

export default function RichTextEditor({ value, onChange, readOnly }) {
  if (!value) value = { title: "Untitled", content: null };

  let mainImage = readOnly ? value.image : value.image;

  let titleComp = (
    <MaterialInput
      // style={{cursor: readOnly?"":"text" }}
      label="Title"
      placeholder="Title"
      // variant="standard"
      // fontWeight={900}
      // fontSize={window.innerWidth > 900 ? "23px" : "25px"}
      // multiline={true}
      value={value.title}
      onChange={onTitleChange}
    />
  );

  if (readOnly) {
    titleComp = <TitleText>{value.title}</TitleText>;
  }

  let containerStyle = null;

  if (readOnly) {
    containerStyle = { marginTop: "0px", gap: "40px" };
  }

  return (
    <Container style={containerStyle}>
      {titleComp}

      {mainImage ? <Image src={getImageURL(mainImage)} /> : null}

      <CustomLexicalEditor
        addButtonPosX={"calc(((100vw - var(--chapterWidth)) / 2) - 50px)"}
        readOnly={readOnly}
        initialEditorState={readOnly ? value.content : value.content}
        onEditorChange={onEditorChange}
      />
    </Container>
  );

  function onTitleChange({ target }) {
    if (readOnly) return true;
    let newValue = { ...value };
    newValue.title = target.value;
    onChange(newValue);
  }

  function onEditorChange(newEditorState) {
    if (readOnly) return true;
    let newValue = { ...value };
    newValue.content = newEditorState;
    onChange(newValue);
  }
}
