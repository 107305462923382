import { styled } from "styled-components";
import ContentActionButtons from "../ContentActionButtons";
import ChapterBox from "./ChapterBox";
import getImageURL from "../../../controllers/getImageURL";
import { useEffect, useState } from "react";
import { serverLine } from "../../../controllers/serverLine";
import LoadingSection from "../../helperComponents/LoadingSection";
import BookCoverPreview from "../../editors/BookCoverPreview";
import checkIsMobile from "../../../controllers/utils/checkIsMobile";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;

const Image = styled.img`
  width: 38vw;
  height: auto;
  border-radius: 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex: 1;
  padding-top: 20px;
`;

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ChapterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 900;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 62vw;
  gap: 100px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Core = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Chapters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Type = styled.div`
  font-size: 15px;
  opacity: 0.5;
  margin-bottom: -30px;
`;

const ChapterLabel = styled.div`
  font-size: 18px;
  opacity: 0.5;
  margin-bottom: -5px;
`;

const Desc = styled.div``;

export default function RenderBook({ value, setValue }) {
  let chaptersComp = null;

  const [chapters, setChapters] = useState(value.chapters);

  useEffect(() => {
    if (!value.chapters) {
      serverLine.get("/content/?contentID=" + value._id).then((newData) => {
        if (newData.chapters) {
          setChapters(newData.chapters);
        }
      });
    }
  }, []);

  if (chapters) {
    chaptersComp = (
      <Chapters>
        {chapters.map((item, index) => (
          <ChapterBox index={index + 1} key={item._id} item={item} />
        ))}
      </Chapters>
    );
  }

  if (!chapters)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  let bookPreviewWidth = 400;

  let isMobile = checkIsMobile();

  if (isMobile) {
    bookPreviewWidth = 300;
  }
  return (
    <Container>
      <Main>
        <Core>
          <BookCoverPreview
            title={value.title}
            data={value.cover}
            width={bookPreviewWidth}
          />
          <TextContainer>
            <Intro>
              <Title>{value.title}</Title>
              <Desc>{value.content.description}</Desc>
            </Intro>

            <ChapterSection>
              <ChapterLabel>Chapters</ChapterLabel>
              {chaptersComp}
            </ChapterSection>

            <Desc>Score: {value.score ? value.score : 0}</Desc>
          </TextContainer>
        </Core>

        <ContentActionButtons content={value} setContent={setValue} />
      </Main>
    </Container>
  );
}
