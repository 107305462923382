import { styled } from "styled-components";
import getDateString from "../../controllers/getDateString";
import goTo from "../../controllers/goTo";
import SemanticButton from "../helperComponents/SemanticButton";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 25px;
  flex-direction: column;
  border-radius: 10px;
  gap: 20px;
  cursor: pointer;
  color: var(--color);
  background: var(--glassGradient);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--hardShadow);

  &:hover {
    background-color: var(--primaryColor);
  }
`;
const Title = styled.h2`
  margin: 0;
`;
const SubTitle = styled.div`
  font-size: 18px;
  text-transform: capitalize;
  opacity: 0.8;
`;

export default function DraftItem({ item }) {
  return (
    <SemanticButton href={`/edit-content/` + item._id}>
      <Container>
        <Title>{item.title ? item.title : "Untitled"}</Title>
        <SubTitle>{getSubtitle()}</SubTitle>
      </Container>
    </SemanticButton>
  );

  function getSubtitle() {
    return `${
      item.published ? "Published" : "Draft"
    } . ${item.type.toLowerCase()} . ${getDateString(
      new Date(item.createdAt)
    )}`;
  }
}
