import { useContext, useState } from "react";
import { serverLine } from "../../controllers/serverLine";
import CustomButton from "../helperComponents/CustomButton";
import Context from "../../Context";

export default function UserBanUnbanButton({ item, subjectType }) {
  const { popupAlert } = useContext(Context);
  const [status, setStatus] = useState(item.banned);

  console.log("status", status);
  let banButton = (
    <CustomButton variant="filled" onClick={banAuthor("BAN")}>
      Ban
    </CustomButton>
  );

  let unbanButton = (
    <CustomButton variant="filled" onClick={banAuthor("UN-BAN")}>
      Un-Ban
    </CustomButton>
  );

  if (status) return unbanButton;

  return banButton;

  function banAuthor(action) {
    return () => {
      serverLine.post("/ban", {
        subjectID: item._id,
        subjectType: subjectType,
        action: action,
      });

      console.log("status2", status);
      setStatus(status ? false : true);
    };
  }
}
