import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;
const Name = styled.div`
  font-size: 20px;
  /* font-weight: 800; */
`;
const Btn = styled.div`
  padding: 0px 25px;
  font-size: 18px;
  border-radius: 0;
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background: var(--translucent);
  cursor: pointer;
  white-space: nowrap;
  height: 50px;

  ${({ variant }) => {
    if (variant === "fluid")
      return `
      border-radius:10px;

    `;
  }}

  ${({ isActive }) => {
    if (isActive)
      return `
        background:var(--primaryColor) !important;
       
    `;

    return `
    &:hover {
      background: var(--glassGradient) !important;
    }
    
    `;
  }}

${({ isActive, disableInActive }) => {
    if (!isActive && disableInActive)
      return `
        opacity:0.5;
       
    `;
  }}
`;
const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  height: 50px;
  /* background: var(--translucent); */
  overflow: hidden;
  /* width: 100%; */
  gap: 0px;
  flex-wrap: wrap;

  background: var(--glassGradient);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--hardShadow);

  ${({ variant }) => {
    if (variant === "fluid")
      return `
      overflow: unset;
      flex-wrap:wrap;
      gap:10px;
    `;
  }}
`;

const ButtonLabel = styled.div``;
export default function CustomToggle({
  variant,
  name,
  value,
  onChange,
  options,
  style,
  toggleStyle,
  btnStyle,
  disableInActive,
  btnPrefix,
}) {
  if (!options) {
    if (!value) value = false;

    options = [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
  }

  let PrefixComp = btnPrefix;

  let tgl = (
    <Toggle variant={variant} style={toggleStyle}>
      {options.map((item) => (
        <Btn
          disableInActive={disableInActive}
          variant={variant}
          style={btnStyle}
          key={item.value}
          onClick={() => {
            onChange(item.value);
          }}
          isActive={item.value === value}
        >
          {PrefixComp ? <PrefixComp config={item} /> : null}
          <ButtonLabel>{item.label}</ButtonLabel>
        </Btn>
      ))}
    </Toggle>
  );

  if (!name) return tgl;

  return (
    <Container style={style}>
      {name ? <Name>{name}</Name> : null}

      {tgl}
    </Container>
  );
}
