import styled from "styled-components";

const CodeActionBtn = styled.button`
  background-color: #222;
  color: #fff;
  padding: 0 15px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primaryColor);
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #444;
  }
`;

export default CodeActionBtn;
