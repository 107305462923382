import getColumnsAndItemsPerSlider from "../../controllers/utils/calculateFeedSettings";
import ArticleCard from "../cardForProfile/ArticleCard";
import BookCard from "../cardForProfile/BookCard";
import UserCard from "../cardForProfile/UserCard";
import CustomMasonry from "../helperComponents/CustomMasonry";
import InfiniteScroll from "../helperComponents/InfiniteScroll";

export default function InfiniteFeed({
  list,
  type,
  doLoading,
  loading,

  reachedTheEnd,
}) {
  let { columns } = getColumnsAndItemsPerSlider({ type });

  let Comp = BookCard;

  if (type == "ARTICLE") Comp = ArticleCard;

  if (type == "USER") Comp = UserCard;

  let items = list.map((item) => (
    <Comp variant={"FEED"} key={item._id} item={item} />
  ));

  return (
    <InfiniteScroll
      loading={loading}
      load={doLoading}
      reachedTheEnd={reachedTheEnd}
    >
      <CustomMasonry maxCol={columns} list={items} />
    </InfiniteScroll>
  );
}
