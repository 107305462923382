import { styled } from "styled-components";
import { useContext } from "react";
import ContentFeed from "./ContentFeed";
import Context from "../../Context";
import LoadingSection from "../helperComponents/LoadingSection";
import isLoggedIn from "../../controllers/isLoggedIn";
import LoggedOutSection from "../core/LoggedOutSection";

const Hr = styled.div`
  min-height: 1px;
  width: 100%;
  background-color: var(--translucentHard);
  opacity: 0.3;

  @media (max-width: 900px) {
    margin-left: -10vw;
    width: 200vw;
    opacity: 0.5;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  width: 100%;

  padding: 0 0;
  padding-top: 0;

  @media (max-width: 900px) {
  }
`;

export default function MainFeed({ type = "BOOK" }) {
  let loggedIn = isLoggedIn();
  return (
    <Container>
      {!loggedIn ? <LoggedOutSection /> : null}

      <ContentFeed
        type={type}
        filterType={"TOP_RATED"}
        title={"Top Rated"}
        feedType={"SLIDER"}
      />

      {/* <ContentFeed
        type={type}
        filterType={"FOLLOWING"}
        title={"From the people you follow"}
        feedType={"SLIDER"}
      />

      <ContentFeed
        type={type}
        filterType={"INTERESTS"}
        title={"Based on your interest"}
        feedType={"SLIDER"}
      /> */}

      {type == "BOOK" ? (
        <ContentFeed
          type={"ARTICLE"}
          filterType={"TOP_RATED"}
          title={"Top Articles"}
          feedType={"SLIDER"}
        />
      ) : null}

      <ContentFeed
        type={type}
        filterType={"MOST_RECENT"}
        title={"Most Recent"}
        feedType={"INFINITE"}
      />

      <br />
      <br />
      <br />
    </Container>
  );
}
