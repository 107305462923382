import styled from "styled-components";
import goToAuthScreen from "../../../controllers/goToAuthScreen.js";
import { HiMenuAlt4 } from "react-icons/hi/index.js";
import { GrGoogle } from "react-icons/gr/index.js";
import { useContext } from "react";
import Context from "../../../Context.js";

import isLoggedIn from "../../../controllers/isLoggedIn.js";

import WithHeader from "../WithHeader.js";

import LoggedOutHomePage from "../LoggedOutSection.js";

import MainFeed from "../../feed/MainFeed.js";

export default function ArticlesPage() {
  return (
    <WithHeader>
      <MainFeed type={"ARTICLE"} />
    </WithHeader>
  );
}
