import { styled } from "styled-components";
import { useContext } from "react";
import getLikeString from "../../../controllers/getLikeString";
import getImageURL from "../../../controllers/getImageURL";
import Context from "../../../Context";
import goTo from "../../../controllers/goTo";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  /* justify-content: space-between; */
  border-radius: 10px;
  gap: 10px;
  font-size: 18px;
  /* gap: 20px; */
  cursor: pointer;
  font-weight: 600;
  /* align-items: center; */
  /* border: 1px solid var(--translucentHard); */

  background: var(--glassGradientHard);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);

  &:hover {
    background: var(--primaryColor);
  }
`;

const Lines = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  padding: 0;
  gap: 10px;
`;
const Line1 = styled.div`
  text-transform: capitalize;
`;
const Line2 = styled.div`
  opacity: 0.5;
`;
const Icon = styled.div``;

const Image = styled.img`
  height: auto;
  height: 70px;
  width: 70px;
  background-color: #fff;
  object-fit: cover;
  border-radius: 5px;
`;

const Index = styled.div`
  font-weight: 500;
  padding: 5px;
`;

export default function ChapterBox({ item, index }) {
  const { setPopupFeedItem } = useContext(Context);

  let img = null;

  if (item.image) img = <Image src={getImageURL(item.image)} />;

  let linesStyle = { borderRadius: "10px" };

  if (img) {
    linesStyle = { borderRadius: "0 10px 10px 0", borderLeft: "none" };
  }

  return (
    <Container onClick={openPost}>
      <Index>{index}.</Index>
      <Lines style={linesStyle}>
        <Line1>{item.title}</Line1>
      </Lines>
    </Container>
  );

  function openPost() {
    setPopupFeedItem(item);
    goTo("/content/" + item._id)();
  }
}
