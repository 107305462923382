import { styled } from "styled-components";
import RenderComment from "./RenderComment";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export default function RenderComments({ list }) {
  return (
    <Container>
      {list.map((item) => (
        <RenderComment key={item._id} item={item} />
      ))}
    </Container>
  );
}
