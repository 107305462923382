import styled from "styled-components";
import HorizontalBarGraph from "./libs/HorizontalBarGraph";
import { useEffect, useState } from "react";

import { serverLine } from "../../controllers/serverLine";
import hozStatDataProcessor from "../../controllers/stat/hozStatDataProcessor";
import StatContentBox from "./StatContentBox";
import LoadingSection from "../helperComponents/LoadingSection";
import WithHeader from "../core/WithHeader";
import UserStatSection from "./UserStats";
import ContentListWithStat from "./ContentListWithStat";
import BeautifulTitle from "../helperComponents/BeautifulTitle";
import { BiStats } from "react-icons/bi";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  height: 100%;
  align-items: center;
  /* padding: 100px 200px; */
  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  width: 100%;
`;

const Title = styled.h1``;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-bottom: 20px;
  border-bottom: 1px solid var(--translucentHard);
`;
const SubHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const MainTableHeading = styled.div`
  width: 200px;
  opacity: 0.5;
  font-size: 18px;
`;

const ColHeading = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  font-size: 18px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default function StatPage() {
  return (
    <WithHeader>
      <Container>
        <BeautifulTitle icon={<BiStats />}>Stat Page</BeautifulTitle>
        <UserStatSection />
        <ContentListWithStat />
        <br /> <br /> <br /> <br /> <br /> <br />
      </Container>
    </WithHeader>
  );
}
