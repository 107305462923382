import styled from "styled-components";
import CustomButton from "../../helperComponents/CustomButton";
import { MdAdd } from "react-icons/md";
import capitalizeFirstLetter from "../../../controllers/capitalizeFirstLetter";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  /* background-color: var(--translucent); */
  /* padding: 15px; */
  border-radius: 10px;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  /* white-space: nowrap; */
  font-size: 21px;
  /* font-weight: 500; */
  /* overflow: hidden; */
`;

export default function ChapterSearchRes({ item, onSelect }) {
  if (!item.title) return null;

  if (!item.title.trim()) return null;

  return (
    <Container>
      <Title>
        {capitalizeFirstLetter(item.title)}{" "}
        {!item.published ? "(Unpublished)" : true}
      </Title>

      <CustomButton
        height={"30px"}
        onClick={() => {
          onSelect(item);
        }}
        icon={<MdAdd />}
      ></CustomButton>
    </Container>
  );
}
