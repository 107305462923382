import { useContext, useEffect, useState } from "react";
import hozStatDataProcessor from "../../controllers/stat/hozStatDataProcessor";
import HorizontalBarGraph from "./libs/HorizontalBarGraph";
import { serverLine } from "../../controllers/serverLine";
import getUrlParams from "../../controllers/getUrlParams";
import Context from "../../Context";
import WithHeader from "../core/WithHeader";
import BeautifulTitle from "../helperComponents/BeautifulTitle";
import styled from "styled-components";
import LoadingSection from "../helperComponents/LoadingSection";

const Title = styled.h2``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
`;

export default function ContentStatPage() {
  const { currentRoute } = useContext(Context);
  const [data, setData] = useState(null);
  let urlQuery = getUrlParams(currentRoute);
  let subjectID = urlQuery.get("subjectID");
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    serverLine.get(`/content-stat/?subjectID=${subjectID}`).then(setData);
  }, [subjectID]);

  useEffect(() => {
    serverLine.get("/content/?contentID=" + subjectID).then((newData) => {
      setPostData(newData);
    });
  }, [subjectID]);

  if (!data || !postData)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  return (
    <WithHeader>
      <Container>
        <BeautifulTitle>Content Feed</BeautifulTitle>
        <Title>{postData.title}</Title>

        <Main>
          <HorizontalBarGraph
            fields={["internalContentViewCount"]}
            title="Internal Views"
            dataProcessor={hozStatDataProcessor}
            rawData={data}
          />
          <HorizontalBarGraph
            fields={["internalContentReadCount"]}
            title="Internal Reads"
            dataProcessor={hozStatDataProcessor}
            rawData={data}
          />
          <HorizontalBarGraph
            fields={["externalContentViewCount"]}
            title="External Views"
            dataProcessor={hozStatDataProcessor}
            rawData={data}
          />
          <HorizontalBarGraph
            fields={["externalContentReadCount"]}
            title="External Reads"
            dataProcessor={hozStatDataProcessor}
            rawData={data}
          />
        </Main>
      </Container>
    </WithHeader>
  );
}
