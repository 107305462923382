import { styled } from "styled-components";

import { serverLine } from "../../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";
import WithHeader from "../WithHeader";

import Context from "../../../Context";

import getSubPath from "../../../controllers/getSubPath";
import RenderContent from "../../contentPage/RenderContent";
import LoadingSection from "../../helperComponents/LoadingSection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 50px; */
  /* overflow-y: scroll; */
  width: 100%;
  /* height: 100%; */
`;

const Main = styled.div`
  width: 62vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default function ContentPage() {
  const { currentRoute } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState(null);

  let contentID = getSubPath(1, currentRoute);

  useEffect(() => {
    serverLine.get("/content/?contentID=" + contentID).then((newData) => {
      setPostData(newData);
      setLoading(false);
    });
  }, [currentRoute]);

  if (!postData || loading)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  return (
    <WithHeader>
      <Container>
        <Main>
          <RenderContent setPostData={setPostData} postData={postData} />
          <br /> <br /> <br /> <br /> <br /> <br />
        </Main>
      </Container>
    </WithHeader>
  );
}
