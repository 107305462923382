import { styled } from "styled-components";

import { useEffect, useState } from "react";
import { serverLine } from "../../../controllers/serverLine";
import WithHeader from "../WithHeader";
import DraftItem from "../../draft/DraftItem";
import CustomButton from "../../helperComponents/CustomButton";
import LoadingSection from "../../helperComponents/LoadingSection";
import CustomToggle from "../../helperComponents/CustomToggle";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Main = styled.div`
  width: 62vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;
const Title = styled.h2``;
const TheList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const typeOptions = [
  { value: "BOOK", label: "Book" },
  { value: "ARTICLE", label: "Article" },
];

const onlyDraftOptions = [
  { value: "YES", label: "Drafts" },
  { value: "NO", label: "Published" },
];

export default function ManageContents() {
  const [type, setType] = useState("BOOK");
  const [onlyDrafts, setOnlyDrafts] = useState("YES");

  const [items, setItems] = useState(null);

  useEffect(() => {
    setItems(null);
    serverLine
      .get(`/content-list/?type=${type}&onlyDrafts=${onlyDrafts}`)
      .then(setItems);
  }, [type, onlyDrafts]);

  if (!items)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  let list = renderItems();

  return (
    <WithHeader>
      <Container>
        <Main>
          <Top>
            <Title>Manage Books & Articles</Title>

            <Right>
              <CustomToggle
                options={typeOptions}
                value={type}
                onChange={setType}
              />
              <CustomToggle
                options={onlyDraftOptions}
                value={onlyDrafts}
                onChange={setOnlyDrafts}
              />
            </Right>
          </Top>

          <TheList>
            {list.length ? (
              list
            ) : (
              <CustomButton style={{ opacity: 0.5 }} variant="minimal">
                Nothing Found
              </CustomButton>
            )}

            <br />
            <br />
            <br />
            <br />
            <br />
          </TheList>
        </Main>
      </Container>
    </WithHeader>
  );

  function renderItems() {
    let toReturn = [];

    for (let item of items) {
      toReturn.push(<DraftItem item={item} />);
    }

    return toReturn;
  }
}
