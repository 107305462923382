import styled from "styled-components";
import goTo from "../../controllers/goTo";

const Row = styled.div`
  display: flex;
  padding: 30px 0;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 25px;

  background: var(--glassGradient);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);
  cursor: pointer;
  padding: 25px;
  border-radius: 10px;

  &:hover {
    background: var(--primaryColor);
  }
`;
const Title = styled.div`
  white-space: nowrap;
  font-size: 19px;
  font-weight: 900;
  width: 100%;
  /* max-width: 30vw; */
  overflow: hidden;
`;
const NumCol = styled.div`
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  /* width: 200px; */
`;

const Stat = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;

export default function StatContentBox({ item }) {
  let {
    _id,
    title,
    internalContentViewCount,
    internalContentReadCount,
    likeCount,
    dislikeCount,
    externalContentViewCount,
    externalContentReadCount,
  } = item;

  if (!internalContentViewCount) internalContentViewCount = 0;
  if (!internalContentReadCount) internalContentReadCount = 0;
  if (!likeCount) likeCount = 0;
  if (!dislikeCount) dislikeCount = 0;
  if (!externalContentViewCount) externalContentViewCount = 0;
  if (!externalContentReadCount) externalContentReadCount = 0;
  return (
    <Row onClick={goTo("/content-stats/?subjectID=" + _id)}>
      <Title>{title}</Title>

      <Stat>
        <NumCol>Like Count: {likeCount}</NumCol>
        <NumCol>Dislike Count: {dislikeCount}</NumCol>
        <NumCol>Internal Views: {internalContentViewCount}</NumCol>
        <NumCol>Internal Reads: {internalContentReadCount}</NumCol>
        <NumCol>External Views: {externalContentViewCount}</NumCol>
        <NumCol>External Reads: {externalContentReadCount}</NumCol>
      </Stat>
    </Row>
  );
}
