import styled from "styled-components";
import Header from "./Header.js";
import { useContext, useEffect } from "react";
import Context from "../../Context.js";
import parseScrollData from "../../controllers/parseScrollData.js";
import onChangeStop from "../../controllers/onChangeStop.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  overflow: hidden;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  overflow: hidden;
  background-size: cover;
  padding-bottom: 0;
  overflow-y: scroll;
  gap: 80px;
  align-items: center;

  @media (max-width: 900px) {
    gap: 50px;
    padding: 0 var(--mobilePadding);
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--coreWidth);
  align-items: center;
  height: auto;
  margin-bottom: 50px;

  @media (max-width: 1300px) {
    width: var(--coreWidthSmall);
  }

  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 150px;
  }
`;

export default function WithHeader({ children }) {
  const { setScrollData } = useContext(Context);
  useEffect(() => {
    const stickyElement = document.querySelector(".header");
    const scrollContainer = document.querySelector(".mainScroll");
    const stickyOffset = 50; //how much margin is above the header

    scrollContainer.addEventListener("scroll", () => {
      if (scrollContainer.scrollTop >= stickyOffset) {
        stickyElement.classList.add("is-sticky");
      } else {
        stickyElement.classList.remove("is-sticky");
      }
    });
  }, []);

  return (
    <Container className="mainScroll" onScroll={onScroll}>
      <Header />
      <Main>{children}</Main>
    </Container>
  );

  function onScroll(e) {
    onChangeStop(
      "mainScroll",
      () => {
        setScrollData(parseScrollData(e));
      },
      800
    );
  }
}
