import { styled } from "styled-components";
import { useContext } from "react";
import Context from "../../Context";
import RegisterView from "../contentPage/RegisterView";
import LoadingSection from "../helperComponents/LoadingSection";
import RenderBook from "./cardForContentPage/RenderBook";
import RenderArticle from "./cardForContentPage/RenderArticle";
import adminAccess from "../../data/adminAccess";

const Info = styled.div`
  margin-top: 50px;
  opacity: 0.5;
`;

export default function RenderContent({ postData, setPostData }) {
  const { loggedInUserID, loggedInUser } = useContext(Context);

  if (!postData) return null;
  if (postData.loading) return <LoadingSection />;

  if (!postData.published)
    return <Info>Content has not been published, yet</Info>;

  if (postData.archived) return <Info>Content has been deleted.</Info>;

  let content = <RenderBook value={postData} setValue={setPostData} />;

  if (postData.type == "ARTICLE") {
    content = <RenderArticle value={postData} setValue={setPostData} />;
  }

  let isAdmin = false;

  if (loggedInUser) {
    isAdmin = adminAccess.includes(loggedInUser.email);
  }

  if (postData.banned) {
    if (isAdmin) {
      content = (
        <>
          <Info>This content is banned.</Info>
          <br />
          <br /> <br /> <br />
          {content}
        </>
      );
    } else if (postData.authorUserID === loggedInUserID) {
      content = (
        <>
          <Info>Your content has been banned.</Info>
          <br />
          <br /> <br /> <br />
          {content}
        </>
      );
    } else {
      content = (
        <>
          <Info>{postData.title}</Info>
          <Info>This content has been banned.</Info>
        </>
      );
    }
  }

  return [
    content,
    <RegisterView subjectType={"CONTENT"} subjectID={postData._id} />,
  ];
}
