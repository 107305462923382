import styled from "styled-components";
import getImageURL from "../../controllers/getImageURL";
import ContentCardBoilerplate from "./ContentCardBoilerplate";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import getLikeString from "../../controllers/getLikeString";
import getCategoryString from "../../controllers/utils/getCategoryString";
import goTo from "../../controllers/goTo";
import GetArticleAndUserCardDimension from "../../controllers/utils/getArticleAndUserCardDimension";

const Container = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  background: var(--glassGradient);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);
  padding: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  &:hover {
    background: var(--primaryColor);
  }
`;

const TheImage = styled.img`
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 5px;
  pointer-events: none;
  box-shadow: var(--lightShadow);

  @media (max-width: 900px) {
    width: 45px;
    height: 45px;
  }
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 19px;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const Lines = styled.div`
  width: 100%;

  color: var(--color);
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 900px) {
    padding: 20px 10px;
    font-size: 15px;
    gap: 10px;
  }
`;

const Line3 = styled.div`
  opacity: 0.6;
  font-size: 13px;
  font-weight: 600;
  @media (max-width: 900px) {
    font-size: 13px;
  }
`;

export default function UserCard({ item }) {
  let title = item.name;

  let dimension = GetArticleAndUserCardDimension();

  let theCore = (
    <Lines>
      <Title>{title}</Title>

      <Line3>{capitalizeFirstLetter(item.tagline)}</Line3>
    </Lines>
  );

  return (
    <Container
      style={{ width: dimension.width }}
      onClick={goTo("/" + item.username)}
    >
      <TheImage src={getImageURL(item.profilePicture, true)} />
      {theCore}
    </Container>
  );
}
