import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";

import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import { serverLine } from "../../controllers/serverLine";
import openLoginPopup from "../../controllers/openLoginPopup";
import Context from "../../Context";
import CustomButton from "../helperComponents/CustomButton";
import {
  MdBookmark,
  MdOutlineBookmarkAdded,
  MdOutlineBookmarkBorder,
} from "react-icons/md";

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export default function BookmarkButton({
  initialStatus,
  initialCount,
  contentID,

  style,
}) {
  const { loggedInUserID, popupAlert } = useContext(Context);

  const [status, setStatus] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setStatus(initialStatus);
    setCount(initialCount ? initialCount : 0);
  }, [contentID]);

  if (!style) style = { gap: "10px" };

  return (
    <Buttons>
      <CustomButton
        style={style}
        variant={"filled"}
        onClick={like}
        icon={status ? <MdBookmark /> : <MdOutlineBookmarkBorder />}
      >
        {count.toString()}
      </CustomButton>
    </Buttons>
  );

  function updateStatus(newStatus) {
    setStatus(newStatus);
  }

  function like() {
    if (!loggedInUserID) return openLoginPopup();

    serverLine.post("/bookmark", { subjectID: contentID });

    let toIncrementLikeCount = 0;

    let newStatus = !status;

    if (status) {
      toIncrementLikeCount = -1;
    } else {
      // popupAlert("Bookmark saved");
      toIncrementLikeCount = 1;
    }

    updateStatus(newStatus);
    setCount(count + toIncrementLikeCount);
  }
}
