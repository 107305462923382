import styled from "styled-components";
import getImageURL from "../../controllers/getImageURL";
import ContentCardBoilerplate from "./ContentCardBoilerplate";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import getLikeString from "../../controllers/getLikeString";
import BookCoverPreview from "../editors/BookCoverPreview";
import getCategoryString from "../../controllers/utils/getCategoryString";
import getBookCardDimension from "../../controllers/utils/getBookCardDimension";

const Container = styled.div`
  width: 300px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  /* border: 1px solid var(--translucentHard); */
  /* background-color: var(--translucent); */
  position: relative;
  box-shadow: var(--hardShadow);
`;

const TheImage = styled.img`
  width: 300px;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  pointer-events: none;
`;

const Title = styled.div`
  font-weight: 900;
  text-transform: capitalize;
  font-size: 40px;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const Lines = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0%;
  left: 0;
  padding: 20px 25px;

  background: linear-gradient(#fff0, rgb(106 101 101));
  color: var(--color);
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 900px) {
    padding: 20px 10px;
    font-size: 15px;
    gap: 10px;
  }
`;

const Line3 = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--color);
  padding: 10px 5px;
  opacity: 0.8;
  @media (max-width: 900px) {
    font-size: 11px;
  }
`;

export default function BookCard({ item, variant }) {
  let theChild = null;

  let dimension = getBookCardDimension();

  window.getBookCardDimension = getBookCardDimension;
  return (
    <ContentCardBoilerplate item={item} variant={variant}>
      {theChild}

      <BookCoverPreview
        data={item.cover}
        title={item.title}
        width={dimension.rawWidth}
      />

      {variant === "FEED" ? (
        <Line3>
          {capitalizeFirstLetter(item.author.username)} .{" "}
          {getCategoryString(item.category)}
        </Line3>
      ) : null}
    </ContentCardBoilerplate>
  );
}
