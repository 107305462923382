import styled from "styled-components";
import goToAuthScreen from "../../../controllers/goToAuthScreen.js";
import { HiMenuAlt4 } from "react-icons/hi/index.js";
import { GrGoogle } from "react-icons/gr/index.js";
import { useContext } from "react";
import Context from "../../../Context.js";

import isLoggedIn from "../../../controllers/isLoggedIn.js";

import WithHeader from "../WithHeader.js";

import LoggedOutHomePage from "../LoggedOutSection.js";

import MainFeed from "../../feed/MainFeed.js";
import ContentFeed from "../../feed/ContentFeed.js";
import BeautifulTitle from "../../helperComponents/BeautifulTitle.js";

export default function ManageBans() {
  return (
    <WithHeader>
      <BeautifulTitle>Manage Banned</BeautifulTitle>

      <ContentFeed
        type={"BOOK"}
        filterType={"BANNED"}
        title={"Banned Books"}
        feedType={"SLIDER"}
      />

      <ContentFeed
        type={"ARTICLE"}
        filterType={"BANNED"}
        title={"Banned Articles"}
        feedType={"SLIDER"}
      />

      <ContentFeed
        type={"USER"}
        filterType={"BANNED"}
        title={"Banned Users"}
        feedType={"SLIDER"}
      />
    </WithHeader>
  );
}
