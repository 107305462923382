import { styled } from "styled-components";
import { useContext } from "react";
import Context from "../../Context";
import PopupImageUploader from "./PopupImageUploader";
import CustomButton from "../helperComponents/CustomButton";
import BookCoverPreview from "./BookCoverPreview";
import { Slider } from "@mui/material";
import {
  GrTextAlignCenter,
  GrTextAlignLeft,
  GrTextAlignRight,
} from "react-icons/gr";
import {
  TbAlignBoxBottomCenter,
  TbAlignBoxTopCenter,
  TbAlignCenter,
} from "react-icons/tb";
import CustomToggle from "../helperComponents/CustomToggle";
import PopupBookCoverEditor from "./PopupBookCoverEditor";
import { PiPaintBrush } from "react-icons/pi";
import {
  MdOutlineDelete,
  MdOutlineImage,
  MdOutlineUpload,
} from "react-icons/md";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 300px;
  /* justify-content: space-between; */
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: center;
`;

/*

  * Vertical alignment
  * Horizontal alignment
  * Font Weight
  * Font family
  * Font Size
  * Zoom
  * Horizontal
  * Vertical

*/

let horizontalAlignmentOptions = [
  { label: <GrTextAlignLeft />, value: "LEFT" },
  { label: <GrTextAlignCenter />, value: "CENTER" },
  { label: <GrTextAlignRight />, value: "RIGHT" },
];

let verticalAlignmentOptions = [
  { label: <TbAlignBoxTopCenter />, value: "LEFT" },
  { label: <TbAlignCenter />, value: "CENTER" },
  { label: <TbAlignBoxBottomCenter />, value: "RIGHT" },
];

let fontWeightOptions = [
  { label: "Light", value: "LIGHT" },
  { label: "Medium", value: "MEDIUM" },
  { label: "Bold", value: "BOLD" },
];

let fontFamilyOptions = [
  { label: "Light", value: "MONO" },
  { label: "Medium", value: "SANS_SERIF" },
  { label: "Bold", value: "SERIF" },
];

let btnStyle = { borderRadius: "10px" };

export default function BookCoverEditor({ value, onChange }) {
  const { setForm } = useContext(Context);

  let uploadCustomCover = (
    <CustomButton
      style={btnStyle}
      onClick={addImage("customCover")}
      variant="filled"
      icon={<MdOutlineUpload />}
    >
      Upload Custom Cover
    </CustomButton>
  );

  let removeCustomCoverButton = (
    <CustomButton
      style={btnStyle}
      icon={<MdOutlineDelete />}
      variant="filled"
      onClick={removeCustomCover}
    >
      Remove Custom Cover
    </CustomButton>
  );

  let designCoverButton = (
    <CustomButton
      icon={<PiPaintBrush />}
      style={btnStyle}
      variant="filled"
      onClick={openDesigner}
    >
      Design Book Cover
    </CustomButton>
  );

  return (
    <Container>
      <BookCoverPreview width={300} title={value.title} data={value.cover} />

      <Buttons>{getButtons()}</Buttons>
    </Container>
  );

  function openDesigner() {
    setForm({
      title: "Design Book Cover",
      component: <PopupBookCoverEditor value={value} onChange={onChange} />,
      settings: { width: "50vw" },
    });
  }

  function getButtons() {
    if (value.cover)
      if (value.cover.customCover) return [removeCustomCoverButton];

    let options = [designCoverButton, uploadCustomCover];

    return options;
  }

  function removeCustomCover() {
    let newCover = {};

    if (value.cover) newCover = value.cover;

    delete newCover.customCover;

    onChange({
      ...value,
      cover: newCover,
    });
  }

  function addImage(field) {
    return () => {
      setForm({
        component: <PopupImageUploader callback={addImageCallback} />,
      });

      function addImageCallback(newImageData) {
        setForm(null);
        let newCover = {};

        if (value.cover) newCover = value.cover;

        newCover[field] = newImageData;

        onChange({
          ...value,
          cover: newCover,
        });
      }
    };
  }
}
