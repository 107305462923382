import styled from "styled-components";
import getImageURL from "../../controllers/getImageURL";

const Container = styled.div`
  position: relative;
  background-color: var(--color);
  box-shadow: var(--lightShadow);
  border-radius: 10px;
  overflow: hidden;
`;

const Background = styled.img`
  height: 100%;
  width: auto;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;
const Title = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 900;
  padding: 20px 25px;
  /* background: linear-gradient(#fff0, var(--color)); */
  color: var(--primaryColor);
  z-index: 10;

  text-shadow: rgba(0, 0, 0, 0.25) 1px 1px 19px;
`;
const Image = styled.img`
  border-radius: 10px;
  object-fit: cover;
  box-shadow: var(--lightShadow);
`;

//For a book dimension is 1 : 1.5

export default function BookCoverPreview({ title, data, width, style }) {
  //size: SMALL, MEDIUM, BIG
  //Small is for mobile feed
  //medium is for desktop feed
  //big is for preview in book page

  if (!title) title = "Untitled Book Cover";

  if (!data) data = {};

  let height = width * 1.5;
  let padding = 30;
  let fontSize = 40;

  if (width <= 300) {
    fontSize = 30;
    padding = 20;
  }

  if (width <= 250) {
    fontSize = 24;
    padding = 15;
  }

  if (width <= 190) {
    // For mobile
    fontSize = 19;
    padding = 15;
  }

  if (data.fontSize) fontSize *= data.fontSize;
  if (data.padding) padding *= data.padding;

  fontSize = fontSize + "px";
  height = height + "px";
  width = width + "px";

  let titleStyle = { padding };
  let backgroundStyle = {};

  if (data.fontWeight) {
    titleStyle.fontWeight = parseFontWeight(data.fontWeight);
  }

  if (data.fontFamily) {
    titleStyle.fontFamily = parseFontFamily(data.fontFamily);
  }

  if (data.verticalAlignment) {
    let verStyle = parseVerticalAlignment(data.verticalAlignment);

    titleStyle = { ...titleStyle, ...verStyle };
  }

  if (data.horizontalAlignment) {
    let hozStyle = parseHorizontalAlignment(data.horizontalAlignment);

    titleStyle = { ...titleStyle, ...hozStyle };
  }

  if (data.color) titleStyle.color = data.color;

  backgroundStyle.transform = parseBgImage(data);

  let typicalCover = (
    <Container style={{ ...style, height, width }}>
      {data.background ? (
        <Background
          style={backgroundStyle}
          src={getImageURL(data.background)}
        />
      ) : null}

      <Title style={{ ...titleStyle, fontSize }}>{title}</Title>
    </Container>
  );

  if (data.customCover)
    return (
      <Image style={{ height, width }} src={getImageURL(data.customCover)} />
    );

  return typicalCover;
}

function parseFontWeight(field) {
  let obj = { LIGHT: 200, MEDIUM: 500, BOLD: 900 };
  return obj[field];
}

function parseFontFamily(field) {
  let obj = {
    MONO: `"Inconsolata", monospace`,
    SANS_SERIF: "Roboto",
    SERIF: "playfair-display",
  };
  return obj[field];
}

function parseHorizontalAlignment(field) {
  if (field == "LEFT") return { left: 0 };
  if (field == "CENTER") return { width: "100%", textAlign: "center", left: 0 };
  if (field == "RIGHT") return { right: 0, textAlign: "right" };
}

function parseVerticalAlignment(field) {
  if (field == "TOP") return { top: 0 };
  if (field == "CENTER")
    return {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      top: 0,
    };
  if (field == "RIGHT") return { bottom: 0 };
}

function parseBgImage(data) {
  let newStyle = "";

  if (data.zoom) newStyle = `scale(${data.zoom})`;
  if (data.translateX) newStyle += ` translateX(${data.translateX}%)`;
  if (data.translateY) newStyle += ` translateY(${data.translateY}%)`;

  return newStyle;
}
