import {
  $getNearestNodeFromDOMNode,
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  $setSelection,
} from "lexical";
import * as React from "react";
import { useState } from "react";
import { useDebounce } from "../utils";
import { FaRegCopy } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import CodeActionBtn from "./CodeActionBtn";
import {
  $createCodeNode,
  $isCodeNode,
  getDefaultCodeLanguage,
  getCodeLanguages,
} from "@lexical/code";
import { useCallback } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import Context from "../../../../../Context";
import capitalizeFirstLetter from "../../../../../controllers/capitalizeFirstLetter";

export function ChangeLanguageBtn({ currentLanguage }) {
  const { setForm, popupAlert } = React.useContext(Context);
  const [editor] = useLexicalComposerContext();
  const codeLanguages = React.useMemo(() => getCodeLanguages(), []);

  const [state, setState] = useState({});

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    // if ($isRangeSelection(selection)) {
    const anchorNode = selection.anchor.getNode();
    const element =
      anchorNode.getKey() === "root"
        ? anchorNode
        : anchorNode.getTopLevelElementOrThrow();
    const elementKey = element.getKey();
    const elementDOM = editor.getElementByKey(elementKey);

    let newElementKey = "";
    let newLanguageCode = "";
    let isCodeNode = false;

    if (elementDOM !== null) {
      newElementKey = elementKey;
      if ($isCodeNode(element)) {
        isCodeNode = true;
        newLanguageCode = element.getLanguage() || getDefaultCodeLanguage();
      }
    }

    let newState = {
      elementKey: newElementKey,
      isCodeNode: isCodeNode,
      languageCode: newLanguageCode,
    };

    console.log(newState);
    setState(newState);
    // }
  }, [editor]);

  React.useEffect(() => {
    const unregisterListener = editor.registerUpdateListener(
      ({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }
    );
    return unregisterListener;
  }, [editor]);

  const onCodeLanguageSelect = useCallback(
    (value) => {
      editor.update(() => {
        if (state.elementKey !== null) {
          const node = $getNodeByKey(state.elementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(value);
          }
        }
      });
    },
    [editor, state.elementKey]
  );

  if (!state.isCodeNode) return null;

  let fieldVal = currentLanguage;
  if (!fieldVal) fieldVal = "Change Lang.";

  return (
    <CodeActionBtn onClick={showLanguageOptions}>{fieldVal}</CodeActionBtn>
  );

  function showLanguageOptions() {
    if (!state.isCodeNode) return popupAlert("Select the code editor first!");

    let options = [];

    for (let item of codeLanguages) {
      options.push({
        name: capitalizeFirstLetter(item),
        onClick: () => {
          onCodeLanguageSelect(item);
          setForm(null);
        },
      });
    }

    console.log(options);

    setForm({ options: options });
  }
}
