import { useContext } from "react";
import { AiOutlineArrowRight, AiOutlineCheck } from "react-icons/ai";
import { BsArrowBarRight } from "react-icons/bs";
import { styled } from "styled-components";
import Context from "../../Context";
import CustomToggle from "../helperComponents/CustomToggle";

const SectionIcon = styled.div``;

export default function OnboardingProgress({ currentSection }) {
  const { loggedInUser } = useContext(Context);

  if (loggedInUser.type == "CLIENT") return null;

  let options = [
    {
      label: "1. Basic",
      value: "Basic Info",
      onboardingField: "basicInfoAdded",
    },
    {
      label: "2. Education",
      value: "Education",
      onboardingField: "educationAdded",
    },
    {
      label: "3. Experience",
      value: "Experience",
      onboardingField: "experienceAdded",
    },
    {
      label: "4. Project",
      value: "Projects",
      onboardingField: "projectsAdded",
    },
    {
      label: "5. Certificates",
      value: "Certificates",
      onboardingField: "certificatesAdded",
    },
    {
      label: "6. Life Story",
      value: "LifeStory",
      onboardingField: "lifeStoryAdded",
    },
  ];

  return (
    <CustomToggle
      disableInActive={true}
      toggleStyle={{
        pointerEvents: "none",
      }}
      btnPrefix={BtnPrefix}
      onChange={console.log}
      options={options}
      value={currentSection}
    />
  );
}

function BtnPrefix({ config }) {
  let { onboardingField } = config;
  const { loggedInUser } = useContext(Context);

  if (!loggedInUser) return null;
  if (!loggedInUser.onboarding) return null;

  return loggedInUser.onboarding[onboardingField] ? (
    <SectionIcon>
      <AiOutlineCheck />
    </SectionIcon>
  ) : null;
}
