import styled from "styled-components";
import { useContext, useState } from "react";
import { useEffect } from "react";
import WithHeader from "../WithHeader";
import Context from "../../../Context";
import CustomToggle from "../../helperComponents/CustomToggle";

import parseScrollData from "../../../controllers/parseScrollData";
import NotificationsCore from "../../notifications/NotificationsCore";
import LoadingSection from "../../helperComponents/LoadingSection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
  align-items: center;
`;

const Title = styled.div`
  text-align: center;
  width: 100%;
  font-size: 21px;
  font-weight: 700;
`;

const Main = styled.div`
  width: 38vw;
  display: flex;
  flex-direction: column;
  gap: 35px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

export default function Notifications() {
  let { loggedInUser, scrollData } = useContext(Context);

  if (!loggedInUser) return <LoadingSection />;

  return (
    <WithHeader>
      <Container>
        <Main>
          <Title>Notifications</Title>
          <NotificationsCore scrollData={scrollData} />
          <br /> <br /> <br />
        </Main>
      </Container>
    </WithHeader>
  );
}
