import styled from "styled-components";
import getImageURL from "../../controllers/getImageURL";
import ContentCardBoilerplate from "./ContentCardBoilerplate";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import getLikeString from "../../controllers/getLikeString";
import getCategoryString from "../../controllers/utils/getCategoryString";
import GetArticleAndUserCardDimension from "../../controllers/utils/getArticleAndUserCardDimension";

const Container = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  background: var(--glassGradient);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);
  padding: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  &:hover {
    background: var(--primaryColor);
  }
`;

const TheImage = styled.img`
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 5px;
  pointer-events: none;
  box-shadow: var(--lightShadow);

  @media (max-width: 900px) {
    width: 45px;
    height: 45px;
    display: none;
  }
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 19px;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const Lines = styled.div`
  width: 100%;

  color: var(--color);
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Line3 = styled.div`
  opacity: 0.6;
  font-size: 13px;
  font-weight: 600;
  @media (max-width: 900px) {
    font-size: 11px;
  }
`;

export default function ArticleCard({ item, variant }) {
  let title = item.title;

  let theChild = null;

  let dimension = GetArticleAndUserCardDimension();

  let theCore = (
    <Lines>
      <Title>{title}</Title>

      {variant === "FEED" ? (
        <Line3>
          By {capitalizeFirstLetter(item.author.username)} .{" "}
          {getCategoryString(item.category)}
        </Line3>
      ) : null}
    </Lines>
  );

  theChild = (
    <Container style={{ width: dimension.width }}>
      <TheImage src={getImageURL(item.image)} />
      {theCore}
    </Container>
  );

  return (
    <ContentCardBoilerplate item={item} variant={variant}>
      {theChild}
    </ContentCardBoilerplate>
  );
}
