import { styled } from "styled-components";
import OnboardingProgress from "./OnboardingProgress";
import BrandContainer from "../brand/BrandContainer";
import BasicInfoEditor from "../editors/BasicInfoEditor";
import { useContext } from "react";
import Context from "../../Context";
import OnboardingBoilerplate from "./OnboardingBoilerplate";
import InterestsEditor from "../editors/InterestsEditor";

export default function AskInterests() {
  const { loggedInUser, setLoggedInUser } = useContext(Context);

  return (
    <OnboardingBoilerplate
    // title="What are your interests"
    // currentSection={"Basic Info"}
    >
      <InterestsEditor />
    </OnboardingBoilerplate>
  );
}
