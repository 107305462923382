import styled from "styled-components";

const FeedHr = styled.div`
  min-height: 1px;
  width: 100%;
  background-color: var(--translucentHard);
  opacity: 0.3;

  @media (max-width: 900px) {
    margin-left: -10vw;
    width: 200vw;
    opacity: 0.5;
  }
`;

export default FeedHr;
