import { styled } from "styled-components";
import { useContext } from "react";
import Context from "../../Context.js";
import categoriesAndSubcategories from "../../data/categoriesAndSubcategories.js";
import CustomToggle from "../helperComponents/CustomToggle.js";
import getCategoryOptions from "../../controllers/utils/getCategoryOptions.js";
import DropDownInput from "../helperComponents/DropDownInput.js";

export default function CategoryAdder({ value, onChange }) {
  return (
    <DropDownInput
      style={{ width: "300px" }}
      onChange={onChange}
      options={getCategoryOptions()}
      value={value}
      placeholder="Select Category"
      label="Category"
    />
  );
}
