import { useContext, useEffect, useState } from "react";
import WithHeader from "../core/WithHeader";
import BeautifulTitle from "../helperComponents/BeautifulTitle";
import CustomToggle from "../helperComponents/CustomToggle";
import LoadingSection from "../helperComponents/LoadingSection";
import { serverLine } from "../../controllers/serverLine";
import styled from "styled-components";
import Context from "../../Context";
import getUrlParams from "../../controllers/getUrlParams";
import ReportBox from "./ReportBox";
import CustomButton from "../helperComponents/CustomButton";
import getSubPath from "../../controllers/getSubPath";
import ReportedItem from "./ReportedItem";
import goTo from "../../controllers/goTo";
import UserBanUnbanButton from "../profilePage/UserBanUnbanButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 50px;
  /* width: 45vw; */
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Content = styled.div`
  width: 100%;
`;

const Box = styled.div`
  background-color: var(--glassGradient);

  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
const Title = styled.h3`
  margin: 0;
`;
const Desc = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const NumCol = styled.div``;

export default function ReportsPage() {
  const { currentRoute, popupAlert, setPopupFeedItem } = useContext(Context);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  let urlQuery = getUrlParams(currentRoute);

  let subjectID = urlQuery.get("subjectID");
  let subjectType = urlQuery.get("subjectType");

  useEffect(() => {
    serverLine
      .get(`/reports/?subjectID=${subjectID}&subjectType=${subjectType}`)
      .then((newData) => {
        setData(newData);
        setLoading(false);
      });
  }, []);

  if (loading || !data)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

 

  return (
    <WithHeader>
      <Container>
        <BeautifulTitle>Reports</BeautifulTitle>

        <Main>
          <Box>
            <Title>{data.reportItem.title}</Title>
            <Desc>
              <NumCol>New Reports: {data.reportItem.newReportsCount}</NumCol>
              <NumCol>Old Reports: {data.reportItem.oldReportsCount}</NumCol>
            </Desc>
          </Box>

          <Buttons>
            <CustomButton variant="filled" onClick={openPost}>
              Open
            </CustomButton>

            <UserBanUnbanButton
              subjectType={subjectType}
              item={data.reportItem}
            />

            <CustomButton
              variant="filled"
              onClick={goTo("/" + data.reportItem.author.username)}
            >
              Show Author
            </CustomButton>

            <CustomButton variant="filled" onClick={settleReports}>
              Settle Reports
            </CustomButton>

            <CustomButton variant="filled" href="/report-dashboard">
              Reports Dashboard
            </CustomButton>

            <CustomButton variant="filled" href="/manage-bans">
              Manage Bans
            </CustomButton>
          </Buttons>

          <List>
            {data.reports.map((item) => (
              <ReportBox item={item} />
            ))}
          </List>
        </Main>
      </Container>
    </WithHeader>
  );

  function openPost() {
    setPopupFeedItem(data.reportItem);
    goTo("/content/" + data.reportItem._id)();
  }

  function banItem(action) {
    return () => {
      serverLine.post("/ban", {
        subjectID: subjectID,
        subjectType,
        action: action,
      });

      popupAlert("Done");
    };
  }

  function settleReports() {
    serverLine.post("/settle-reports", {
      subjectID: subjectID,
      subjectType,
    });

    popupAlert("Reports have been settled");
  }
}
