import { styled } from "styled-components";
import { useContext } from "react";
import Context from "../../Context.js";
import { AiOutlineDown } from "react-icons/ai";
import PopupSelect from "./PopupSelect.js";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  width: 100%;
  background: var(--glassGradientHard);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);

  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  gap: 10px;
`;

const Label = styled.div`
  width: 100%;
  opacity: 1;
  font-size: 13px;
  white-space: nowrap;
  font-weight: 400;
  text-transform: capitalize;
`;

const Left = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

const SelectInputText = styled.div`
  font-size: 15px;
  white-space: nowrap;
  font-weight: 500;
`;
const SelectInputButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
`;

export default function DropDownInput(props) {
  const { setForm } = useContext(Context);

  let { value, placeholder, onChange, options, label, style, popupComponent } =
    props;

  let valToShow = getLabelFromValue({ value, options });

  let placeholderData = valToShow ? valToShow : placeholder;

  return (
    <Container
      style={style}
      onClick={() => {
        setForm({
          title: placeholder,
          component: popupComponent ? (
            popupComponent
          ) : (
            <PopupSelect value={value} options={options} onChange={onChange} />
          ),
        });
      }}
    >
      <Left>
        {label ? <Label>{label}</Label> : null}

        {placeholderData ? (
          <SelectInputText>{placeholderData}</SelectInputText>
        ) : null}
      </Left>
      <SelectInputButton>
        <AiOutlineDown />
      </SelectInputButton>
    </Container>
  );
}

function getLabelFromValue({ value, options }) {
  if (!options) return value; // In case of location input

  for (let item of options) {
    if (item.value == value) return item.label;
  }

  return false;
}
