import styled from "styled-components";
import swatchColors from "../../data/swatchColors";

const Container = styled.div`
  padding: 15px;
  background: var(--glassGradient);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  border-radius: 10px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;

const Color = styled.div`
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100px;
  cursor: pointer;
  /* box-shadow: var(--hardShadow); */
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--translucent);

  &:hover {
    transform: scale(1.2);
    box-shadow: var(--lightShadow);
  }
`;

export default function CustomSwatch({ onChange }) {
  let items = swatchColors.map((item) => (
    <Color style={{ background: item }} onClick={setColor(item)} />
  ));

  return <Container>{items}</Container>;

  function setColor(item) {
    return () => {
      onChange(item);
    };
  }
}
