let categoriesAndSubcategories = {
  PROGRAMMING: {
    name: "Programming",
    subCategories: {
      WEB_DEV: "Web Development",
      MOBILE_DEV: "Mobile Development",
      DATA_SCIENCE: "Data Science",
      AI: "Artificial Intelligence",
      DEVOPS: "Devops",
      GAME_DEV: "Game Development",
      CYBERSECURITY: "Cyber Security",
      PYTHON: "Python",
      JAVASCRIPT: "Javascript",
      NODEJS: "Node.js",
      C_PLUS_PLUS: "C++",
      JAVA: "JAVA",
      C_SHARP: "C#",
      PHP: "PHP",
      RUST: "Rust",
      KOTLIN: "Kotlin",
      RUBY: "Ruby",
      DART: "Dart",
      SWIFT: "Swift",
      SCALA: "Scala",
      DOT_NET: ".Net",
      ELIXIR: "Elixir",
      ERlANG: "Erlang",
    },
  },
  HISTORY: {
    name: "History",
    subCategories: {
      ANCIENT: "Ancient",
      MEDIEVAL: "Medieval",
      MODERN: "Modern",
      INDIA: "India",
      CHINA: "China",
      ASIA: "Asia",
      RUSSIA: "Russia",
      EUROPE: "Europe",
      AFRICA: "Africa",
      NORTH_AMERICA: "North America",
      SOUTH_AMERICA: "South America",
      AUSTRALIA: "Australia",
    },
  },
  SCIENCE: {
    name: "Science",
    subCategories: {
      PHYSICS: "Physics",
      CHEMISTRY: "Chemistry",
      BIOLOGY: "Biology",
    },
  },
  MATHS: {
    name: "Mathematics",
    subCategories: null,
  },
  FICTION: {
    name: "Fiction",
    subCategories: {
      FANTASY: "Fantasy",
      ROMANCE: "Romance",
      HORROR: "Horror",
      MYSTERY: "Mystery",
      THRILLER: "Thriller",
      DRAMA: "Drama",
      SCI_FI: "Sci-Fi",
      COMEDY: "Comedy",
    },
  },
  COMIC: {
    name: "Comic",
    subCategories: {
      SUPERHERO: "Superhero",
      FANTASY: "Fantasy",
      ROMANCE: "Romance",
      HORROR: "Horror",
      MYSTERY: "Mystery",
      THRILLER: "Thriller",
      DRAMA: "Drama",
      SCI_FI: "Sci-Fi",
      COMEDY: "Comedy",
    },
  },
  SELF_HELP: {
    name: "Self Help",
    subCategories: {
      PRODUCTIVITY: "Productivity",
      MENTAL_HEALTH: "Mental Health",
      RELATIONSHIPS: "Relationships",
      CAREER: "Career",
      TIME_MANAGEMENT: "Time Management",
      COMMUNICATION_SKILLS: "Communication Skills",
    },
  },
  BUSINESS: {
    name: "Business",
    subCategories: {
      ENTREPRENEURSHIP: "Entrepreneurship",
      LEADERSHIP: "Leadership",
      MARKETING: "Marketing & Sales",
      FINANCE: "Finance & Investing",
      STARTUPS: "Startups",
      STRATEGY: "Business Strategy",
      MANAGEMENT: "Management",
    },
  },
  POEM: {
    name: "Poem",
    subCategories: {
      CLASSIC: "Classic",
      CONTEMPORARY: "Contemporary",
      HAIKU: "Haiku",
      SONNET: "Sonnet",
      FREE_VERSE: "Free Verse",
      NARRATIVE: "Narrative",
      LYRIC: "Lyric",
      EPIC: "Epic",
      LOVE: "Love",
    },
  },
  HEALTH: {
    name: "Health",
    subCategories: {
      NUTRITION: "Nutrition",
      EXERCISE: "Exercise",
      MENTAL_HEALTH: "Mental Health",
      MEDICAL_RESEARCH: "Medical Research",
      WEIGHT_MANAGEMENT: "Weight Management",
      SLEEP_HEALTH: "Sleep Health",
      HEALTHY_AGING: "Healthy Aging",
    },
  },

  POLITICS: {
    name: "Politics",
    subCategories: {
      GEOPOLITICS: "Geopolitics",
      INDIA: "India",
      JAPAN: "Japan",
      ASIA: "Asia",
      RUSSIA: "Russia",
      EUROPE: "Europe",
      NORTH_AMERICA: "North America",
      SOUTH_AMERICA: "South America",
      AFRICA: "Africa",
      MIDDLE_EAST: "Middle East",
      AUSTRALIA: "Australia",
    },
  },
  TRAVEL: {
    name: "Travel",
    subCategories: {
      INDIA: "India",
      NORTH_AMERICA: "North America",
      SOUTH_AMERICA: "South America",
      EUROPE: "Europe",
      RUSSIA: "Russia",
      CHINA: "China",
      ASIA: "Asia",
      JAPAN: "Japan",
      MIDDLE_EAST: "Middle East",
      AUSTRALIA: "Australia",
    },
  },
};

export default categoriesAndSubcategories;
