import { $applyNodeReplacement, createEditor, DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";
import { styled } from "styled-components";
import getImageURL from "../../../controllers/getImageURL";
import goTo from "../../../controllers/goTo";

const ImageComponent = styled.img`
  width: 100%;
  border-radius: 10px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Caption = styled.div`
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.5;
  text-decoration: underline;
  font-size: 18px;
`;

function convertImageElement(domNode) {
  if (domNode instanceof HTMLImageElement) {
    const { imageData } = domNode;
    console.log("convertImageElement imageData", imageData);
    const node = $createImageNode(imageData);
    return { node };
  }
  return null;
}

export class ImageNode extends DecoratorNode {
  static getType() {
    return "image";
  }

  static clone(node) {
    return new ImageNode(node.__imageData, node.__key);
  }

  static importJSON(serializedNode) {
    const { imageData } = serializedNode;
    console.log("serializedNode imageData", imageData, serializedNode);
    const node = $createImageNode(imageData);
    return node;
  }

  exportDOM() {
    const element = document.createElement("img");
    element.setAttribute("src", getImageURL(this.__imageData));

    return { element };
  }

  static importDOM() {
    return {
      img: (node) => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    };
  }

  constructor(imageData, key) {
    super(key);
    this.__imageData = imageData;
  }

  exportJSON() {
    return {
      imageData: this.__imageData,
      type: "image",
    };
  }

  createDOM(config) {
    const span = document.createElement("span");
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  updateDOM() {
    return false;
  }

  decorate() {
    let theData = this.__imageData;

    if (!theData) return; //Image pasted using ctrl + v

    return (
      <Suspense fallback={null}>
        <ImageContainer>
          <ImageComponent src={getImageURL(theData)} />
          {theData.metadata ? (
            <Caption onClick={goTo(theData.metadata.photographerLink)}>
              By {theData.metadata.photographer}
            </Caption>
          ) : null}
        </ImageContainer>
      </Suspense>
    );
  }
}

export function $createImageNode(imageData) {
  console.log("rea", imageData);
  return $applyNodeReplacement(new ImageNode(imageData));
}

export function $isImageNode(node) {
  return node instanceof ImageNode;
}
