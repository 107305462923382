import { useContext } from "react";
import styled from "styled-components";
import { FiSearch } from "react-icons/fi/index.js";
import { AiOutlineUser } from "react-icons/ai/index.js";
import { HiMenu } from "react-icons/hi/index.js";
// import goToAuthScreen from "../controllers/goToAuthScreen.js";
import { GrGoogle } from "react-icons/gr/index.js";
import {
  MdOutlineArticle,
  MdOutlineBookmarkBorder,
  MdOutlineCreate,
} from "react-icons/md/index.js";
import {
  RiBookmark2Line,
  RiBookmark3Line,
  RiHome2Line,
  RiNotificationLine,
} from "react-icons/ri/index.js";
import goToProfile from "../../controllers/goToProfile.js";
import Context from "../../Context.js";
import goToAuthScreen from "../../controllers/goToAuthScreen.js";
import goTo from "../../controllers/goTo.js";
import CustomButton from "../helperComponents/CustomButton.js";
import SemanticButton from "../helperComponents/SemanticButton.js";
import getProfileLink from "../../controllers/getProfileLink.js";
import { GoBookmark } from "react-icons/go";
import checkIsMobile from "../../controllers/utils/checkIsMobile.js";

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  @media (max-width: 900px) {
    font-size: 5px;
  }
`;

const LogoName = styled.div`
  font-weight: 800;
  font-size: 25px;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const TheLogo = styled.div`
  @media (max-width: 900px) {
  }
`;

const LogoImg = styled.img`
  height: 40px;
  width: auto;

  @media (max-width: 900px) {
    height: 25px;
  }
`;

export default function HeaderLogo() {
  const { loggedInUser } = useContext(Context);

  return (
    <TheLogo loggedInUser={loggedInUser}>
      <CustomButton
        style={{ padding: 0 }}
        onClick={goTo("/")}
        variant="minimal"
      >
        <Logo>
          <LogoImg src={"/logo2.png"} />
          <LogoName>Shortbooks</LogoName>
        </Logo>
      </CustomButton>
    </TheLogo>
  );
}
