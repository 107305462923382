import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createLineBreakNode,
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $insertNodes,
  $isRangeSelection,
  $isRootOrShadowRoot,
  FORMAT_TEXT_COMMAND,
  LineBreakNode,
} from "lexical";
import { LuHeading1, LuHeading2, LuImage } from "react-icons/lu";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";
import { BsTextParagraph } from "react-icons/bs";
import { BiHeading } from "react-icons/bi";
import styled from "styled-components";
import CustomButton from "../../helperComponents/CustomButton";
import {
  AiOutlineBold,
  AiOutlineCode,
  AiOutlineDown,
  AiOutlineHighlight,
  AiOutlineItalic,
  AiOutlinePlus,
  AiOutlineStrikethrough,
  AiOutlineUnderline,
} from "react-icons/ai";
import { TbCode } from "react-icons/tb";
import { $getNearestNodeOfType } from "@lexical/utils";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isParentElementRTL,
  $wrapLeafNodesInElements,
  $isAtNodeEnd,
  $setBlocksType,
} from "@lexical/selection";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from "@lexical/list";
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
} from "@lexical/rich-text";
import {
  $createCodeNode,
  $isCodeNode,
  getDefaultCodeLanguage,
  getCodeLanguages,
} from "@lexical/code";
import { DEPRECATED_$isGridSelection } from "lexical";
import capitalizeFirstLetter from "../../../controllers/capitalizeFirstLetter";
import Context from "../../../Context";
import {
  PiListBullets,
  PiListBulletsLight,
  PiListNumbers,
} from "react-icons/pi";
import { MdFormatListBulleted, MdFormatListNumbered } from "react-icons/md";
import PopupImageUploader from "../../editors/PopupImageUploader";
import { $createImageNode } from "../nodes/ImageNode";

const IconButton = styled.div``;

const Container = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: row;
  /* background-color: var(--color); */
  /* margin-top: -20px; */
  border-radius: 10px;

  @media (max-width: 900px) {
    bottom: 0px !important;
    top: unset !important;
    height: var(--headerHeight);
    visibility: visible !important;
    opacity: 1 !important;
    align-items: center;
    left: 0 !important;
  }
`;

const Options = styled.div`
  position: absolute;
  left: 40px;
  display: flex;
  flex-direction: row;

  background: var(--glassGradientHard);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);

  backdrop-filter: blur(20px);
  overflow: hidden;
  border-radius: 10px;
  top: 0;
  transform: scale(1);
  transition: 0.15s ease-in-out;

  ${({ show }) => {
    if (!show) {
      return `
        transform: scale(0);
      `;
    }
  }}

  @media (max-width: 900px) {
    transform: scale(1);
    width: 100vw;
    left: 0;
    justify-content: space-between;
    padding: 25px;
    align-items: center;
    height: 100%;
    border-radius: 10px 10px 0 0;
  }
`;

const ThePlus = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`;

let FolatingAddButtonUI = forwardRef(FloatingToolbarComp);

let buttonVariant = "minimal";

export default FolatingAddButtonUI;

function FloatingToolbarComp(props, ref) {
  const { setForm } = useContext(Context);
  const { editor, coords } = props;
  const [show, setShow] = useState(false);

  const shouldShow = coords !== undefined;

  // console.log(coords);

  const [state, setState] = useState({
    isBold: false,
    isCode: false,
    isItalic: false,
    isStrikethrough: false,
    isUnderline: false,
  });

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      let newBlockType = "";
      let newElementKey = "";
      let newLanguageCode = "";

      if (elementDOM !== null) {
        newElementKey = elementKey;

        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          newBlockType = type;
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();

          newBlockType = type;

          if ($isCodeNode(element)) {
            newLanguageCode = element.getLanguage() || getDefaultCodeLanguage();
          }
        }
      }

      let isLink = false;

      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        isLink = true;
      }

      let newState = {
        isLink: isLink,
        isBold: selection.hasFormat("bold"),
        isCode: selection.hasFormat("code"),
        isItalic: selection.hasFormat("italic"),
        isStrikethrough: selection.hasFormat("strikethrough"),
        isUnderline: selection.hasFormat("underline"),
        blockType: newBlockType,
        elementKey: newElementKey,
        languageCode: newLanguageCode,
      };

      setState(newState);
    }
  }, [editor]);

  useEffect(() => {
    const unregisterListener = editor.registerUpdateListener(
      ({ editorState }) => {
        editorState.read(() => {
          updateToolbar();

          // const selection = $getSelection();
          // if (!$isRangeSelection(selection)) return;

          // setState({
          //   isBold: selection.hasFormat("bold"),
          //   isCode: selection.hasFormat("code"),
          //   isItalic: selection.hasFormat("italic"),
          //   isStrikethrough: selection.hasFormat("strikethrough"),
          //   isUnderline: selection.hasFormat("underline"),
          // });
        });
      }
    );
    return unregisterListener;
  }, [editor]);

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatBulletList = () => {
    if (state.blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }

    setShow(false);
  };

  const formatNumberedList = () => {
    if (state.blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }

    setShow(false);
  };

  const formatLargeHeading = (theType) => {
    return () => {
      if (state.blockType !== theType) {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode(theType));
          }
        });
      }

      setShow(false);
    };
  };

  const formatCode = () => {
    if (state.blockType !== "code") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createCodeNode());
        }
      });

      setShow(false);
    }
  };

  const formatImage = (imageData) => {
    editor.update(() => {
      const imageNode = $createImageNode(imageData);
      $insertNodes([imageNode]);
      if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
        $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
      }

      $insertNodes([$createParagraphNode()]); //add additional line after the image
    });

    setShow(false);
  };

  // console.log(state);

  let btnSize = "35px";

  let addBtnStyle = { borderRadius: "100px" };

  if (show) {
    addBtnStyle = { ...addBtnStyle, transform: "rotate(45deg)" };
  }

  return (
    <Container
      ref={ref}
      className="flex items-center justify-between bg-slate-100 border-[1px] border-slate-300 rounded-md p-1 gap-1"
      aria-hidden={!shouldShow}
      style={{
        zIndex: 10,
        position: "fixed",
        top: coords?.y,
        left: coords?.x,
        visibility: shouldShow ? "visible" : "hidden",
        opacity: shouldShow ? 1 : 0,
      }}
    >
      <ThePlus>
        <CustomButton
          style={addBtnStyle}
          // variant={"variant"}
          onClick={(e) => {
            e.preventDefault();
            setShow(!show);
            editor.focus();
            return false;
          }}
          size={btnSize}
          icon={<AiOutlinePlus />}
        />
      </ThePlus>

      <Options show={show}>
        <CustomButton
          variant={buttonVariant}
          onClick={formatLargeHeading("h1")}
          size={btnSize}
          icon={<LuHeading1 />}
        />
        <CustomButton
          variant={buttonVariant}
          onClick={formatLargeHeading("h2")}
          size={btnSize}
          icon={<LuHeading2 />}
        />
        <CustomButton
          variant={buttonVariant}
          onClick={formatCode}
          size={btnSize}
          icon={<TbCode />}
        />

        <CustomButton
          variant={buttonVariant}
          onClick={formatBulletList}
          size={btnSize}
          icon={<MdFormatListBulleted />}
        />

        <CustomButton
          variant={buttonVariant}
          onClick={formatNumberedList}
          size={btnSize}
          icon={<MdFormatListNumbered />}
        />

        <CustomButton
          variant={buttonVariant}
          onClick={openImageUploader}
          size={btnSize}
          icon={<LuImage />}
        />
      </Options>
    </Container>
  );

  function openImageUploader() {
    setShow(false);
    setForm({ component: <PopupImageUploader callback={addImage} /> });
  }

  function addImage(theData) {
    setForm(null);
    formatImage(theData);
    console.log(theData);
  }
}

function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}
