let reportReasons = {
  SEXUAL: "Sexual Content",
  VIOLENT_OR_REPULSIVE: "Violent or repulsive content",
  HATEFUL_OR_ABUSIVE: "Hateful or abusive content",
  HARASSMENT_OR_BULLYING: "Harassment or bullying",
  HARMFUL_OR_DANGEROUS: "Harmful or dangerous acts",
  MISINFORMATION: "Misinformation",
  CHILD_ABUSE: "Child abuse",
  PROMOTES_TERRORISM: "Promotes terrorism",
  SPAM_OR_MISLEADING: "Spam or misleading",
  LEGAL_ISSUE: "Legal issue",
  CAPTIONS_ISSUE: "Captions issue",
};

export default reportReasons;
