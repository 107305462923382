import { useNavigate, useNavigation } from "react-router-dom";
import Link from "./Link.js";
import styled from "styled-components";
import { MoonLoader } from "react-spinners";
import goTo from "../../controllers/goTo.js";
import { Tooltip } from "@mui/material";
import { useState } from "react";

const Container = styled.a`
  padding: 12px 20px;
  background: var(--color);
  color: var(--bgColor);
  border-radius: 10px;
  text-decoration: none;
  border: none;
  outline: none;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 13px;
  transition: 0.1s ease-in all;
  cursor: pointer;

  @media (max-width: 900px) {
    padding: 10px 20px;
    font-size: 15px;
  }

  ${({ variant }) => {
    if (variant === "standard")
      return `
    

      &:hover {
        transform: scale(0.95);
      }
  `;

    if (variant === "primary")
      return `

      &:hover {
        color: var(--bgColor);
        background: var(--accentColor);
      }
  `;

    if (variant === "filled")
      return `
    
      background:var(--glassGradient);
      border:1px solid var(--glassBorder);

      color: var(--color);
      transition: 0s ease-in;
      box-shadow: var(--lightShadow);

      &:hover {
        background: var(--primaryColor);
      }
  `;

    if (variant === "outlined")
      return `
    
      background:transparent;
      border: 1px solid var(--translucentHard);
      color: var(--color);
      transition: 0s ease-in;

      &:hover {
        transform:unset;
        background: var(--translucent);
      }
    `;

    if (variant === "boxed")
      return `
    
      background:transparent;
      border: 1px solid var(--translucentHard);
      color: var(--color);
      transition: 0s ease-in;
      border-radius: 10px;
      flex-direction: column;

      &:hover {
        transform:unset;
        background: var(--translucent);
      }
    `;

    if (variant === "hidden-border")
      return `
    
      background:transparent;
      border: none;
      color: var(--color);
      transition: 0s ease-in;

      &:hover {
        transform:unset;
        background: var(--translucent);
      }
    `;

    if (variant === "minimal")
      return `
    
      background:transparent;
      color: var(--color);
      transition: 0.2s ease-in-out;
      padding:0;
      border:none;

      &:hover {
        transform:scale(0.9);
        background: unset;
        text-decoration:none;

      }



    `;

    if (variant === "minimal-underlined")
      return `
    
      background:transparent;
      color: var(--color);
      transition: 0s ease-in;
      padding:0;
      border:none;
      text-decoration:underline;
      opacity:0.7;

      &:hover {
        transform:unset;
        background: unset;
      }



    `;

    if (variant === "virtual-hover")
      return `
    
      background:transparent;
      color: var(--color);
      transition: 0s ease-in;
      padding:0;
      border:none;
      position:relative;

      &:hover:after {
        content: "";
        height: 150%;
        position: absolute;
        top: -25%;
        border-radius: 20px;
        /* padding: 15px; */
        left: -25%;
        width: 150%;
        background: var(--translucent);
      }



    `;
  }}

  ${({ fontSize }) => {
    if (fontSize)
      return `
      font-size:${fontSize};
  `;
  }}

${({ size }) => {
    if (size)
      return `
      padding:0;
      height:${size};
      width:${size};
  `;
  }}

${({ width }) => {
    if (width)
      return `
      width:${width};
  `;
  }}

${({ height }) => {
    if (height)
      return `
      padding-top:0;
      padding-bottom:0;
      height:${height};
  `;
  }}

${({ underlined }) => {
    if (underlined)
      return `
      text-decoration:underline;
  `;
  }}
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TextContainer = styled.div`
  font-weight: 500;
  white-space: nowrap;
  font-family: "Roboto";
  /* text-transform: capitalize; */
`;

export default function CustomButton({
  children,
  icon,
  onClick,
  href,
  style,
  variant = "outlined",
  isLoading,
  fontSize,
  size,
  width,
  height,
  underlined,
  isActive,
  onActiveStyle,
  disabled,
  tooltipTitle,
}) {
  variant = variant.toLowerCase();

  if (href && !onClick) {
    onClick = () => {
      goTo(href)();
    };
  }

  if (!style) style = {};

  if (disabled) {
    style = { ...style, opacity: 0.5, pointerEvents: "none" };
  }

  if (isActive && onActiveStyle) {
    style = { ...style, ...onActiveStyle };
  }

  if (isLoading) icon = <MoonLoader size="20px" color="var(--color)" />;

  let theComp = (
    <Container
      href={href}
      height={height}
      width={width}
      variant={variant}
      style={style}
      size={size}
      onClick={theClick}
      fontSize={fontSize}
      underlined={underlined}
    >
      {icon ? <IconContainer>{icon}</IconContainer> : null}
      {children ? <TextContainer>{children}</TextContainer> : null}
    </Container>
  );

  if (tooltipTitle) {
    return <Tooltip title={tooltipTitle}>{theComp}</Tooltip>;
  } else {
    return theComp;
  }

  function theClick(e) {
    e.preventDefault();
    onClick(e);
  }
}
