import styled from "styled-components";
import getImageURL from "../../controllers/getImageURL";
import goTo from "../../controllers/goTo";
import { useContext } from "react";
import Context from "../../Context";

import getContentLink from "../../controllers/getContentLink";
import SemanticButton from "../helperComponents/SemanticButton";

const HoverEffect = styled.div`
  transition: 0.15s ease-in;

  &:hover {
    transform: scale(0.98);
  }
`;

export default function ContentCardBoilerplate({
  item,
  variant,
  children,
  isService,
}) {
  const { setPopupFeedItem } = useContext(Context);

  return (
    <SemanticButton href={getContentLink(item._id)} onClick={openPost}>
      <HoverEffect>{children}</HoverEffect>
    </SemanticButton>
  );

  function openPost() {
    setPopupFeedItem(item);
    goTo("/content/" + item._id)();
  }
}
