import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";

import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import { serverLine } from "../../controllers/serverLine";
import openLoginPopup from "../../controllers/openLoginPopup";
import Context from "../../Context";
import CustomButton from "../helperComponents/CustomButton";

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export default function LikeDislikeButtons({
  likeStatus,
  likeCount = 0,
  dislikeCount = 0,
  contentID,
  commentID,
  replyLocation,
  variant = "filled",
  style,
  isMinimal,
}) {
  const { loggedInUserID } = useContext(Context);

  const [status, setStatus] = useState(likeStatus);
  const [likeCountState, setLikeCountState] = useState(0);
  const [dislikeCountState, setDislikeCountState] = useState(0);

  useEffect(() => {
    if (typeof likeCount == "number") {
      setLikeCountState(likeCount);
    }

    if (typeof dislikeCount == "number") {
      setDislikeCountState(dislikeCount);
    }
  }, [contentID]);

  if (replyLocation) commentID = null;
  let details = { contentID, commentID, replyLocation };

  if (!style) style = { gap: "10px" };

  let dislikeButton = (
    <CustomButton
      style={style}
      variant={variant}
      onClick={dislike}
      icon={status === "DISLIKE" ? <AiFillDislike /> : <AiOutlineDislike />}
    >
      {dislikeCountState.toString()}
    </CustomButton>
  );

  if (isMinimal) {
    dislikeButton = null;

    if (window.innerWidth < 900) {
      style = {
        fontSize: "15px",
        height: "35px",
        padding: "10px 15px",
        gap: "10px",
      };
    }
  }

  return (
    <Buttons>
      <CustomButton
        style={style}
        variant={variant}
        onClick={like}
        icon={status === "LIKE" ? <AiFillLike /> : <AiOutlineLike />}
      >
        {likeCountState.toString()}
      </CustomButton>

      {dislikeButton}
    </Buttons>
  );

  function updateStatus(newStatus) {
    setStatus(newStatus);
  }

  function like() {
    if (!loggedInUserID) return openLoginPopup();

    serverLine.post("/like-dislike", { ...details, type: "LIKE" });

    let toIncrementLikeCount = 0;
    let toIncrementDislikeCount = 0;
    let newStatus = null;

    if (status === "DISLIKE") {
      toIncrementDislikeCount--;
      toIncrementLikeCount++;
      newStatus = "LIKE";
    } else if (status === "LIKE") {
      toIncrementLikeCount--;

      newStatus = "NEUTRAL";
    } else if (status === "NEUTRAL") {
      toIncrementLikeCount++;

      newStatus = "LIKE";
    }

    let newLikeCount = likeCountState + toIncrementLikeCount;
    let newDislikeCount = dislikeCountState + toIncrementDislikeCount;
    updateStatus(newStatus);
    setLikeCountState(newLikeCount);
    setDislikeCountState(newDislikeCount);
  }

  function dislike() {
    if (!loggedInUserID) return openLoginPopup();
    serverLine.post("/like-dislike", { ...details, type: "DISLIKE" });

    let toIncrementLikeCount = 0;
    let toIncrementDislikeCount = 0;
    let newStatus = null;

    if (status === "DISLIKE") {
      toIncrementDislikeCount--;
      newStatus = "NEUTRAL";
    } else if (status === "LIKE") {
      toIncrementLikeCount--;
      toIncrementDislikeCount++;
      newStatus = "DISLIKE";
    } else if (status === "NEUTRAL") {
      toIncrementDislikeCount++;
      newStatus = "DISLIKE";
    }

    let newLikeCount = likeCountState + toIncrementLikeCount;
    let newDislikeCount = dislikeCountState + toIncrementDislikeCount;

    updateStatus(newStatus);
    setLikeCountState(newLikeCount);
    setDislikeCountState(newDislikeCount);
  }
}
