import { useState } from "react";
import styled from "styled-components";
import CustomMasonry from "../helperComponents/CustomMasonry";
import MaterialInput from "../helperComponents/MaterialInput";
import LoadingSection from "../helperComponents/LoadingSection";
import { serverLine } from "../../controllers/serverLine";

const Container = styled.div``;

const Image = styled.img`
  width: 100%;
  height: auto;

  object-fit: cover;
  cursor: pointer;
  position: relative;
  background-color: var(--translucent);
  transition: 0.15s ease-out;
  cursor: pointer;

  &:hover {
    transform: scale(0.95);
  }
`;

const Info = styled.div`
  width: 100%;
  text-align: center;
  height: 100%;
  opacity: 0.5;
  margin: 25px 0;
`;

const InputContainer = styled.div`
  position: sticky;
  top: 0;
  padding: 20px;
  z-index: 100;
  /* background: var(--bgColor); */
`;

function SearchUnsplash({ callback }) {
  let [images, setImages] = useState(null);
  let [query, setQuery] = useState("");
  let [loading, setLoading] = useState(false);

  if (loading)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  let content = null;

  if (!query) {
    content = null;
  }

  if (images)
    if (!images.length) {
      content = <Info>0 Results, try putting it differently</Info>;
    } else {
      let imageRender = images.map((item) => {
        let { regular, small } = item.urls;
        let { links, name } = item.user;

        let imageData = {
          url: regular,
          source: "unsplash",
          metadata: {
            photographer: name,
            photographerLink: links.html,
          },
          raw: item,
        };

        return (
          <Image
            key={small}
            onClick={() => {
              setImage(imageData);
            }}
            src={small}
          />
        );
      });

      content = <CustomMasonry gap={"5px"} maxCol={3} list={imageRender} />;
    }

  return (
    <Container>
      <InputContainer>
        <MaterialInput
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          autofocus={true}
          value={query}
          label={"Search Images"}
          onEnter={makeQuery}
        />
      </InputContainer>
      {content}
    </Container>
  );

  function queryChanged(e) {
    let val = e.target.value;
    setQuery(val);
    if (val == query) return;
    makeQuery(val);
  }

  async function makeQuery(e) {
    setLoading(true);
    let data = await serverLine.get(
      `/search-unsplash/?query=${e.target.value}`
    );
    setImages(data.results);
    setLoading(false);
  }

  function setImage(imageData) {
    callback(imageData);
  }
}

export default SearchUnsplash;
