import { styled } from "styled-components";
import MaterialInput from "../helperComponents/MaterialInput";
import getImageURL from "../../controllers/getImageURL";
import { useEffect, useState } from "react";
import ChapterSearchRes from "./book/ChapterSearchRes";
import SelectedChapter from "./book/SelectedChapter";
import { serverLine } from "../../controllers/serverLine";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LoadingSection from "../helperComponents/LoadingSection";
import BookCoverEditor from "./BookCoverEditor";

const Container = styled.div`
  display: flex;
  /* background: var(--translucent); */
  /* padding: 25px; */
  border-radius: 15px;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  align-items: center;

  flex: 1;
  font-size: 21px;
`;

const Inputs = styled.div`
  display: flex;

  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Title = styled.input`
  padding: 15px 0;
  padding-top: 0;
  border: none;
  width: 100%;
  text-align: left;
  /* border-bottom: 2px solid var(--translucentHard); */
  font-size: 21px;
  font-weight: 800;
  background: transparent;
  outline: none;
  color: var(--color);
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
`;
const SectionTitle = styled.div`
  font-weight: 900;
  font-size: 21px;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Message = styled.div`
  opacity: 0.5;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 50px;
`;

export default function BookEditor({ value, onChange, bookID }) {
  const [allPosts, setAllPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const idVsPost = getIdVsPost();

  useEffect(() => {
    serverLine.get("/all-chapters").then((newList) => {
      setAllPosts(newList);
      setLoading(false);
    });
  }, []);

  value = { ...value };

  if (!value)
    value = {
      title: "Untitled",
      content: { description: "", chapters: [] },
    };

  if (!value.content) value.content = { description: "", chapters: [] };

  if (loading) return <LoadingSection />;

  return (
    <Container>
      <Top>
        <BookCoverEditor value={value} onChange={onChange} />
        <Inputs>
          <MaterialInput
            disableUnderline={true}
            placeholder="Title"
            label={"Title"}
            multiline
            value={value.title}
            onChange={onTitleChange}
          />
          <MaterialInput
            disableUnderline={true}
            placeholder="Description"
            label={"Description"}
            multiline
            rows={10}
            value={value.content.description}
            onChange={onDescriptionChange}
          />

          <Section>
            <SectionTitle>Selected Chapters</SectionTitle>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <List {...provided.droppableProps} ref={provided.innerRef}>
                    {renderSelectedChapters()}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </Section>

          <Section>
            <SectionTitle>Add Chapters</SectionTitle>
            <SectionTitle>
              <MaterialInput
                label="Search Chapters Here"
                value={searchQuery}
                onChange={updateSearchQuery}
              />
            </SectionTitle>

            <List>{showSearchResult()}</List>
          </Section>
        </Inputs>
      </Top>
    </Container>
  );

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    if (!value.content) {
      return null;
    }

    if (!value.content.chapters) {
      return null;
    }

    let newValue = { ...value };

    const items = Array.from(value.content.chapters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    newValue.content.chapters = items;
    onChange(newValue);
  }

  function renderSelectedChapters() {
    if (!value.content) return <Message>No Chapter Selected</Message>;
    if (!value.content.chapters) return <Message>No Chapter Selected</Message>;
    if (!value.content.chapters.length)
      return <Message>No Chapter Selected</Message>;

    let theList = value.content.chapters;

    let toRender = theList.map((item, index) => (
      <Draggable
        key={item.contentID}
        draggableId={item.contentID}
        index={index}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <SelectedChapter
              item={idVsPost[item.contentID]}
              onRemove={removeChapter}
            />
          </div>
        )}
      </Draggable>
    ));

    return toRender;
  }

  function withoutAlreadySelected() {
    let theList = [];

    if (!value.content) {
      return allPosts;
    }

    if (!value.content.chapters) {
      return allPosts;
    }

    let existingcontentIDs = value.content.chapters.map(
      (item) => item.contentID
    );

    for (let item of allPosts) {
      if (!existingcontentIDs.includes(item._id)) {
        if (!item.bookID) theList.push(item);
        // theList.push(item);
      }
    }

    return theList;
  }

  function showSearchResult() {
    let theList = allPosts;
    theList = withoutAlreadySelected();
    let newList = theList;

    if (searchQuery) {
      let theSearchQuery = searchQuery.toLowerCase();
      newList = [];

      for (let item of theList) {
        if (item.searchableText) {
          let searchableText = item.searchableText.toLowerCase();
          if (searchableText.indexOf(theSearchQuery) !== -1) {
            newList.push(item);
          }
        }
      }
    }

    let toRender = newList.map((item) => (
      <ChapterSearchRes item={item} onSelect={selectChapter} />
    ));

    return toRender;
  }

  function removeChapter({ _id }) {
    let newValue = { ...value };

    let newList = [];
    for (let item of newValue.content.chapters) {
      if (item.contentID !== _id) {
        newList.push(item);
      }
    }

    detachBookID(_id);
    newValue.content.chapters = newList;
    onChange(newValue);
  }

  function selectChapter(theItem) {
    if (theItem.bookID) {
      return window.doAlert(
        "This post is already assigned to a book. Remove it from that book to continue"
      );
    }
    let itemID = theItem._id;
    let newValue = { ...value };
    if (!newValue.content) {
      newValue.content = { chapters: [] };
    }

    attachBookID(itemID);
    newValue.content.chapters.push({ contentID: itemID });
    onChange(newValue);
  }

  function attachBookID(contentID) {
    serverLine.post("/attach-to-book", {
      action: "ATTACH",
      bookID: bookID,
      contentID,
    });
  }

  function detachBookID(contentID) {
    let newDoc = { ...idVsPost };
    newDoc[contentID].bookID = null;

    let newAllPosts = [];
    for (let id in newDoc) {
      newAllPosts.push(newDoc[id]);
    }

    setAllPosts(newAllPosts);

    serverLine.post("/attach-to-book", { action: "DETACH", contentID });
  }

  function updateSearchQuery(e) {
    setSearchQuery(e.target.value);
  }

  function onTitleChange({ target }) {
    let newValue = { ...value };
    newValue.title = target.value;
    onChange(newValue);
  }

  function onDescriptionChange({ target }) {
    let newValue = { ...value };
    newValue.content.description = target.value;
    onChange(newValue);
  }

  function getIdVsPost() {
    let theObj = {};

    for (let item of allPosts) {
      theObj[item._id] = item;
    }

    return theObj;
  }
}
