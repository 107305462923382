import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { computePosition } from "@floating-ui/dom";

import FloatingToolbarUI from "../ui/FloatingToolbarUI";
import { usePointerInteractions } from "../hooks/usePointerInteractions";
import { $getSelection, $isRangeSelection } from "lexical";
import FloatingAddButtonUI from "../ui/FloatingAddButtonUI";
import CustomButton from "../../helperComponents/CustomButton";

const DOM_ELEMENT = document.body;

export function FloatingAddButton({ addButtonPosX }) {
  const ref = useRef(null);
  const [coords, setCoords] = useState(undefined);
  const [editor] = useLexicalComposerContext();

  const { isPointerDown, isPointerReleased } = usePointerInteractions();

  const calculatePosition = useCallback(() => {
    const domSelection = getSelection();
    const domRange = domSelection?.getRangeAt(0);

    if (!ref.current) return setCoords(undefined);

    let caretPos = getCaretCoordinates();

    if (caretPos) {
      let x = 0;

      if (addButtonPosX) {
        x = addButtonPosX;
      } else {
        let actualWidth = window.innerWidth;
        let spaceTaken = actualWidth * 0.62;
        x = (actualWidth - spaceTaken) / 2;
        x = x - 50;
      }

      setCoords({ x: x, y: caretPos.y });
    } else {
      setCoords(null);
    }
    // console.log(domRange, "TOP:", pos);
  }, [isPointerDown]);

  const $handleSelectionChange = useCallback(() => {
    if (
      editor.isComposing() ||
      editor.getRootElement() !== document.activeElement
    ) {
      setCoords(undefined);
      return;
    }

    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      calculatePosition();
    } else {
      setCoords(undefined);
    }
  }, [editor, calculatePosition]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setCoords(undefined);
    });

    const unregisterListener = editor.registerUpdateListener(
      ({ editorState }) => {
        editorState.read(() => $handleSelectionChange());
      }
    );
    return unregisterListener;
  }, [editor, $handleSelectionChange]);

  const show = coords !== undefined;

  useEffect(() => {
    editor.getEditorState().read(() => $handleSelectionChange());

    // Adding show to the dependency array causes an issue if
    // a range selection is dismissed by navigating via arrow keys.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPointerReleased, $handleSelectionChange, editor]);

  // return <CustomButton>Hell</CustomButton>;

  return createPortal(
    <FloatingAddButtonUI ref={ref} editor={editor} coords={coords} />,
    DOM_ELEMENT
  );
}

function getCaretCoordinates() {
  let x = 0,
    y = 0;
  const isSupported = typeof window.getSelection !== "undefined";
  if (isSupported) {
    const selection = window.getSelection();

    const range = selection.getRangeAt(0).cloneRange();

    let clientRects = range.getClientRects();

    if (clientRects.length) {
      let rect = clientRects[0];
      return { x: rect.left, y: rect.top };
    }

    if (range.endContainer) {
      if (range.endContainer.getBoundingClientRect) {
        const rect = range.endContainer.getBoundingClientRect();

        if (rect) {
          x = rect.left;
          y = rect.top - 5;

          return { x, y };
        }
      } else {
        console.log(
          "getBoundingClientRect does not exists in",
          range.endContainer
        );
      }
    }
  }
  return null;
}
