import { MdAdd, MdCall, MdMessage, MdMinimize } from "react-icons/md/index.js";

import CustomButton from "./CustomButton.js";
import { useContext } from "react";
import Context from "../../Context.js";
import { serverLine } from "../../controllers/serverLine.js";
import { AiOutlineMinus } from "react-icons/ai";
import openLoginPopup from "../../controllers/openLoginPopup.js";

export default function FollowButton({ userID, style }) {
  const { loggedInUser, setLoggedInUser, loggedInUserID } = useContext(Context);

  const hasFollowed = getFollowStatus();

  if (loggedInUserID == userID) return null;

  return (
    <CustomButton
      style={style}
      onClick={followButton}
      icon={hasFollowed ? null : <MdAdd />}
      circular
      variant={"outlined"}
    >
      {hasFollowed ? "Following" : "Follow"}
    </CustomButton>
  );

  function getFollowStatus() {
    let theList = [];

    if (loggedInUser) {
      if (loggedInUser.followingUsers) {
        theList = [...loggedInUser.followingUsers];
      }
    }

    return theList.includes(userID);
  }

  function followButton() {
    if (!loggedInUserID) return openLoginPopup();

    serverLine.post("/follow", { receiverUserID: userID });

    let theList = [];

    if (loggedInUser) {
      if (loggedInUser.followingUsers) {
        theList = [...loggedInUser.followingUsers];
      }
    }

    if (theList.includes(userID)) {
      let index = theList.indexOf(userID);
      theList.splice(index, 1);
    } else {
      theList.push(userID);
    }

    let newLoggedInUser = { ...loggedInUser };
    newLoggedInUser.followingUsers = theList;

    setLoggedInUser(newLoggedInUser);
  }
}
