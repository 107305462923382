export default function GetArticleAndUserCardDimension() {
  let innerWidth = window.innerWidth;
  let width = null;
  let height = null;

  let isMobile = window.innerWidth < 900;
  let isTablet = window.innerWidth > 900 && window.innerWidth < 1300;

  if (isTablet) {
    width = 480;
    height = 100;
  } else if (isMobile) {
    width = "100%";
    height = 100;
  } else {
    width = 635;
    height = 100;
  }

  return { width: width + "px", height: height + "px" };
}
