import { HiOutlineDotsHorizontal, HiOutlineDotsVertical } from "react-icons/hi";
import CustomButton from "../helperComponents/CustomButton";
import { useContext } from "react";
import Context from "../../Context";
import { serverLine } from "../../controllers/serverLine";
import goTo from "../../controllers/goTo";

export default function CommentOptionsButton({ item }) {
  const { loggedInUserID, popupAlert, setForm } = useContext(Context);

  return (
    <CustomButton
      variant="minimal"
      onClick={openMenu}
      icon={<HiOutlineDotsHorizontal />}
    ></CustomButton>
  );

  function openMenu() {
    let options = [];

    if (loggedInUserID == item.senderUserID) {
      options.push({ name: "Delete", onClick: deleteComment });
    } else {
      options.push({
        name: "Report",
        onClick: goTo(
          "/create-report/?subjectType=COMMENT&subjectID=" + item._id
        ),
      });
    }

    setForm({ options: options });
  }

  async function deleteComment() {
    setForm(null);
    popupAlert("Comment Deleted");
    await serverLine.post("/delete", {
      subjectType: "COMMENT",
      subjectID: item._id,
      action: "ARCHIVE",
    });
  }
}
