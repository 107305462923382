import { styled } from "styled-components";
import CustomButton from "../helperComponents/CustomButton";
import { useContext, useState } from "react";
import Context from "../../Context";
import selectFile from "../../controllers/selectFile";
import compressAndUploadFile from "../../controllers/compressAndUploadFile";
import openPaymentPopup from "../../controllers/payment/openPaymentPopup";
import LoadingSection from "../helperComponents/LoadingSection";
import SearchUnsplash from "../misc/SearchUnsplash";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const Options = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: row;
  gap: 50px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;
export default function PopupImageUploader({ callback }) {
  const { setForm, loggedInUser } = useContext(Context);
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);

  if (!loggedInUser) return <LoadingSection />;

  if (!type)
    return (
      <Options>
        <CustomButton
          variant="outlined"
          onClick={() => {
            setType("UNSPLASH");
          }}
        >
          Search Unsplash
        </CustomButton>
        <CustomButton variant="outlined" onClick={doManualUpload}>
          Upload Image
        </CustomButton>
      </Options>
    );

  if (type == "UNSPLASH") {
    if (loading) return <LoadingSection />;
    return (
      <Container>
        <SearchUnsplash
          callback={(imageData) => {
            callback({
              type: "UNSPLASH",
              data: imageData.url,
              metadata: imageData.metadata,
            });
          }}
        />
      </Container>
    );
  }

  if (type == "MANUAL") {
    if (loading) return <LoadingSection />;
    return (
      <Container>
        You have not selected an Image. Click/Tap anywhere to go back.
      </Container>
    );
  }

  async function doManualUpload() {
    try {
      setType("MANUAL");
      let image = await selectFile({ onlyImage: true });

      if (image) {
        if (image.length) {
          setLoading(true);
          let fileData = await compressAndUploadFile(null, image[0]);
          callback({ data: fileData.fileName, type: "S3_UPLOAD" });
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(false);
      window.popupAlert(e.message);
    }
  }
}
