import styled from "styled-components";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { serverLine } from "../../controllers/serverLine";
import Context from "../../Context";
import NotificationBox from "./NotificationBox";
import InfiniteScroll from "../helperComponents/InfiniteScroll";
import goTo from "../../controllers/goTo";
import LoadingSection from "../helperComponents/LoadingSection";
import goToProfile from "../../controllers/goToProfile";
import SemanticButton from "../helperComponents/SemanticButton";
import getProfileLink from "../../controllers/getProfileLink";
import reportReasons from "../../data/reportReasons";

const Notification = styled.div`
  padding: 25px 25px;
  /* cursor: pointer; */
  width: 100%;
  border-radius: 15px;

  /* text-transform: capitalize; */
  background: var(--glassGradient);
  color: var(--color);
  display: flex;
  flex-direction: column;
  gap: 25px;

  ${({ seen }) => {
    if (!seen)
      return `
      background:var(--primaryColor);
      `;
  }}
`;

const Button = styled.button`
  /* flex: 1; */
  position: relative;
  background: var(--glassGradient);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);
  font-size: 15px;
  padding: 10px 25px;
  color: var(--color);
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.05s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  opacity: 0.5;
`;

export default function NotificationsCore({ scrollData }) {
  const { loggedInUserID, loggedInUser, updateNotificationData } =
    useContext(Context);
  const [res, setRes] = useState([]);
  const [notificationsSeenAt, setNotificationsSeenAt] = useState(null);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [reachedTheEnd, setReachedTheEnd] = useState(false);

  let api = "/notifications";

  useEffect(() => {
    setOffset(0);
    setNotificationsSeenAt(null);
    setRes([]);
    setLoading(true);
  }, []);

  useEffect(() => {
    if (loggedInUserID) {
      doLoading(true).then(updateNotificationData);
    }
  }, [loggedInUserID]);

  async function doLoading(firstLoad, theOffset = offset) {
    if (firstLoad) theOffset = 0;
    setLoading(true);
    let newRes = await serverLine.get(`${api}/?offset=${theOffset}`);

    if (firstLoad) {
      setNotificationsSeenAt(newRes.notificationsSeenAt);
    }
    if (!newRes.notifs.length) {
      setReachedTheEnd(true);
    }

    if (firstLoad) {
      setRes([...newRes.notifs]);
    } else {
      setRes([...res, ...newRes.notifs]);
    }

    setLoading(false);

    setOffset(theOffset + 1);
    return true;
  }

  if (!loggedInUserID) return "Login to continue";

  if (!loggedInUser) return <LoadingSection />;

  let result = renderResult();

  if (!result.length && !loading)
    return <Message>No notification to show</Message>;

  return (
    <InfiniteScroll
      reachedTheEnd={reachedTheEnd}
      loading={loading}
      load={doLoading}
      scrollData={scrollData}
    >
      {result}
    </InfiniteScroll>
  );

  function renderResult() {
    if (!res) return [];
    if (!res.length) return [];
    return res.map((item) => {
      return getNotif({ item });
    });
  }

  function getNotif({ item }) {
    let seen = hasSeen(item.createdAt);

    // return JSON.stringify(item);

    let doesNotRequireSender = ["BAN", "UN_BAN", "REPORT"];

    if (!item.sender && !doesNotRequireSender.includes(item.type))
      return (
        <NotificationBox
          seen={seen}
          text={`The Person involved has deleted the account. ${item.type}`}
        ></NotificationBox>
      );

    let notificationText = null;
    let notificationImage = null;
    let notificationKey = item._id;
    let createdAt = item.createdAt;
    let notificationButtons = null;

    if (item.type == "FOLLOW") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <>
          <SemanticButton href={getProfileLink(item.sender.username)}>
            <Button seen={seen}>Visit Profile</Button>
          </SemanticButton>
        </>
      );
      notificationText = `${item.sender.username} started following you`;
    } else if (item.type === "ARTICLE_BOOKMARK") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <>
          <SemanticButton href={"/content/" + item.contentID}>
            <Button seen={seen}>Open Article</Button>
          </SemanticButton>
        </>
      );

      let contentTitle = "Untitled";

      if (item.contentData) {
        if (item.contentData.title) {
          contentTitle = item.contentData.title;
        }
      }

      notificationText = `Someone saved your article. ${contentTitle}`;
    } else if (item.type === "BOOK_BOOKMARK") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <>
          <SemanticButton href={"/content/" + item.contentID}>
            <Button seen={seen}>Open Book</Button>
          </SemanticButton>
        </>
      );

      let contentTitle = "Untitled";

      if (item.contentData) {
        if (item.contentData.title) {
          contentTitle = item.contentData.title;
        }
      }

      notificationText = `Someone saved your Book. ${contentTitle}`;
    } else if (item.type === "LIKE") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <>
          <SemanticButton href={getProfileLink(item.sender.username)}>
            <Button seen={seen}>Open Their Profile</Button>
          </SemanticButton>
          <SemanticButton href={"/content/" + item.contentID}>
            <Button seen={seen}>Open The Post</Button>
          </SemanticButton>
        </>
      );

      let contentTitle = getTitle(item);

      if (item.subjectType == "CONTENT") {
        notificationText = `${item.sender.username} has liked your post: ${contentTitle}`;
      } else if (item.subjectType == "COMMENT") {
        notificationText = `${item.sender.username} has liked your comment: ${contentTitle}`;
      }
    } else if (item.type === "COMMENT") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <>
          <SemanticButton href={getProfileLink(item.sender.username)}>
            <Button seen={seen}>Open Their Profile</Button>
          </SemanticButton>
          <SemanticButton href={"/content/" + item.contentID}>
            <Button seen={seen}>Open The Post</Button>
          </SemanticButton>
        </>
      );

      let contentTitle = "Untitled";

      if (item.contentData) {
        if (item.contentData.title) {
          contentTitle = item.contentData.title;
        }
      }

      let commentText = "Comment not found";

      if (item.content) {
        if (item.content.text) {
          commentText = item.content.text;
        }
      }

      notificationText = `${item.sender.username} has commented your post: ${contentTitle}. Saying: ${commentText}`;
    } else if (item.type === "REPLY") {
      notificationImage = item.sender.profileImage;
      notificationButtons = (
        <>
          <SemanticButton href={getProfileLink(item.sender.username)}>
            <Button seen={seen}>Open Their Profile</Button>
          </SemanticButton>
          <SemanticButton href={"/content/" + item.contentID}>
            <Button seen={seen}>Open The Post</Button>
          </SemanticButton>
        </>
      );

      let contentTitle = "Untitled";

      if (item.contentData) {
        if (item.contentData.title) {
          contentTitle = item.contentData.title;
        }
      }

      let commentText = "Comment not found";

      if (item.content) {
        if (item.content.text) {
          commentText = item.content.text;
        }
      }

      notificationText = `${item.sender.username} has replied to your comment on the post: ${contentTitle}. Saying: ${commentText}`;
    } else if (item.type === "REPORT") {
      notificationButtons = (
        <>
          <SemanticButton href={"/content/" + item.contentID}>
            <Button seen={seen}>Visit Content</Button>
          </SemanticButton>
        </>
      );

      let contentTitle = getTitle(item);

      if (item.subjectType == "CONTENT") {
        notificationText = `Your Content '${
          contentTitle
        }' got reported. Reason: ${item.data ? reportReasons[item.data.reason] : ""}`;
      } else if (item.subjectType == "COMMENT") {
        notificationText = `Your comment '${
          contentTitle
        }' got reported. Reason: ${item.data ? reportReasons[item.data.reason] : ""}`;
      }
    } else if (item.type === "UN_BAN") {
      notificationButtons = (
        <>
          <SemanticButton href={"/content/" + item.contentID}>
            <Button seen={seen}>Visit Content</Button>
          </SemanticButton>
        </>
      );

      let title = getTitle(item);

      notificationText = `Your Content "${title}" has been un-banned.`;
    } else if (item.type === "BAN") {
      notificationButtons = (
        <>
          <SemanticButton href={"/content/" + item.contentID}>
            <Button seen={seen}>Visit Content</Button>
          </SemanticButton>
        </>
      );

      let contentTitle = getTitle(item);

      if (item.subjectType == "CONTENT") {
        notificationText = `Your Content '${contentTitle}' has been banned.`;
      } else if (item.subjectType == "COMMENT") {
        notificationText = `Your Comment '${contentTitle}' has been banned.`;
      }
    } else {
      // notificationImage = item.sender.profileImage;
      notificationButtons = null;
      notificationText = `${item.sender ? item.sender.username : null} - ${
        item.type
      }`;
    }

    return (
      <NotificationBox
        key={notificationKey}
        createdAt={createdAt}
        seen={seen}
        text={notificationText}
        buttons={notificationButtons}
        image={notificationImage}
      />
    );

    function getTitle(item) {
      let contentTitle = "Untitled";
      if (item.subjectType == "CONTENT") {
        if (item.contentData) {
          if (item.contentData.title) {
            contentTitle = item.contentData.title;
          }
        }
      } else if (item.subjectType == "COMMENT") {
        if (item.content) {
          if (item.content.text) {
            contentTitle = item.content.text;
          }
        }
      }

      return contentTitle;
    }
  }

  function hasSeen(createdAt) {
    let createdAtEpochs = new Date(createdAt).valueOf();
    let notificationsSeenAtDate = new Date(
      notificationsSeenAt ? notificationsSeenAt : 0
    ).valueOf();
    // console.log(createdAtEpochs, notificationsSeenAt);

    return createdAtEpochs < notificationsSeenAtDate;
  }
}
