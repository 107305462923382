import { styled } from "styled-components";
import { useEffect, useState } from "react";
import LikeDislikeButtons from "./LikeDislikeButtons";
import ReplyBox from "./ReplyBox";
import getImageURL from "../../controllers/getImageURL";
import goToProfile from "../../controllers/goToProfile";
import { serverLine } from "../../controllers/serverLine";
import CustomButton from "../helperComponents/CustomButton";
import SemanticButton from "../helperComponents/SemanticButton";
import getProfileLink from "../../controllers/getProfileLink";
import goTo from "../../controllers/goTo";
import CommentOptionsButton from "./CommentOptionsButton";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const Profile = styled.img`
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50px;
`;
const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;
const CommentText = styled.div`
  font-size: 18px;
  opacity: 0.8;
`;
const AuthorName = styled.div`
  font-weight: 900;
  cursor: pointer;

  color: var(--color);
  &:hover {
    text-decoration: underline;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 25px;
`;

const Replies = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  margin-top: 25px;
`;

export default function RenderComment({ item, address }) {
  const [replies, setReplies] = useState([]);
  const [shouldShowReplies, setShouldShowReplies] = useState(
    address ? true : false
  );
  const [replyInputStatus, setReplyInputStatus] = useState(false);

  const [loadingReplies, setLoadingReplies] = useState(false);
  const [myReplies, setMyReplies] = useState([]);

  if (!address) address = [item._id];

  useEffect(() => {
    if (address.length === 1) {
      loadReplies().then(setReplies);
    } else {
      if (item.replies) setReplies(item.replies);
    }
  }, []);

  if (address.length == 1) console.log(item);

  if (item.banned) return null;

  if (item.archived) {
    return null;
    //item.content.text = "This comment was deleted!";
  }

  return (
    <Container>
      <Profile src={getImageURL(item.author.profileImage, true)}></Profile>
      <TextContent>
        <SemanticButton
          setMyReplies={getProfileLink(item.author.username)}
          onClick={goToProfile(item.author.username)}
        >
          <AuthorName>{item.author.name}</AuthorName>
        </SemanticButton>

        <CommentText>{item.content.text}</CommentText>

        <Buttons>
          <LikeDislikeButtons
            variant="minimal"
            likeStatus={item.likeStatus}
            netLikeCount={item.netLikeCount}
            likeCount={item.likeCount}
            dislikeCount={item.dislikeCount}
            commentID={item._id}
            replyLocation={address.length == 1 ? null : address}
          />
          <CustomButton
            onClick={() => {
              setShouldShowReplies(!shouldShowReplies);
            }}
            variant="minimal"
          >
            {address.length === 1
              ? calcReplyCount(item.content.replyTree)
              : null}{" "}
            Replies
          </CustomButton>

          <CustomButton
            onClick={() => {
              setReplyInputStatus(!replyInputStatus);
              setShouldShowReplies(!replyInputStatus);
            }}
            variant="minimal"
          >
            Reply
          </CustomButton>

          <CommentOptionsButton item={item} />
        </Buttons>

        {replyInputStatus ? (
          <ReplyBox
            myReplies={myReplies}
            setMyReplies={setMyReplies}
            commentAddress={address}
          />
        ) : null}

        {renderReplies()}
      </TextContent>
    </Container>
  );

  function renderReplies() {
    if (!shouldShowReplies) return null;

    if (loadingReplies) return "Loading...";

    let theList = [];

    let theArray = [...myReplies, ...replies];

    for (let item of theArray) {
      theList.push(
        <RenderComment
          key={item._id}
          item={item}
          address={[...address, item._id]}
        />
      );
    }

    if (!theList.length) return null;

    return <Replies>{theList}</Replies>;
  }

  async function loadReplies() {
    setLoadingReplies(true);
    let theData = await serverLine.get("/replies/?commentID=" + item._id);
    setLoadingReplies(false);
    return theData;
  }
}

function calcReplyCount(tree) {
  if (!tree) return 0;
  let count = 0;

  for (let id in tree) {
    count++;
    if (tree[id]) {
      count += calcReplyCount(tree[id]);
    }
  }

  return count;
}
