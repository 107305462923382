import { styled } from "styled-components";
import { useContext, useEffect } from "react";
import Context from "../../Context.js";
import categoriesAndSubcategories from "../../data/categoriesAndSubcategories.js";
import CustomToggle from "../helperComponents/CustomToggle.js";
import getCategoryOptions from "../../controllers/utils/getCategoryOptions.js";
import getSubCategoryOptions from "../../controllers/utils/getSubCategoryOptions.js";
import CustomButton from "../helperComponents/CustomButton.js";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter.js";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 25px;
  flex-wrap: wrap;
  gap: 10px;
`;

export default function SubCategoryAdder({ value, onChange, category }) {
  if (!value) value = [];

  // if (!Array.isArray(value)) value = [];
  // value = [];
  let options = getSubCategoryOptions(category);
  return <Container>{renderButtons()}</Container>;

  function renderButtons() {
    let toReturn = [];

    let theList = options;

    let highlightStyle = {
      background: "var(--primaryColor)",
    };

    for (let item of theList) {
      let style = {};

      if (value.includes(item.value)) style = highlightStyle;
      toReturn.push(
        <CustomButton
          fontSize={"15px"}
          key={item.value}
          variant="outlined"
          style={style}
          onClick={addItem(item.value)}
        >
          {capitalizeFirstLetter(item.label)}
        </CustomButton>
      );
    }

    return toReturn;
  }

  function addItem(catID) {
    return () => {
      let newTags = [];

      if (value) newTags = [...value];

      if (newTags.includes(catID)) {
        newTags.splice(newTags.indexOf(catID), 1);
      } else {
        if (newTags.length >= 3) return window.doAlert("Max 3 are allowed");

        newTags.push(catID);
      }

      console.log(newTags);
      onChange(newTags);
    };
  }
}
