import CustomButton from "../../helperComponents/CustomButton";
import { styled } from "styled-components";
import goTo from "../../../controllers/goTo";
import { serverLine } from "../../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";
import WithHeader from "../WithHeader";

import { FiCheck } from "react-icons/fi";
import ArticleEditor from "../../editors/ArticleEditor";

import onChangeStop from "../../../controllers/onChangeStop";

import Context from "../../../Context";
import { AiOutlineLeft } from "react-icons/ai";
import { RiDeleteBin7Line, RiImageLine } from "react-icons/ri";

import TagsEditor from "../../editors/TagsEditor";

import PopupImageUploader from "../../editors/PopupImageUploader";
import getSubPath from "../../../controllers/getSubPath";
import BookEditor from "../../editors/BookEditor";
// import CategoryAdder from "../../editors/CategorySubcategoryAdder";

import LoadingSection from "../../helperComponents/LoadingSection";
import CategoryAdder from "../../editors/CategoryAdder";
import SubCategoryAdder from "../../editors/SubCategoryAdder";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 62vw;

  gap: 100px;

  @media (max-width: 900px) {
    width: 90vw;

    gap: 50px;
  }

  @media (min-width: 900px) {
    ${({ type }) => {
      if (type == "ARTICLE")
        return `
      width: var(--chapterWidth);
    `;
    }}
  }
`;

const EditArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
`;

const Hr = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--translucentHard);
`;

const TopArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const Left = styled.div``;
const Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;

export default function EditContent() {
  const { setPopupFeedItem, loggedInUserID, setNonPopupRoute } =
    useContext(Context);
  const [loading, setLoading] = useState(true);
  const { setForm } = useContext(Context);

  const [postData, setPostData] = useState(null);
  const [draftState, setDraftState] = useState({});
  let contentID = getSubPath(1);

  useEffect(() => {
    // if (!postData)
    if (contentID) {
      setLoading(true);
      serverLine.get("/content/?contentID=" + contentID).then((newData) => {
        setPostData(newData);
        if (newData.draft) {
          setDraftState(newData.draft);
        }
        setLoading(false);
      });
    }
  }, [contentID]);

  // return contentID;

  if (!loggedInUserID)
    return (
      <WithHeader>
        <Container>Login Required</Container>
      </WithHeader>
    );

  if (loading)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  if (!postData) return null;

  let typeVsComp = {
    BOOK: (
      <BookEditor
        bookID={postData._id}
        onChange={updateDraft}
        value={draftState}
      />
    ),
    ARTICLE: (
      <ArticleEditor
        contentID={postData._id}
        onChange={updateDraft}
        value={draftState}
      />
    ),
  };

  let addImageButton = (
    <CustomButton variant={"filled"} onClick={addImage} icon={<RiImageLine />}>
      Add Image
    </CustomButton>
  );

  let publishButton = (
    <CustomButton
      variant="filled"
      style={{ width: "150px" }}
      onClick={publishPost}
      icon={<FiCheck />}
    >
      Publish
    </CustomButton>
  );

  let theEditor = null;

  theEditor = typeVsComp[postData.type];

  let buttons = [];

  if (postData.type == "ARTICLE") {
    buttons = [addImageButton, publishButton];
  } else {
    buttons = [publishButton];
  }

  return (
    <WithHeader>
      <Container>
        <Main type={postData.type}>
          <TopArea>
            <Left>
              <CustomButton
                width={"100px"}
                variant={"filled"}
                onClick={goBack}
                icon={<AiOutlineLeft />}
              >
                Edit
              </CustomButton>
            </Left>

            <Right>{buttons}</Right>
          </TopArea>

          <EditArea>
            {theEditor}
            <br />
            <br />

            <CategoryAdder
              value={draftState.category}
              onChange={updateCategory}
            />

            <SubCategoryAdder
              category={draftState.category}
              value={draftState.subCategories}
              onChange={updateCategories}
            />

            {publishButton}
          </EditArea>

          <br />
          <br />
        </Main>
      </Container>
    </WithHeader>
  );

  function goBack() {
    goTo(-1)();
  }

  function updateCategory(newVals) {
    updateDraft({
      ...draftState,
      category: newVals,
      subCategories: [],
    });
  }

  function updateCategories(newVals) {
    updateDraft({
      ...draftState,
      subCategories: newVals,
    });
  }

  function updateTags(tags) {
    updateDraft({
      ...draftState,
      tags: tags,
    });
  }

  async function addImage() {
    setForm({ component: <PopupImageUploader callback={addImageCallback} /> });

    function addImageCallback(newImageData) {
      setForm(null);
      updateDraft({
        ...draftState,
        image: newImageData,
      });
    }
  }

  function updateDraft(newDraftState) {
    newDraftState = { ...newDraftState };
    setDraftState(newDraftState);

    onChangeStop("updateContent", () => {
      serverLine.patch("/content", {
        contentID: postData._id,
        changes: { draft: newDraftState },
      });
    });
  }

  async function publishPost() {
    if (!draftState.category) return window.doAlert("Please select category");

    if (!draftState.title) {
      return window.doAlert("Title is required");
    }

    if (postData.type == "ARTICLE") {
      if (!draftState.image) {
        return window.doAlert("Image is required");
      }
    }

    setLoading(true);

    let changes = {};
    changes.title = draftState.title;
    changes.image = draftState.image;
    changes.content = draftState.content;
    changes.cover = draftState.cover;
    changes.category = draftState.category;
    changes.subCategories = draftState.subCategories;
    changes.tags = draftState.tags;
    changes.draft = draftState;

    //For attaching all tags

    changes.published = true;

    await serverLine.patch("/content", {
      contentID: postData._id,
      changes: changes,
    });

    setPopupFeedItem(null);
    setNonPopupRoute(null);
    goTo(`/content/${postData._id}`)();
    setLoading(false);

    // setLoading(false);
  }
}
