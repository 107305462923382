import { styled } from "styled-components";
import { useContext, useState } from "react";
import Context from "../../Context";
import PopupImageUploader from "./PopupImageUploader";
import CustomButton from "../helperComponents/CustomButton";
import BookCoverPreview from "./BookCoverPreview";
import { Slider } from "@mui/material";
import {
  GrTextAlignCenter,
  GrTextAlignLeft,
  GrTextAlignRight,
} from "react-icons/gr";
import {
  TbAlignBoxBottomCenter,
  TbAlignBoxTopCenter,
  TbAlignCenter,
} from "react-icons/tb";
import CustomToggle from "../helperComponents/CustomToggle";
import MaterialSlider from "../helperComponents/MaterialSlider";
import {
  MdOutlineVerticalAlignBottom,
  MdOutlineVerticalAlignCenter,
  MdOutlineVerticalAlignTop,
} from "react-icons/md";
import { CompactPicker, SwatchesPicker } from "react-color";
import CustomSwatch from "../colors/CustomSwatch";
import MaterialInput from "../helperComponents/MaterialInput";
import { HexColorPicker } from "react-colorful";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  align-items: flex-start;
  padding: 50px;

  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: sticky;
  top: 30px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

/*

  * Vertical alignment
  * Horizontal alignment
  * Font Weight
  * Font family
  * Font Size
  * Zoom
  * Horizontal
  * Vertical

*/

let horizontalAlignmentOptions = [
  { label: <GrTextAlignLeft />, value: "LEFT" },
  { label: <GrTextAlignCenter />, value: "CENTER" },
  { label: <GrTextAlignRight />, value: "RIGHT" },
];

let verticalAlignmentOptions = [
  { label: <MdOutlineVerticalAlignTop />, value: "TOP" },
  { label: <MdOutlineVerticalAlignCenter />, value: "CENTER" },
  { label: <MdOutlineVerticalAlignBottom />, value: "BOTTOM" },
];

let fontWeightOptions = [
  { label: "Light", value: "LIGHT" },
  { label: "Medium", value: "MEDIUM" },
  { label: "Bold", value: "BOLD" },
];

let fontFamilyOptions = [
  { label: "Mono", value: "MONO" },
  { label: "Sans Serif", value: "SANS_SERIF" },
  { label: "Serif", value: "SERIF" },
];

export default function PopupBookCoverEditor({ value, onChange }) {
  const { setForm } = useContext(Context);
  const [valueState, setValueState] = useState(value);
  const [imageSelectorEnabled, enableImageSelector] = useState(false);

  let cover = {};

  if (valueState.cover) cover = valueState.cover;

  if (imageSelectorEnabled)
    return <PopupImageUploader callback={addImageCallback} />;

  function addImageCallback(newImageData) {
    let newCover = { ...cover };

    newCover["background"] = newImageData;

    setValueState({
      ...valueState,
      cover: newCover,
    });

    enableImageSelector(false);
  }

  return (
    <Container>
      <Left>
        <BookCoverPreview width={360} title={valueState.title} data={cover} />
        <MaterialInput
          disableUnderline={true}
          placeholder="Title"
          label={"Title"}
          multiline
          value={valueState.title}
          onChange={(e) => {
            makeChange("title")(e.target.value);
          }}
        />

        <CustomToggle
          value={cover.horizontalAlignment}
          options={horizontalAlignmentOptions}
          onChange={makeCoverChange("horizontalAlignment")}
        />
        <CustomToggle
          value={cover.verticalAlignment}
          options={verticalAlignmentOptions}
          onChange={makeCoverChange("verticalAlignment")}
        />
      </Left>

      <Buttons>
        {getButtons()}

        <CustomButton
          style={{ borderRadius: "10px" }}
          //   variant="filled"
          onClick={() => {
            setForm(null);
            onChange(valueState);
          }}
        >
          Save
        </CustomButton>
      </Buttons>
    </Container>
  );

  function getButtons() {
    let editingButtons = [
      <HexColorPicker
        color={cover.color}
        onChange={(color) => {
          makeCoverChange("color")(color);
        }}
      />,
      <MaterialSlider
        label="Font Size"
        value={cover.fontSize}
        step={0.1}
        min={0.5}
        max={2}
        onChange={makeCoverChange("fontSize")}
      />,
      <MaterialSlider
        label="Padding"
        value={cover.padding}
        step={0.1}
        min={0.5}
        max={2}
        onChange={makeCoverChange("padding")}
      />,

      <CustomToggle
        value={cover.fontWeight}
        options={fontWeightOptions}
        onChange={makeCoverChange("fontWeight")}
      />,
      <CustomToggle
        value={cover.fontFamily}
        options={fontFamilyOptions}
        onChange={makeCoverChange("fontFamily")}
      />,
      <CustomButton
        style={{ borderRadius: "10px" }}
        variant="filled"
        onClick={() => {
          enableImageSelector(true);
        }}
      >
        Change Background
      </CustomButton>,
      <MaterialSlider
        label="Background Zoom"
        value={cover.zoom}
        step={0.1}
        min={1}
        max={3}
        onChange={makeCoverChange("zoom")}
      />,
      <MaterialSlider
        label="Background X"
        value={cover.translateX}
        min={-50}
        max={50}
        onChange={makeCoverChange("translateX")}
      />,
      <MaterialSlider
        label="Background Y"
        value={cover.translateY}
        min={-50}
        max={50}
        onChange={makeCoverChange("translateY")}
      />,
      // <CustomSwatch
      //   color={cover.color}
      //   onChange={(color) => {
      //     makeCoverChange("color")(color);
      //   }}
      // />,
    ];

    return editingButtons;
  }

  function makeCoverChange(field, isEvent) {
    return (newVal) => {
      let newCover = { ...cover };

      newCover[field] = newVal;

      setValueState({
        ...valueState,
        cover: newCover,
      });
    };
  }

  function makeChange(field, isEvent) {
    return (newVal) => {
      setValueState({
        ...valueState,
        [field]: newVal,
      });
    };
  }
}
