import { heIL } from "@mui/x-date-pickers";

export default function getBookCardDimension() {
  let innerWidth = window.innerWidth;
  let width = null;
  let height = null;

  let isMobile = window.innerWidth < 900;
  let isTablet = window.innerWidth > 900 && window.innerWidth < 1300;

  isTablet = false;
  if (isTablet) {
    let individual = 900 / 4.2;
    width = individual;
    height = width * 1.5;
  } else if (isMobile) {
    width = window.innerWidth - 20 * 3;
    width = width / 2;

    height = width * 1.5;
  } else {
    width = 230;
    height = width * 1.5;
  }

  return { width: width + "px", height: height + "px", rawWidth: width };
}
