import { GrArticle } from "react-icons/gr";
import { GoFileMedia } from "react-icons/go";
import { LiaCubeSolid } from "react-icons/lia";
import CustomButton from "../helperComponents/CustomButton";
import { styled } from "styled-components";
import goTo from "../../controllers/goTo";
import { serverLine } from "../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";

import CustomMasonry from "../helperComponents/CustomMasonry";
import Context from "../../Context";
import FeedSection from "./FeedSection";
import InfiniteScroll from "../helperComponents/InfiniteScroll";
import CustomSelect from "../helperComponents/CustomSelect";
import FeedHr from "./FeedHr";
import LoadingSection from "../helperComponents/LoadingSection";
import isImageTypeContent from "../isImageTypeContent";
import ArticleCard from "../cardForProfile/ArticleCard";
import BookCard from "../cardForProfile/BookCard";
import CustomToggle from "../helperComponents/CustomToggle";
import getCategoryOptions from "../../controllers/utils/getCategoryOptions";
import DropDownInput from "../helperComponents/DropDownInput";
import InfiniteFeed from "./InfiniteFeed";
import SliderFeed from "./SliderFeed";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 40px;
`;
const Message = styled.div`
  font-size: 21px;
  /* background-color: var(--translucent); */
  padding: 0;
  border-radius: 10px;
  font-weight: 300;
  text-transform: capitalize;
  flex-direction: row;
  align-items: center;
  display: flex;
  opacity: 0.7;
  gap: 10px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const TitleIcon = styled.div`
  display: flex;
  justify-content: center;

  align-items: center;
`;
const TitleText = styled.div``;

const Top = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
`;
const Left = styled.div`
  font-size: 31px;
  font-weight: 100;
`;

let timeSpanOptions = [
  { value: "ALL_TIME", label: "All Time" },
  { value: "LAST_7_DAYS", label: "Last 7 Days" },
  { value: "LAST_30_DAYS", label: "Last 30 Days" },
  { value: "LAST_90_DAYS", label: "Last 90 Days" },
  { value: "LAST_365_DAYS", label: "Last 365 Days" },
];

export default function ContentFeed({
  type,
  title,
  filterType,
  feedType,
  loadSecondIndexAsWell,
  username, // For profile page contents
  searchQuery, // For profile page contents
}) {
  const [list, setList] = useState([]);
  const [category, setCategory] = useState("");
  const [timeSpan, setTimeSpan] = useState("ALL_TIME");

  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reachedTheEnd, setReachedTheEnd] = useState(false);

  useEffect(() => {
    setReachedTheEnd(false);
    setOffset(0);
    setList([]);
    doLoading();
  }, [filterType, category, timeSpan, searchQuery, type]);

  console.log(reachedTheEnd);

  // if (!loading && !list.length) return null;

  if (feedType == "INFINITE")
    return (
      <FeedSection buttons={getButtons()} title={title}>
        <InfiniteFeed
          type={type}
          list={list}
          doLoading={doLoading}
          loading={loading}
          reachedTheEnd={reachedTheEnd}
        />
      </FeedSection>
    );

  if (feedType == "SLIDER") {
    return (
      <FeedSection buttons={getButtons()} title={title}>
        <SliderFeed
          type={type}
          category={category}
          timeSpan={timeSpan}
          list={list}
          doLoading={doLoading}
          loading={loading}
          reachedTheEnd={reachedTheEnd}
          searchQuery={searchQuery}
        />
      </FeedSection>
    );
  }

  function getButtons() {
    let categoryButton = (
      <DropDownInput
        placeholder="Category"
        options={getCategoryOptions()}
        value={category}
        onChange={setCategory}
      />
    );
    let timeSpanButton = (
      <DropDownInput
        placeholder="Time Span"
        options={timeSpanOptions}
        value={timeSpan}
        onChange={setTimeSpan}
      />
    );

    if (filterType == "TOP_RATED") return [categoryButton, timeSpanButton];
    if (filterType == "MOST_RECENT") return [categoryButton];
    return null;
  }

  async function loadData(offset) {
    let loadedData = await serverLine.get(
      `/feed/?type=${type}&offset=${offset}&filterType=${filterType}&timeSpan=${timeSpan}&category=${category}&username=${username}&searchQuery=${searchQuery}`
    );

    loadedData = removeRepetition(loadedData);

    // loadedData = excludeContentWithoutAuthor(loadedData);

    return loadedData;
  }

  async function doLoading() {
    setLoading(true);

    let loadedData = await loadData(offset);

    let newOffset = offset + 1;
    let newList = [];

    if (!loadedData.length) {
      setReachedTheEnd(true);
    } else {
      setOffset(newOffset);
      newList = [...list, ...loadedData];
      setList(newList);
    }

    setLoading(false);

    if (loadSecondIndexAsWell) {
      if (newOffset == 1) {
        let newData = await loadData(newOffset);
        newList = [...newList, ...newData];
        setList(newList);
        setOffset(newOffset + 1);
      }
    }
  }

  function removeRepetition(newList) {
    let ids = list.map((item) => item._id);

    let toReturn = [];

    for (let item of newList) {
      if (!ids.includes(item._id)) {
        toReturn.push(item);
      }
    }

    return toReturn;
  }

  function excludeContentWithoutAuthor(items) {
    //In case author account has been deleted
    let newItems = [];

    for (let item of items) {
      if (item.author) newItems.push(item);
    }

    return newItems;
  }
}
