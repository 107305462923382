import { styled } from "styled-components";
import { serverLine } from "../../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";
import ReportedItem from "../../report/ReportedItem";
import LoadingSection from "../../helperComponents/LoadingSection";
import WithHeader from "../WithHeader";
import CustomToggle from "../../helperComponents/CustomToggle";

const Container = styled.div`
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Title = styled.h1``;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-bottom: 20px;
  border-bottom: 1px solid var(--translucentHard);
`;
const SubHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const MainTableHeading = styled.div`
  width: 200px;
  opacity: 0.5;
  font-size: 18px;
`;

const ColHeading = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  font-size: 18px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const subjectTypeOptions = [
  { value: "CONTENT", label: "Content" },
  { value: "COMMENT", label: "Comment" },
];

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export default function ReportDashboard() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subjectType, setSubjectType] = useState("CONTENT");

  useEffect(() => {
    setLoading(true);
    serverLine
      .get(`/report-dashboard/?subjectType=${subjectType}`)
      .then((newData) => {
        setItems(newData.items);
        setLoading(false);
      });
  }, [subjectType]);

  if (loading)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  return (
    <WithHeader>
      <Container>
        <Row>
          <Title>Report Dashboard</Title>
          <CustomToggle
            value={subjectType}
            onChange={setSubjectType}
            options={subjectTypeOptions}
          />
        </Row>

        <Section>
          <List>{renderContent()}</List>
        </Section>
      </Container>
    </WithHeader>
  );

  function renderContent() {
    let toReturn = [];

    for (let item of items) {
      toReturn.push(
        <ReportedItem key={item._id} item={item} subjectType={subjectType} />
      );
    }

    return toReturn;
  }
}
