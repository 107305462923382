import { useEffect, useState } from "react";
import { serverLine } from "../../controllers/serverLine";
import CustomToggle from "../helperComponents/CustomToggle";
import StatContentBox from "./StatContentBox";
import styled from "styled-components";
import LoadingSection from "../helperComponents/LoadingSection";

const options = [
  { value: "BOOK", label: "Book" },
  { value: "ARTICLE", label: "Article" },
];
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Left = styled.div`
  font-size: 23px;
  font-weight: 900;
`;
const Right = styled.div``;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default function ContentListWithStat() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contentType, setContentType] = useState("BOOK");

  useEffect(() => {
    console.log("type changed", contentType);
    setLoading(true);
    serverLine.get(`/content-list/?&type=${contentType}`).then((data) => {
      setLoading(false);
      setList(data);
    });
  }, [contentType]);

  if (loading) return <LoadingSection />;

  return (
    <Container>
      <Top>
        <Left>Content Stat</Left>
        <Right>
          <CustomToggle
            options={options}
            value={contentType}
            onChange={setContentType}
          />
        </Right>
      </Top>

      <Main>
        {list.map((item) => (
          <StatContentBox key={item._id} item={item} />
        ))}
      </Main>
    </Container>
  );
}
