import { styled } from "styled-components";
import OnboardingProgress from "./OnboardingProgress";
import BrandContainer from "../brand/BrandContainer";
import BasicInfoEditor from "../editors/BasicInfoEditor";
import { useContext } from "react";
import Context from "../../Context";
import OnboardingBoilerplate from "./OnboardingBoilerplate";

export default function AskBasicInfo() {
  const { loggedInUser, setLoggedInUser } = useContext(Context);

  return (
    <OnboardingBoilerplate
      title="Complete Signup"
      currentSection={"Basic Info"}
    >
      <BasicInfoEditor />
    </OnboardingBoilerplate>
  );
}
