import { useContext, useEffect, useState } from "react";
import BookCard from "../cardForProfile/BookCard";
import CustomMasonry from "../helperComponents/CustomMasonry";
import InfiniteScroll from "../helperComponents/InfiniteScroll";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { offset } from "@floating-ui/dom";
import getShimmers from "../../controllers/utils/getShimmers";
import styled from "styled-components";
import CustomButton from "../helperComponents/CustomButton";
import Context from "../../Context";
import ArticleCard from "../cardForProfile/ArticleCard";

import calculateFeedSettings from "../../controllers/utils/calculateFeedSettings";
import UserCard from "../cardForProfile/UserCard";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const PageNumber = styled.div``;

const Info = styled.div`
  background: var(--glassGradient);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);
  padding: 25px;
  font-size: 23px;
  border-radius: 10px;
  width: 100%;
  height: calc(230px * 1.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export default function SliderFeed({
  type,
  list,
  doLoading,
  loading,
  reachedTheEnd,
  category,
  timeSpan,
  searchQuery,
}) {
  const { popupAlert } = useContext(Context);
  const [localOffset, setLocalOffset] = useState(0);

  useEffect(() => {
    setLocalOffset(0);
  }, [category, timeSpan, searchQuery, type]);

  let { columns, itemsPerSlider, shimmerWidth, shimmerHeight } =
    calculateFeedSettings({
      type,
    });

  let subList = getSubList(localOffset);

  let Comp = BookCard;

  if (type == "ARTICLE") Comp = ArticleCard;

  if (type == "USER") Comp = UserCard;

  let items = subList.map((item) => (
    <Comp variant={"FEED"} key={item._id} item={item} />
  ));

  if (loading)
    return (
      <Container>
        <CustomMasonry
          maxCol={columns}
          list={getShimmers({
            count: itemsPerSlider,
            width: shimmerWidth,
            height: shimmerHeight,
          })}
        />
      </Container>
    );

  return (
    <Container>
      {getMainContent()}
      <Buttons>
        <CustomButton
          variant="filled"
          onClick={decrement}
          icon={<AiOutlineLeft />}
        />
        <PageNumber>{localOffset + 1}</PageNumber>
        <CustomButton
          variant="filled"
          onClick={increment}
          icon={<AiOutlineRight />}
        />
      </Buttons>
    </Container>
  );

  function getSubList(offset) {
    let start = offset * itemsPerSlider;

    let end = start + itemsPerSlider;
    let subList = list.slice(start, end);

    return subList;
  }

  function increment() {
    let newOffset = localOffset + 1;
    let newSubList = getSubList(newOffset);

    if (!newSubList.length && reachedTheEnd) {
      return popupAlert("You have reached the end");
    }

    if (!newSubList.length) doLoading();

    setLocalOffset(newOffset);
  }

  function decrement() {
    let newOffset = localOffset - 1;

    if (newOffset < 0) return;

    setLocalOffset(newOffset);
  }

  function getMainContent() {
    if (!list.length) return <Info>Nothing to show</Info>;

    if (!items.length && reachedTheEnd)
      return <Info>You have reached the end</Info>;

    return <CustomMasonry maxCol={columns} list={items} />;
  }
}
