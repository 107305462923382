import styled from "styled-components";
import goTo from "../../controllers/goTo";
import CustomButton from "../helperComponents/CustomButton";

const Button = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 25px;
  gap: 15px;
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid var(--translucentHard);
  align-items: center;
`;
const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
`;

const RoundButton = styled(Button)`
  padding: 0;
  border: none;
`;
const ButtonText = styled.div``;
const HeroButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
`;

const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: var(--translucentHard);
  }
`;

export default function SocialLink({
  baseLink,
  icon,
  data,
  field,
  size = "25px",
}) {
  if (!data) return null;
  if (!data[field]) return null;

  let theValue = data[field];

  let linksThatStartWithAt = ["youtube", "unsplash", "tiktok"];

  if (linksThatStartWithAt.includes(field)) {
    if (theValue[0] !== "@") {
      theValue = "@" + theValue;
    }
  }

  if (field == "upwork") {
    if (theValue[0] !== "~") {
      theValue = "~" + theValue;
    }
  }

  let iconBtnStyle = { fontSize: size };

  let onClick = null;

  if (field == "websiteLink") {
    if (theValue.indexOf("http") == -1) theValue = `https://${theValue}`;
    onClick = goTo(theValue, {
      isAbsolute: true,
    });
  } else {
    onClick = goTo(`${baseLink}/${theValue}`, {
      isAbsolute: true,
    });
  }

  return (
    <CustomButton
      style={iconBtnStyle}
      onClick={onClick}
      circular
      size="50px"
      fontSize="22px"
      icon={icon}
      variant={"minimal"}
    />
  );
}
