import { useContext, useEffect, useState } from "react";

import ContentPage from "./routes/ContentPage.js";
import HomePage from "./routes/HomePage.js";

import SendAuthCode from "./routes/SendAuthCode.js";
import ProfileEditor from "./routes/ProfileEditor.js";

import Notifications from "./routes/Notifications.js";
import CreateContent from "./routes/CreateContent.js";
import EditContent from "./routes/EditContent.js";
import MoreMenu from "./routes/MoreMenu.js";
import CheckStripePaymentSuccess from "./routes/CheckStripePaymentSuccess.js";
import Context from "../../Context.js";
import getPopupType from "../../controllers/getPopupType.js";

import styled from "styled-components";
import StatPage from "../stat/StatPage.js";

import ReportContent from "./routes/ReportContent.js";
import ReportDashboard from "./routes/ReportDashboard.js";
import ReportsPage from "../report/ReportsPage.js";

import Search from "./routes/Search.js";
import PopupFeedItem from "../feed/PopupFeedItem.js";
import ProfilePage from "../profilePage/ProfilePage.js";
import getSubPath from "../../controllers/getSubPath.js";
import ManageContents from "./routes/ManageContents.js";
import ArticlesPage from "./routes/ArticlesPage.js";
import BookmarkFeed from "../feed/BookmarkFeed.js";
import CreateReport from "./routes/CreateReport.js";
import ManageBans from "./routes/ManageBans.js";
import ContentStatPage from "../stat/ContentStatPage.js";
import InterestsEditor from "../editors/InterestsEditor.js";
import WithHeader from "./WithHeader.js";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

let pathVsComp = {
  "/": <HomePage />,
  "/saved": <BookmarkFeed />,
  "/create-report": <CreateReport />,
  "/articles": <ArticlesPage />,
  "/stats": <StatPage />,
  "/content-stats": <ContentStatPage />,
  "/content": <ContentPage />,
  "/stripe-payment-success": <CheckStripePaymentSuccess />,
  "/more-menu": <MoreMenu />,
  "/report-dashboard": <ReportDashboard />,
  "/manage-bans": <ManageBans />,
  "/reports": <ReportsPage />,
  "/report": <ReportContent />,
  "/notifications": <Notifications />,
  "/edit-content": <EditContent />,
  "/create-content": <CreateContent />,
  "/manage-contents": <ManageContents />,
  "/edit-profile": <ProfileEditor />,
  "/auth-redirect": <SendAuthCode />,
  "/search": <Search />,
  "/edit-interests": (
    <WithHeader>
      <InterestsEditor />
    </WithHeader>
  ),
};

export default function CustomRouter() {
  const { currentRoute, nonPopupRoute } = useContext(Context);

  let popupComp = null;
  let baseComp = findCompOfPath(currentRoute);

  let popupType = getPopupType(currentRoute);

  if (popupType && nonPopupRoute) {
    if (currentRoute !== nonPopupRoute) {
      baseComp = findCompOfPath(nonPopupRoute);
      popupComp = getPopupComp(currentRoute);
    }
  }

  let userPageParam = getSubPath(0, nonPopupRoute);

  let containerStyle = null;

  if (popupComp) {
    containerStyle = { display: "none" };
  }

  let profileComp = <ProfilePage username={userPageParam} />;

  return (
    <>
      <Container style={containerStyle}>
        {baseComp ? baseComp : profileComp}
      </Container>
      {popupComp}
    </>
  );

  function findCompOfPath(pathToScan) {
    // console.log("Scanning Path", pathToScan);

    pathToScan = pathToScan.split("?")[0];
    if (pathToScan == "/") return pathVsComp["/"];

    for (let aPath in pathVsComp) {
      if (aPath == "/") continue;
      let theComp = pathVsComp[aPath];
      let matchLength = aPath.length;
      let extracted = pathToScan.slice(0, matchLength);
      // console.log(extracted, aPath, extracted === aPath);
      if (extracted === aPath) return theComp;
    }

    return null;
  }

  function isNotForm(thePath) {
    return thePath.indexOf("formPage=true") === -1;
  }

  function getPopupComp(pathName) {
    let popupType = getPopupType(pathName);
    if (popupType) {
      return <PopupFeedItem type={popupType} />;
    } else {
      return false;
    }
  }
}
