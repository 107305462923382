import { styled } from "styled-components";
import { useContext } from "react";
import ContentFeed from "./ContentFeed";
import Context from "../../Context";
import LoadingSection from "../helperComponents/LoadingSection";
import isLoggedIn from "../../controllers/isLoggedIn";
import LoggedOutSection from "../core/LoggedOutSection";
import WithHeader from "../core/WithHeader";

const Hr = styled.div`
  min-height: 1px;
  width: 100%;
  background-color: var(--translucentHard);
  opacity: 0.3;

  @media (max-width: 900px) {
    margin-left: -10vw;
    width: 200vw;
    opacity: 0.5;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  width: 100%;

  padding: 0 0;
  padding-top: 0;

  @media (max-width: 900px) {
  }
`;

export default function BookmarkFeed({ type = "BOOK" }) {
  let loggedIn = isLoggedIn();
  return (
    <WithHeader>
      <Container>
        {!loggedIn ? <LoggedOutSection /> : null}

        <ContentFeed
          type={"BOOK"}
          filterType={"BOOK_BOOKMARKS"}
          title={"Saved Books"}
          feedType={"SLIDER"}
        />

        <ContentFeed
          type={"ARTICLE"}
          filterType={"ARTICLE_BOOKMARKS"}
          title={"Saved Articles"}
          feedType={"SLIDER"}
        />

        <br />
        <br />
        <br />
      </Container>
    </WithHeader>
  );
}
