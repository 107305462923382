import getArticleAndUserCardDimension from "./getArticleAndUserCardDimension";
import getBookCardDimension from "./getBookCardDimension";

export default function calculateFeedSettings({ type }) {
  let isMobile = window.innerWidth < 900;
  let isTablet = window.innerWidth > 900 && window.innerWidth < 1300;

  if (type == "BOOK") {
    let dimension = getBookCardDimension();

    if (isTablet) {
      return {
        columns: 4,
        itemsPerSlider: 4,
        shimmerWidth: dimension.width,
        shimmerHeight: dimension.height,
      };
    }

    if (isMobile) {
      return {
        columns: 2,
        itemsPerSlider: 4,
        shimmerWidth: dimension.width,
        shimmerHeight: dimension.height,
      };
    }

    return {
      columns: 5,
      itemsPerSlider: 5,
      shimmerWidth: dimension.width,
      shimmerHeight: dimension.height,
    };
  }

  if (type == "ARTICLE" || type == "USER") {
    let dimension = getArticleAndUserCardDimension();

    if (isMobile)
      return {
        columns: 1,
        itemsPerSlider: 4,
        shimmerWidth: dimension.width,
        shimmerHeight: dimension.height,
      };

    return {
      columns: 2,
      itemsPerSlider: 6,
      shimmerWidth: dimension.width,
      shimmerHeight: dimension.height,
    };
  }
}
