import { useContext } from "react";
import styled from "styled-components";
import { FiSearch } from "react-icons/fi/index.js";
import { AiOutlineUser } from "react-icons/ai/index.js";
import { HiMenu } from "react-icons/hi/index.js";
// import goToAuthScreen from "../controllers/goToAuthScreen.js";
import { GrGoogle } from "react-icons/gr/index.js";
import {
  MdOutlineArticle,
  MdOutlineBookmarkBorder,
  MdOutlineCreate,
} from "react-icons/md/index.js";
import {
  RiBookmark2Line,
  RiBookmark3Line,
  RiHome2Line,
  RiNotificationLine,
} from "react-icons/ri/index.js";
import goToProfile from "../../controllers/goToProfile.js";
import Context from "../../Context.js";
import goToAuthScreen from "../../controllers/goToAuthScreen.js";
import goTo from "../../controllers/goTo.js";
import CustomButton from "../helperComponents/CustomButton.js";
import SemanticButton from "../helperComponents/SemanticButton.js";
import getProfileLink from "../../controllers/getProfileLink.js";
import { GoBookmark } from "react-icons/go";
import checkIsMobile from "../../controllers/utils/checkIsMobile.js";
import HeaderLogo from "./HeaderLogo.js";

const Container = styled.div`
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: row;

  background: var(--glassGradientHeader);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);
  z-index: 555;
  backdrop-filter: blur(10px);
  transition: all 0.25s ease-in-out;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  padding-right: 20px;
  border-radius: 15px;
  width: var(--coreWidth);
  /* height: 100%; */
  /* height: var(--headerHeight); */
  margin-top: 50px;

  @media (max-width: 1300px) {
    width: var(--coreWidthSmall);
  }
`;

const MobileContainer = styled.div`
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: row;

  z-index: 555;
  backdrop-filter: blur(10px);
  transition: all 0.25s ease-in-out;
  justify-content: space-between;
  align-items: center;

  border-radius: 15px;
  width: 100%;
  margin-top: 10px;
  padding: 10px 0;
  /* height: 90px; */
`;

const MobileFloatingBottom = styled.div`
  position: fixed;
  bottom: 0px;
  display: flex;
  flex-direction: row;

  background: var(--glassGradientHeader);
  border: 1px solid var(--glassBorder);
  box-shadow: var(--lightShadow);
  z-index: 555;
  backdrop-filter: blur(10px);
  transition: all 0.25s ease-in-out;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  padding-right: 20px;
  width: 100%;
  left: 0;
  border-radius: 10px 10px 0 0;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  gap: 10px;
  align-items: center;
  position: relative;
  border-radius: 0;
  cursor: pointer;
  padding: 10px 20px;
  /* padding-left: 80px; */
  font-size: 19px;
  color: var(--color);
  border-radius: 10px;
  word-wrap: normal;

  border: 1px solid var(--glassBorder);
  box-shadow: var(--veryLightShadow);
  background: var(--glassGradient);

  ${({ highlight }) => {
    if (highlight)
      return `
       background: var(--primaryColor);
    `;

    return `
    &:hover{
     background: var(--primaryColor);
    }
       

    `;
  }}
`;

const Items = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: row;

  @media (max-width: 900px) {
  }
`;

const NotificationBubble = styled.div`
  display: flex;
  position: absolute;
  right: -3px;
  top: -5px;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  background-color: var(--accentColor);
  color: var(--bgColor);
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  @media (max-width: 900px) {
  }
`;

const LogoName = styled.div`
  font-weight: 800;
  font-size: 25px;
`;

const TheLogo = styled.div`
  @media (max-width: 900px) {
  }
`;

const LogoImg = styled.img`
  height: 40px;
  width: auto;
`;

const Padding = styled.div`
  height: var(--headerHeight);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 45px;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  font-size: 17px;
  align-items: center;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  text-align: left;
  /* background-color: red; */
  justify-content: flex-start;
  opacity: 0.8;
  position: relative;
  white-space: nowrap;

  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

let smallButtonStyle = { paddingLeft: "15px", paddingRight: "15px" };

export default function Header() {
  const { loggedInUser, notificationData, currentRoute } = useContext(Context);

  if (!notificationData) return null;

  let isMobile = checkIsMobile();

  let logoComp = <HeaderLogo />;

  if (isMobile) return getMobileComponent();

  if (!loggedInUser)
    return (
      <Container className="header" loggedInUser={loggedInUser}>
        {logoComp}

        <Items>
          <ProperButton
            link={"https://arnav.upon.one"}
            highlight={checkHighlight("/about")}
          >
            About Us
          </ProperButton>

          <ProperButton onClick={goToAuthScreen} icon={<GrGoogle />}>
            Login With Google
          </ProperButton>
        </Items>
      </Container>
    );

  let totalNotifications = notificationData.general;

  return (
    <Container className="header" loggedInUser={loggedInUser}>
      {logoComp}

      <Items>
        <ProperButton
          link={"/"}
          icon={<RiHome2Line />}
          highlight={checkHighlight("/")}
        >
          Books
        </ProperButton>
        <ProperButton
          link={"/articles"}
          icon={<MdOutlineArticle />}
          highlight={checkHighlight("/articles")}
        >
          Articles
        </ProperButton>

        <ProperButton
          style={smallButtonStyle}
          link={"/saved"}
          highlight={checkHighlight("/saved")}
          icon={<MdOutlineBookmarkBorder />}
        ></ProperButton>

        <ProperButton
          style={smallButtonStyle}
          link={"/notifications"}
          highlight={checkHighlight("/notifications")}
          icon={<RiNotificationLine />}
          bubble={
            totalNotifications ? (
              <NotificationBubble>{totalNotifications}</NotificationBubble>
            ) : null
          }
        ></ProperButton>

        <ProperButton
          style={smallButtonStyle}
          link={"/create-content"}
          highlight={checkHighlight("/create-content")}
          icon={<MdOutlineCreate />}
        >
          {/* Write */}
        </ProperButton>

        <ProperButton
          style={smallButtonStyle}
          highlight={checkHighlight(getProfileLink(loggedInUser.username))}
          link={getProfileLink(loggedInUser.username)}
          onClick={goToProfile(loggedInUser.username)}
          icon={<AiOutlineUser />}
        >
          {/* Profile */}
        </ProperButton>

        <ProperButton
          style={smallButtonStyle}
          highlight={checkHighlight("/search")}
          link={`/search`}
          icon={<FiSearch />}
        >
          {/* Search */}
        </ProperButton>
      </Items>
    </Container>
  );

  function checkHighlight(route) {
    if (route !== "/") {
      return currentRoute.indexOf(route) !== -1;
    }
    return route == currentRoute;
  }

  function getMobileComponent() {
    if (!loggedInUser)
      return (
        <MobileContainer className="header" loggedInUser={loggedInUser}>
          {logoComp}

          <Items>
            <ProperButton onClick={goToAuthScreen} icon={<GrGoogle />}>
              Login
            </ProperButton>
          </Items>
        </MobileContainer>
      );

    let totalNotifications = notificationData.general;

    return (
      <>
        <MobileContainer className="header" loggedInUser={loggedInUser}>
          <ProperButton
            style={smallButtonStyle}
            highlight={checkHighlight(getProfileLink(loggedInUser.username))}
            link={getProfileLink(loggedInUser.username)}
            onClick={goToProfile(loggedInUser.username)}
            icon={<AiOutlineUser />}
          ></ProperButton>

          {logoComp}

          <ProperButton
            style={smallButtonStyle}
            highlight={checkHighlight("/search")}
            link={`/search`}
            icon={<FiSearch />}
          ></ProperButton>
        </MobileContainer>

        <MobileFloatingBottom className="header" loggedInUser={loggedInUser}>
          <ProperButton
            link={"/"}
            icon={<RiHome2Line />}
            highlight={checkHighlight("/")}
          >
            Books
          </ProperButton>
          <ProperButton
            link={"/articles"}
            icon={<MdOutlineArticle />}
            highlight={checkHighlight("/articles")}
          >
            Articles
          </ProperButton>

          <ProperButton
            style={smallButtonStyle}
            link={"/saved"}
            highlight={checkHighlight("/saved")}
            icon={<MdOutlineBookmarkBorder />}
          ></ProperButton>

          <ProperButton
            style={smallButtonStyle}
            link={"/notifications"}
            highlight={checkHighlight("/notifications")}
            icon={<RiNotificationLine />}
            bubble={
              totalNotifications ? (
                <NotificationBubble>{totalNotifications}</NotificationBubble>
              ) : null
            }
          ></ProperButton>
        </MobileFloatingBottom>
      </>
    );
  }
}

function ProperButton({
  link,
  onClick,
  icon,
  children,
  highlight,
  style,
  bubble,
}) {
  return (
    <SemanticButton
      style={{ width: "100%" }}
      semanticHref={link}
      onClick={onClick ? onClick : goTo(link)}
    >
      <Button style={style} highlight={highlight}>
        {icon ? <ButtonIcon>{icon}</ButtonIcon> : null}
        {children ? <ButtonText>{children}</ButtonText> : null}
        {bubble ? bubble : null}
      </Button>
    </SemanticButton>
  );
}
