import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import BasicInfoEditor from "../../editors/BasicInfoEditor";
import CustomButton from "../../helperComponents/CustomButton";
import goTo from "../../../controllers/goTo";
import { AiOutlineLeft, AiOutlineProfile } from "react-icons/ai";
import Context from "../../../Context";
import LoginButton from "../../helperComponents/LoginButton";
import goToProfile from "../../../controllers/goToProfile";
import getProfileLink from "../../../controllers/getProfileLink";
import WithHeader from "../WithHeader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  padding: 50px 0;
  gap: 50px;
  padding-bottom: 50px;

  @media (max-width: 900px) {
    padding: 30px 0;
    /* padding: 25px; */
  }
`;

const TopPart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const EditSection = styled.div`
  width: 100%;
`;

const Main = styled.div`
  width: 38vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  @media (max-width: 900px) {
    width: 90vw;
    /* padding: 25px; */
  }
`;

const RightButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export default function ProfileEditor() {
  const { loggedInUser } = useContext(Context);

  if (!loggedInUser) return <LoginButton />;

  let topPart = (
    <TopPart>
      <CustomButton
        variant="filled"
        icon={<AiOutlineLeft />}
        onClick={goTo(-1)}
      />

      <RightButtons>
        <CustomButton
          variant="filled"
          icon={<AiOutlineProfile />}
          href={getProfileLink(loggedInUser.username)}
        >
          Open Profile
        </CustomButton>
      </RightButtons>
    </TopPart>
  );

  return (
    <WithHeader>
      <Container>
        <Main>
          {topPart}

          <EditSection>
            <BasicInfoEditor />
          </EditSection>
        </Main>
      </Container>
    </WithHeader>
  );
}
