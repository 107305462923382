let swatchColors = [
  "#4D4D4D",
  "#999999",
  "#FFFFFF",
  "#F44E3B",
  "#FE9200",
  "#FCDC00",
  "#DBDF00",
  "#A4DD00",
  "#68CCCA",
  "#73D8FF",
  "#AEA1FF",
  "#FDA1FF",
  "#333333",
  "#808080",
  "#cccccc",
  "#D33115",
  "#E27300",
  "#FCC400",
  "#B0BC00",
  "#68BC00",
  "#16A5A5",
  "#009CE0",
  "#7B64FF",
  "#FA28FF",
  "#000000",
  "#666666",
  "#B3B3B3",
  "#9F0500",
  "#C45100",
  "#FB9E00",
  "#808900",
  "#194D33",
  "#0C797D",
  "#0062B1",
  "#653294",
  "#AB149E",
];

swatchColors = [
  "#000000",
  "#333333",
  "#4D4D4D",
  "#666666",
  "#808080",
  "#999999",
  "#B3B3B3",
  "#CCCCCC",
  "#FFFFFF",
  "#9F0500",
  "#D33115",
  "#F44E3B",
  "#C45100",
  "#E27300",
  "#FE9200",
  "#FB9E00",
  "#FCC400",
  "#FCDC00",
  "#808900",

  "#B0BC00",
  "#DBDF00",
  "#A4DD00",
  "#68BC00",
  "#194D33",
  "#16A5A5",
  "#0C797D",
  "#68CCCA",
  "#73D8FF",
  "#0062B1",
  "#009CE0",
  "#7B64FF",
  "#AEA1FF",
  "#653294",
  "#AB149E",
  "#FA28FF",
  "#FDA1FF",
];

export default swatchColors;
